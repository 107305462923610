import React from "react";

const LogoIcon = ({ type = 'logo', style, title = ''}) => {
  const images = require.context("../../../../assets/img/logos", true);
  try {
    let img = images(`./${type.toLowerCase()}.png`);
    return (
      <img style={style} alt={title} title={title} src={img.default} />
    );
  } catch (e) {
    return <></>;
  }
};

export default LogoIcon;
