export const getProfilerPersonalInfo = (state) => state.profiler.personalInfo;
export const getProfilerPerson = (state) => state.profiler.person;
export const getProfilerPersonLoading = (state) => state.profiler.personLoading;
export const getProfilerLocations = (state) => state.profiler.locations
export const getProfilerFriends = (state) => state.profiler.friends;
export const getProfilerFriendsStat = (state) => state.profiler.friendsStat;
export const getProfilerImages = (state) => state.profiler.images;
export const getProfilerWebSearch = (state) => state.profiler.webSearch;
export const getProfilerPosts = (state) => state.profiler.posts;
export const getProfilerPreferences = (state) => state.profiler.preferences;
export const getProfilerContactInfo = (state) => state.profiler.contactInfo;
export const getProfilerDeepWeb = (state) => state.profiler.deepWeb;
export const getProfilerSynopsis = (state) => state.profiler.synopsis;
export const getProfilerGeneralStat = (state) => state.profiler.generalStat;
export const getProfilerPostStat = (state) => state.profiler.postStat;
export const getProfilerPsychoProfile = (state) => state.profiler.psychoProfile;
export const getProfilerSearchProgress = (state) => state.profiler.searchProgress;
export const getProfilerImageSearchResults = (state) => state.profiler.imageSearchResults;
export const getProfilerExportLoading = state => state.profiler.exportLoading;
export const getProfilerIsNotFound = state => state.profiler.isNotFound;
