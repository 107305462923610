import {
  Document,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  WidthType,
  ExternalHyperlink
} from "docx";
import i18n from "i18next";
import DocxService from "../DocxService";

class ExportDocxWebintUserStat {
  static dimensions = {};

  static renderWebintUserStat = (data = []) => {
    console.log('webint users stat', data);

    const rows = [];

    rows.push(new TableRow({
      children: [
        new TableCell({
          width: {
            size: 1800,
            type: WidthType.DXA,
          },
          children: [new Paragraph(i18n.t('Username'))],
        }),
        new TableCell({
          width: {
            size: 1800,
            type: WidthType.DXA,
          },
          children: [new Paragraph(i18n.t('Date'))],
        }),
        new TableCell({
          width: {
            size: 1800,
            type: WidthType.DXA,
          },
          children: [new Paragraph(i18n.t('Avatars'))],
        }),
        new TableCell({
          width: {
            size: 1800,
            type: WidthType.DXA,
          },
          children: [new Paragraph(i18n.t('Task'))],
        }),
        new TableCell({
          width: {
            size: 1800,
            type: WidthType.DXA,
          },
          children: [new Paragraph(i18n.t('Monitoring'))],
        }),
      ],
    }))

    data.forEach(activity => {
      rows.push(new TableRow({
        children: [
          new TableCell({
            width: {
              size: 1800,
              type: WidthType.DXA,
            },
            children: [new Paragraph(activity.user)],
          }),
          new TableCell({
            width: {
              size: 1800,
              type: WidthType.DXA,
            },
            children: [new Paragraph(activity.date)],
          }),
          new TableCell({
            width: {
              size: 1800,
              type: WidthType.DXA,
            },
            children: [new Paragraph(activity.avatarsArr.map(ava => ava.profileName).join("\n"))],
          }),
          new TableCell({
            width: {
              size: 1800,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new ExternalHyperlink({
                    children: [
                      new TextRun({
                        text: activity.taskName,
                        style: "Hyperlink",
                      }),
                    ],
                    link: window?.location?.origin + activity.taskLink,
                  }),
                ]
              })
            ],
          }),
          new TableCell({
            width: {
              size: 1800,
              type: WidthType.DXA,
            },
            children: [new Paragraph(activity.monitoringText)],
          }),
        ],
      }))
    })

    const table = new Table({
      columnWidths: [1800, 1800, 1800, 1800, 1800],
      rows: rows,
    });

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Webint User Stats')),
        table,
      ],
    };
  };



  static renderDoc(data) {
    return new Document({
      sections: [
        this.renderWebintUserStat(data),
      ],
    });
  }
}

export default ExportDocxWebintUserStat;