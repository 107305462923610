export const getAmmMetaAvatars = (state) => state.amm.metaAvatars;
export const getAmmMetaAvatar = (state) => state.amm.metaAvatar;
export const getAmmMetaAvatarLoading = (state) => state.amm.metaAvatarLoading;
export const getAmmProfilesFilters = (state) => state.amm.profilesFilters;
export const getAmmManagementFilters = (state) => state.amm.managementFilters;
export const getAmmAuditLog = (state) => state.amm.auditLog;
export const getAmmAuditLogCat = (state) => state.amm.auditLogCattegories;
export const getAmmBlockedAvatars = (state) => state.amm.blockedAvatars;
export const getAmmMetaLikes = (state) => state.amm.likes;
export const getAmmMetaPosts = (state) => state.amm.posts;
export const getAmmMetaLastActivity = (state) => state.amm.lastActivity;

