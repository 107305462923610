import {storageSupported} from "./storageSupported";

export function authHeader() {
  // return authorization header with jwt token
  const accessToken = storageSupported() && localStorage.getItem('auth');
  if (accessToken) {
    return { Authorization: `Bearer ${accessToken}` };
  } else {
    return {};
  }
}