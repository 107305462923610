import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderQuotaContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
`;

export const QuotaSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  gap: 10px;
`;

