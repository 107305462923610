import BaseReducer from "../BaseReducer";
import AvatarAction from "./avatarAction";

export default class AvatarReducer extends BaseReducer {
  initialState = {
    avatars: [],
    blockedAvatars: [],
    usage: [],
    activityEvents: [],
    currentActivity: {},
    users: [],
  };

  [AvatarAction.UPDATE_ACTIVITY_STORE](state, action) {
    const { id, elem } = action.payload;
    return {
      ...state,
      activityEvents: [
        ...state.activityEvents.filter((el) => el.id !== id),
        elem,
      ],
    };
  }

  [AvatarAction.UPDATE_AVATAR_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }

  [AvatarAction.SET_AVATAR_USAGE](state, action) {
    return {
      ...state,
      usage: action.payload,
    };
  }

  [AvatarAction.SET_CURRENT_ACTIVITY](state, action) {
    return {
      ...state,
      currentActivity: action.payload,
    };
  }

  [AvatarAction.SET_AVATAR_DATA](state, action) {
    return {
      ...state,
      avatars: action.payload,
    };
  }
  [AvatarAction.SET_BLOCKED_AVATARS_DATA](state, action) {
    return {
      ...state,
      blockedAvatars: action.payload,
    };
  }
  [AvatarAction.SET_ACTIVITY_EVENTS_DATA](state, action) {
    return {
      ...state,
      activityEvents: action.payload,
    };
  }
  [AvatarAction.ADD_ACTIVITY_TO_STORE](state, action) {
    return {
      ...state,
      activityEvents: [...state.activityEvents, action.payload],
    };
  }
}
