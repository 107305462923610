import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {withTranslation} from "react-i18next";

const MainInfo = ({t, data}) => {
  const { personalInfo } = data;
  const { lastOnline = [], genders = [], status = '', emails = []} = personalInfo;

  return (
    <View style={{borderBottom: '1px solid #ececec'}}>
      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}}>
        <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{t("Last online")}:</Text>
        </View>
        <View>
          {
            lastOnline.map((online, idx) => <View key={idx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px'}}>
              <SocialIcon network={online.source} />
              <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{online.status}</Text>
            </View>)
          }
        </View>
      </View>
      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}}>
        <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{t("Gender")}:</Text>
        </View>
        <View>
          {
            genders.map((gender, idx) => <View key={idx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px'}}>
              <SocialIcon network={gender.source} />
              <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{gender.gender}</Text>
            </View>)
          }
        </View>
      </View>
      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}}>
        <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{t("Emails")}:</Text>
        </View>
        <View>
          {
            emails.map((email, idx) => <View key={idx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px'}}>
              <SocialIcon network={email.source} />
              <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{email.data}</Text>
            </View>)
          }
        </View>
      </View>
      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}}>
        <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{t("Status")}:</Text>
        </View>

        <View>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{status || '-'}</Text>
        </View>
      </View>

      <Text break />
    </View>
  );
};

export default withTranslation()(MainInfo);