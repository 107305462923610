import { notification } from "antd";
import { put, takeEvery } from "redux-saga/effects";
import {apiAlertsListService, apiAlertsService} from "../../config/api";
import SettingsAction from "../settings/settingsAction";

import AlertsAction from "./alertsAction";
import {performAlertsFilters, prepareAddCriteria, transformAlertsList} from "./alertsTransformer";
import ExportFactory from "../../services/Export/ExportFactory";
import i18n from "i18next";

function* addCriteriaSaga(action) {
  const { data, callback } = action.payload;
  const dataToSend = prepareAddCriteria(data);
  yield apiAlertsService.createAlertC(dataToSend);
  yield put(AlertsAction.getCriteria());
  notification.success({
    message: i18n.t("Alert was created"),
    placement: "bottomRight",
  });
  yield callback();
  try {
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* patchCriteriaSaga(action) {
  const { data, id, callback } = action.payload;
  const dataToSend = prepareAddCriteria(data);
  try {
    yield apiAlertsService.updateAlertC(id, dataToSend);
    yield put(AlertsAction.getCriteria());
    notification.success({
      message: `${i18n.t("Alert")} ${id} ${i18n.t("was updated")}`,
      placement: "bottomRight",
    });
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* deleteCriteriaSaga(action) {
  const { id } = action.payload;
  try {
    yield apiAlertsService.deleteAlertC(id);
    yield put(AlertsAction.getCriteria());
    notification.success({
      message: `${i18n.t("Alert")} ${id} ${i18n.t("was deleted")}`,
      placement: "bottomRight",
    });
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getCriteriasSaga(action) {
  const { callback } = action.payload;
  yield put(AlertsAction.setLoading(true));
  try {
    const response = yield apiAlertsService.getAllAlertC();
    yield put(AlertsAction.setCriterias(response.data));
    yield put(AlertsAction.setLoading(false));
    if (callback) {
      yield callback();
    }
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield put(AlertsAction.setLoading(false));
  }
}

function* getAlertsSaga(action) {
  const {filters = {}, callback} = action.payload;
  try {
    const performedFilters = performAlertsFilters(filters);
    const {data} = yield apiAlertsListService.alertSearch(performedFilters);
    const transData = transformAlertsList(data);
    yield put(AlertsAction.updateAlertsState(({alerts: transData, unreadAlerts: 0})));
    callback(data);
  } catch (e) {
    callback([]);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportAlertsSaga(action) {
  const {type, filters = {}, callback} = action.payload;
  try {
    const performedFilters = performAlertsFilters(filters);
    const {data} = yield apiAlertsListService.alertSearch({...performedFilters/*, size: 200*/});
    const {content = []} = data;

    const exportInstance = yield ExportFactory.getExportHandler(type, content);
    yield exportInstance.prepareAlerts();
    yield exportInstance.export();

    callback(data);
  } catch (e) {
    callback([]);
    yield put(SettingsAction.handleAPIError(e));
  }
}

export function* watchAlert() {
  yield takeEvery(AlertsAction.ADD_ALERT, addCriteriaSaga);
  yield takeEvery(AlertsAction.GET_CRITERIAS, getCriteriasSaga);
  yield takeEvery(AlertsAction.PATCH_CRITERIA, patchCriteriaSaga);
  yield takeEvery(AlertsAction.DELETE_CRITERIA, deleteCriteriaSaga);
  yield takeEvery(AlertsAction.GET_ALERTS, getAlertsSaga);
  yield takeEvery(AlertsAction.EXPORT_ALERTS, exportAlertsSaga);
}
