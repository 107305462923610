import styled from "styled-components";
import {Layout} from "antd";

export const CustomLayout = styled(Layout)`
  background: ${props => props.theme.main_bg};
  min-height: 100vh;
  .ant-modal-content{
    background-color: ${props => props.theme.modal_bg};
    border-radius: ${props => props.theme.modal_border_radius};
  }
  .ant-modal-header{
    border-radius: ${props => props.theme.modal_border_radius};
    background-color: ${props => props.theme.modal_bg};
  }
  .custom-ant-modal .ant-modal-footer .ant-btn-primary,
  .ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger)
  {
    background: ${props => props.theme.button_bg};
    border-color: ${props => props.theme.button_border};
    border-radius: ${props => props.theme.button_border_radius};
    &:hover{
      background: ${props => props.theme.button_hover};
      border-color: ${props => props.theme.button_hover};
    }
    &:active{
      background: ${props => props.theme.button_active};
      border-color: ${props => props.theme.button_active};
    }
  }
  .ant-modal .ant-modal-footer .ant-btn{
    border-radius: ${props => props.theme.button_border_radius};
  }
  .custom-ant-modal .ant-modal-footer .ant-btn-text{
    border-radius: ${props => props.theme.button_border_radius};
    background: ${props => props.theme.button_text_bg};
  }
  .custom-ant-modal .ant-modal-title{
    color: ${props => props.theme.modal_title_color}
  }
  .ant-input{
    border-radius: ${props => props.theme.input_border_radius};
  }
  .ant-btn{
    border-radius: ${props => props.theme.button_border_radius};
  }
  .ant-btn.ant-btn-primary{
    background: ${props => props.theme.button_bg};
    border-color: ${props => props.theme.button_border};
    &:hover{
      background: ${props => props.theme.button_hover};
      border-color: ${props => props.theme.button_hover};
    }
    &:active{
      background: ${props => props.theme.button_active};
      border-color: ${props => props.theme.button_active};
    }
  }
`;