import { all } from "redux-saga/effects";
import { watchSettings } from "./settings/settingsEffect";
import { watchAuth } from "./auth/authEffect";
import { watchUsers } from "./users/usersEffect";
import { watchInvestigation } from "./investigation/investigationEffect";
import { watchProfiler } from "./profiler/profilerEffect";
import { watchWebint } from "./webint/webintEffect";
import { watchAvatars } from "./avatars/avatarsEffect";
import { watchAmm } from "./amm/ammEffect";
import { watchAvatar } from "./avatar/avatarEffect";
import { watchRekognition } from "./rekognition/rekognitionEffect";
import { watchAlert } from "./alerts/alertsEffect";
import { watchAudit } from "./audit/auditEffect";
import { watchProxy } from "./proxy/proxyEffect";
import {watchDots} from "./dots/dotsEffect";

export function* rootSaga() {
  yield all([
    watchAuth(),
    watchSettings(),
    watchUsers(),
    watchInvestigation(),
    watchProfiler(),
    watchWebint(),
    watchAvatars(),
    watchAmm(),
    watchAvatar(),
    watchRekognition(),
    watchAlert(),
    watchAudit(),
    watchProxy(),
    watchDots(),
  ]);
}
