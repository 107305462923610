import React from 'react';
import {Image, Text, View} from "@react-pdf/renderer";
import {imageByIndex} from "../../../../../helpers/imageByIndex";
import {withTranslation} from "react-i18next";

const Images = ({t, data}) => {
  const {images = []} = data;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Images")}</Text>


      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
        {
          images.map(({imageId}) =>
            <View key={imageId} style={{width: '150px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
              <Image style={{width: '150px', height: '150px', marginRight: '10px'}} src={imageByIndex(imageId)}/>
            </View>
          )
        }
      </View>

      <Text break />
    </View>
  );
};

export default withTranslation()(Images);