import moment from "moment";

import * as fs from "file-saver";
import { Packer } from "docx";
import ExportDocxProfiler from "./ExportDocxProfiler";
import ExportDocxWebint from "./ExportDocxWebint";
import ExportDocxPosts100 from "./ExportDocxPosts100";
import ExportDocxWebintUserStat from "./ExportDocxWebintUserStat";
import ExportDocxAlerts from "./ExportDocxAlerts";


class ExportDocx {
  constructor(data) {
    this.doc = null;
    this.rawData = Array.isArray(data) ? [...data] : {...data};
    this.fileName = "PROFILER_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareProfiler() {
    const {person} = this.rawData;
    this.fileName = "PROFILER_" + person?.id + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.doc = ExportDocxProfiler.renderDoc(this.rawData);
    return this;
  }
  prepareWebint() {
    const {task} = this.rawData;
    this.fileName = "WEBINT_" + task?.id + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.doc = ExportDocxWebint.renderDoc(this.rawData);
    return this;
  }
  prepareWebintUserStat() {
    this.fileName = "WEBINT_USER_STAT_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.doc = ExportDocxWebintUserStat.renderDoc(this.rawData);
    return this;
  }
  preparePosts100() {
    this.fileName = "TOP_100_POSTS_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.doc = ExportDocxPosts100.renderDoc(this.rawData);
    return this;
  }
  prepareAlerts() {
    this.fileName = "ALERTS_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.doc = ExportDocxAlerts.renderDoc(this.rawData);
    return this;
  }
  async export() {
    return new Promise(resolve => {
      Packer.toBlob(this.doc).then((blob) => {
        fs.saveAs(blob, this.fileName);
        resolve('exported');
      });
    })
  }

  //other methods
}

export default ExportDocx