export function prepareAvailableAvatars(avatarsList = {}){
  /*0:
  id: "5f1eb3c7e4b0a3c7bf2b0273"
  profileName: "Vadim.1988.Mevsha@gmail.com"
  socialNetwork: "ANGELLIST"
  status: "DISABLED"*/

  /*{
    facebook: [
      {
        id: 1,
        name: 'avatar1@fb',
      },
      {
        id: 2,
        name: 'avatar2@fb',
      },
      {
        id: 3,
        name: 'avatar3@fb',
      },
    ],*/
  const out = {};

  avatarsList.filter(ava => ava.status === 'VALID').forEach(ava => {
    if (!out[ava.socialNetwork]) {
      out[ava.socialNetwork] = []
    }
    out[ava.socialNetwork].push({id: ava.id, name: ava.profileName});
  })

  return out;
}