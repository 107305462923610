import BaseReducer from "../BaseReducer";
import SettingsAction from "./settingsAction";
import {storageSupported} from "../../helpers/storageSupported";

export default class SettingsReducer extends BaseReducer {
  initialState = {
    notification: [],
    activeRoute: "",
    lastLocation: null,
    //chosenLang: localStorage.getItem("ui_lang"),
    chosenLang: storageSupported() && localStorage?.getItem("ui_lang"),
    gridLayouts: {},
    isLoading: false,
    isNewBlank: storageSupported() ? JSON.parse(localStorage.getItem("ui_isNewTab")) : false,
  };

  [SettingsAction.TOGGLE_NEW_BLANK](state, action) {
    return {
      ...state,
      isNewBlank: action.payload,
    };
  }

  [SettingsAction.UPDATE_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }

  [SettingsAction.SET_IS_LOADING](state, action) {
    return {
      ...state,
      isLoading: action.payload,
    };
  }

  [SettingsAction.SHOW_NOTIFICATION](state, action) {
    return {
      ...state,
      notification: state.notification.concat(action.payload),
    };
  }

  [SettingsAction.CLEAR_NOTIFICATIONS](state, action) {
    return {
      ...state,
      notification: [],
    };
  }

  [SettingsAction.SET_ACTIVE_ROUTE](state, action) {
    return {
      ...state,
      activeRoute: action.payload,
    };
  }

  [SettingsAction.SET_LANGUAGE](state, action) {
    return {
      ...state,
      chosenLang: action.payload,
    };
  }

  [SettingsAction.SET_LAYOUT](state, action) {
    return {
      ...state,
      gridLayouts: {
        ...state.gridLayouts,
        [action.payload.keyLayout]: [...action.payload.items],
      },
    };
  }

  [SettingsAction.ADD_LAYOUT_ITEM](state, action) {
    return {
      ...state,
      gridLayouts: {
        ...state.gridLayouts,
        [action.payload.keyLayout]: [
          ...state.gridLayouts[action.payload.keyLayout],
          action.payload.item,
        ],
      },
    };
  }

  [SettingsAction.DELETE_LAYOUT_ITEM](state, action) {
    return {
      ...state,
      gridLayouts: {
        ...state.gridLayouts,
        [action.payload.keyLayout]: state.gridLayouts[
          action.payload.keyLayout
        ].filter((item) => item !== action.payload.item),
      },
    };
  }
}
