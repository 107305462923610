import moment from "moment";
import i18n from "i18next";

export class ExportXlsProfilerCase {
  static exportFoundNames = (targets) => {
    const data = [
      ['ID', i18n.t('Source'), i18n.t('Social network'), i18n.t('Name'), ],
    ];

    targets.forEach(({id, names}) => {
      names.forEach(({source, socialNetwork, data: itemData}) => {
        data.push([id, source, socialNetwork, itemData]);
      })
    })
    return data;
  }

  static exportSocialNetworks = (targets) => {
    const data = [
      ['ID', i18n.t('Social network'), i18n.t('Profile'), i18n.t('Source'),],
    ];

    targets.forEach(({id, networks}) => {
      networks.forEach(({socialNetwork, data: itemData, sources= []}) => {
        data.push([id, socialNetwork, itemData, sources?.join(', ')]);
      })
    })

    return data;
  }

  static exportLocations = (targets) => {
    const data = [
      ['ID', i18n.t('Location'), i18n.t('Source'),],
    ];

    targets.forEach(({id, addresses}) => {
      addresses.forEach(({data: itemData, source}) => {
        data.push([id, itemData, source]);
      })
    })

    return data;
  }

  static exportPhones = (targets) => {
    const data = [
      ['ID', i18n.t('Phone'),],
    ];

    targets.forEach(({id, phones}) => {
      phones.forEach((itemData) => {
        data.push([id, itemData,]);
      })
    })

    return data;
  }

  static exportGenders = (targets) => {
    const data = [
      ['ID', i18n.t('Gender'), i18n.t('Source'), i18n.t('Social Network'),],
    ];

    targets.forEach(({id, genders}) => {
      genders.forEach(({gender, source, socialNetworkIDs = []}) => {
        data.push([id, gender, source, socialNetworkIDs?.join(', ')]);
      })
    })

    return data;
  }

  static exportLastOnline = (targets) => {
    const data = [
      ['ID', i18n.t('Last Online'), i18n.t('Source'), i18n.t('Social Network'),],
    ];

    targets.forEach(({id, lastOnlineStatuses}) => {
      lastOnlineStatuses.forEach(({date, source, socialNetwork}) => {
        const formatDate = moment(date, 'x', true).isValid()
          ? moment(date, 'x', true).format('DD-MM-YYYY HH:mm')
          : date;
        data.push([id, formatDate, source, socialNetwork]);
      })
    })

    return data;
  }

  static exportEmails = (targets) => {
    const data = [
      ['ID', i18n.t('Email'), i18n.t('Source')],
    ];

    targets.forEach(({id, emails = []}) => {
      emails.forEach(({data: email, source,}) => {
        data.push([id, email, source]);
      })
    })

    return data;
  }

  static exportCarrier = (targets) => {
    const data = [
      ['ID', i18n.t('Operator'), 'IMSI'],
    ];

    targets.forEach(({id, carrier, imsi}) => {
      data.push([id, carrier, imsi]);
    })

    return data;
  }

  static exportDeepWeb = (targets) => {
    const data = [[
      'ID',
      i18n.t('Keyword'),//private String keyword;
      i18n.t('Type'),//private CrawlerType type;
      i18n.t('Created Date'),//private String createdDate;
      i18n.t('Address'),//private String address;
      i18n.t('Country'),//private String country;
      i18n.t('Date of birth'),//private String dateOfBirth;
      i18n.t('District'),//private String district;
      'Email',//private String email;
      i18n.t('Facebook ID'),//private String facebookId;
      i18n.t('Facebook Username'),//private String facebookUserName;
      i18n.t('Favorite word'),//private String favoriteWord;
      i18n.t('First Name'),//private String firstNameHydra;
      i18n.t('Last Name'),//private String lastNameHydra;
      i18n.t('Gender'),//private String gender;
      i18n.t('Linkedin Username'),//private String linkedinUserName;
      i18n.t('Password'),//private String password;
      i18n.t('Phone'),//private String phone;
      i18n.t('Profession'),//private String profession;
      'SSN',//private String ssn;
      i18n.t('Telegram ID'),//private String telegramId;
      i18n.t('Telegram Username'),//private String telegramUsername;
      i18n.t('Telegram Nickname'),//private String telegramNickname;
      i18n.t('VK ID'),//private String vkId;
    ]]

    targets.forEach(({id, deepWebInfo}) => {
      deepWebInfo?.hydraItems?.forEach(item => {
        data.push([
          id,
          item?.keyword,
          item?.type,
          item?.createdDate,
          item.address,
          item.country,
          item.dateOfBirth,
          item.district,
          item.email,
          item.facebookId,
          item.facebookUserName,
          item.favoriteWord,
          item.firstNameHydra,
          item.lastNameHydra,
          item.gender,
          item.linkedinUserName,
          item.password,
          item.phone,
          item.profession,
          item.ssn,
          item.telegramId,
          item.telegramUsername,
          item.telegramNickname,
          item.vkId,
        ]);
      })
    })

    return data;
  }
}