import { put, takeEvery, takeLeading, select } from "redux-saga/effects";
import UsersAction from "./usersAction";
import SettingsAction from "../settings/settingsAction";
import { apiInvestigationService, apiUsersService } from "../../config/api";
import moment from "moment";
import {
  getQuotaDate,
  transformInvestigations,
  transformInvestToEdit, transformUserPermissions,
} from "./usersTransformer";
import {getUser} from "../auth/authSelector";
import {notification} from "antd";
import i18n from "../../i18n";
import AuthAction from "../auth/authAction";

function* getInvestigationsListForUsersSaga(action) {
  const user = action.payload;
  try {
    const { data } = yield apiInvestigationService.getAllInvTree();
    const transformed = transformInvestigations(data, user);
    yield put(UsersAction.setInvestigationsList(transformed));
  } catch (e) {
    yield SettingsAction.handleAPIError(e);
  }
}

function* getUserListSaga(action) {
  const filter = action.payload;
  yield put(UsersAction.setUsersLoading(true));
  try {
    const data = yield apiUsersService.getUsers(filter);
    //const data = yield apiUsersService.getUsers({page: pageNumber, size: pageSize });
    //console.log('API', data, action, {page: pageNumber, size: pageSize});
    if (data.data.content.length || data.data.first) {
      yield put(UsersAction.setUserList(data));
      yield put(UsersAction.setUsersLoading(false));
    } else {
      yield put(
        UsersAction.getUserList({
          isActive: filter.isActive,
          pageFilter: {
            size: filter.pageFilter.size,
            page: filter.pageFilter.page - 1,
          },
        })
      );
    }
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield put(UsersAction.setUsersLoading(false));
  }
}

function* assignInvestigationsSaga(action) {
  const { data, callback, userName } = action.payload;
  try {
    const transformed = transformInvestToEdit(data);
    yield apiInvestigationService.changeAssignedUser({
      invIds: transformed,
      userName: userName.userName,
    });
  } catch (e) {
    yield SettingsAction.handleAPIError(e);
    yield callback();
  }
}

function* getQuickUserListSaga(action) {
  const filters = {
    isActive: true,
    pageFilter: {
      page: 0,
      size: 100,
      sortBy: "userName",
    },
  };
  const callback = action.payload;
  try {
    const { data } = yield apiUsersService.getUsers(filters);
    const { content = [] } = data;
    callback(content);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    callback(null);
  }
}

function* updateQuota(action) {
  const data = {
    ...action.payload,
    id: action.payload.id,
    roles: action.payload.roles,
    userName: action.payload.userName,
    password: "",
    webintIsActive: action.payload.webintIsActive,
    profilerIsActive: action.payload.profilerIsActive,
    quota: {
      ...action.payload.initialQuot,
      quotaProfiler: {
        isForever: action.payload.profiler?.isValidityForever,
        validFrom: getQuotaDate(
          action.payload.profiler?.interval[0],
          action.payload.initialQuot?.quotaProfiler?.validFrom
        ),
        expireBy: getQuotaDate(
          action.payload.profiler?.interval[1],
          action.payload.initialQuot?.quotaProfiler?.expiredBy
        ),
        crawler: {
          ...action.payload.initialQuot?.quotaProfiler.crawler,
          maxQuota:
            action.payload.profiler.collection ||
            action.payload.initialQuot?.quotaProfiler?.crawler?.maxQuota ||
            0,
        },
        lookup: {
          ...action.payload.initialQuot?.quotaProfiler.lookup,
          maxQuota:
            action.payload.profiler.monitoring ||
            action.payload.initialQuot?.quotaProfiler?.lookup?.maxQuota ||
            0,
        },
        cases: {
          ...action.payload.initialQuot?.quotaProfiler.cases,
          maxQuota:
            action.payload.profiler.numberOfJobs ||
            action.payload.initialQuot?.quotaProfiler?.cases?.maxQuota ||
            0,
        },
      },
      quotaWebint: {
        isForever: action.payload.webint?.isValidityForever,
        validFrom: getQuotaDate(
          action.payload.webint?.interval[0],
          action.payload.initialQuot?.quotaWebint?.validFrom
        ),
        expireBy: getQuotaDate(
          action.payload.webint?.interval[1],
          action.payload.initialQuot?.quotaWebint?.expiredBy
        ),
        crawler: {
          ...action.payload.initialQuot?.quotaWebint.crawler,
          maxQuota:
            action.payload.webint.collectionOperations ||
            action.payload.initialQuot?.quotaWebint?.crawler?.maxQuota ||
            0,
        },
        monitoring: {
          ...action.payload.initialQuot?.quotaWebint.monitoring,
          maxQuota:
            action.payload.webint.monitoring ||
            action.payload.initialQuot?.quotaWebint?.monitoring?.maxQuota ||
            0,
        },
        cases: {
          ...action.payload.initialQuot?.quotaWebint.cases,
          maxQuota:
            action.payload.webint.numberOfJobs ||
            action.payload.initialQuot?.quotaWebint?.cases?.maxQuota ||
            0,
        },
      },
    },
  };
  //console.log('updateQuota saga', action.payload, data);
  try {
    yield apiUsersService.updateUser(data.id, data);
    yield put(
      UsersAction.getUserList({
        isActive: true,
        pageFilter: { page: action.payload.page, size: 10},
        userName: action.payload.userName,
      })
    );
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* createUserSaga(action) {
  const data = {
    userName: action.payload.userName,
    roles: action.payload.roles,
    password: action.payload.password,
    webintIsActive: action.payload.webintIsActive,
    profilerIsActive: action.payload.profilerIsActive,
    topPostsIsActive: action.payload.topPostsIsActive || false,
    quota: {
      expireBy: moment(new Date()).format("YYYY-MM-DD"),
      validFrom: moment(new Date()).format("YYYY-MM-DD"),
      lastUpdate: moment(new Date()).format("YYYY-MM-DD"),
      isDaily: true,
      quotaProfiler: {
        lookup: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
        crawler: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
        cases: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
        periodic: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
        monitoring: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
      },
      quotaWebint: {
        lookup: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
        crawler: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
        cases: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
        periodic: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
        monitoring: {
          usedQuota: 0,
          maxQuota: 0,
          maxDailyQuota: 0,
        },
      },
    },
  };
  const { currentPage = 0 } = action.payload;
  try {
    yield apiUsersService.registerUser(data);
    yield put(
      UsersAction.getUserList({
        isActive: true,
        pageFilter: { size: 10, page: currentPage },
      })
    );
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* editUserSaga(action) {
  console.log('action.payload in saga', action.payload);
  const {editedUser, callback} = action.payload;
  const { id } = editedUser;
  const { currentPage = 0 } = editedUser;

  const data = {
    isActive: editedUser.isActive,
    userName: editedUser.userName,
    roles: editedUser.roles,
    password: editedUser.password,
    webintIsActive: editedUser.webintIsActive,
    profilerIsActive: editedUser.profilerIsActive,
    topPostsIsActive: editedUser.topPostsIsActive,
    //quota: action.payload.quota || {},
  };
  console.log('saga data edit user', action.payload);

  try {
    yield apiUsersService.updateUser(id, data);
    yield put(
      UsersAction.getUserList({
        isActive: true,
        pageFilter: { size: 10, page: currentPage },
      })
    );
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  } finally {
    callback(true);
  }
}

function* editUserNameSaga(action) {
  const { id, newInfo, currentPage = 0, callback } = action.payload;
  console.log('newInfo', newInfo);
  const {username} = yield select(getUser);
  if (newInfo.oldName === 'admin') {
    notification.success({message: i18n.t('Permission denied'), placement: 'bottomRight'});
    callback(true);
  } else {
    try {
      yield apiUsersService.renameUser(id, newInfo);
      if (username === newInfo.oldName) {
        notification.success({
          message: i18n.t("User info has been updated successfully. Please login with your new credentials"),
          placement: "bottomRight",
        });
        yield put(AuthAction.logout({}));
      } else {
        notification.success({
          message: i18n.t("User info has been updated successfully."),
          placement: "bottomRight",
        });
        yield put(
          UsersAction.getUserList({
            isActive: true,
            pageFilter: { size: 10, page: currentPage },
          })
        );
      }

      callback(true);
    } catch (e) {
      yield put(SettingsAction.handleAPIError(e));
      callback(false);
    }
  }

}

function* deleteUserSaga(action) {
  const { currentPage = 0, id } = action.payload.userData;
  //const { callback } = action.payload;
  try {
    //yield axios.delete(`${config.api_users}/${id}`, { headers: authHeader()})
    yield apiUsersService.deleteUser(id);
    yield put(
      UsersAction.getUserList({
        isActive: true,
        pageFilter: { size: 10, page: currentPage },
      })
    );
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getUserInfoSaga(action) {
  const callback = action.payload;
  try {
    const { data } = yield apiUsersService.getUserInfo();
    const authData = yield select(getUser);
    const transData = transformUserPermissions(data, authData);
    yield put(UsersAction.updateUserState({permissions: transData, userQuota: data?.quota}));

    callback(transData);
  } catch (e) {
    yield SettingsAction.handleAPIError(e);
  }
}

function* getUserQuotaSaga(action) {
  //const callback = action.payload;
  try {
    const {username} = yield select(getUser);
    const filter = {
      userName: username,
      isActive: true,
      pageFilter: {
        page: 0, size: 10
      }
    }
    const {data} = yield apiUsersService.getUsers(filter);
    const quotas = data?.content?.find(el => el.userName === username)?.quota;

    //callback(quotas);
    yield put(UsersAction.updateUserState({userQuota: quotas}));
  } catch (e) {
    yield SettingsAction.handleAPIError(e);
  }
}

export function* watchUsers() {
  yield takeEvery(UsersAction.GET_USER_LIST, getUserListSaga);
  yield takeEvery(UsersAction.CREATE_USER, createUserSaga);
  yield takeEvery(UsersAction.EDIT_USER, editUserSaga);
  yield takeEvery(UsersAction.EDIT_USER_NAME, editUserNameSaga);
  yield takeEvery(UsersAction.DELETE_USER, deleteUserSaga);
  yield takeEvery(UsersAction.UPDATE_QUOTA, updateQuota);
  yield takeEvery(
    UsersAction.GET_INVESTIGATIONS_LIST,
    getInvestigationsListForUsersSaga
  );
  yield takeEvery(UsersAction.GET_QUICK_USER_LIST, getQuickUserListSaga);
  yield takeEvery(UsersAction.ASSIGN_INVESTIGATION, assignInvestigationsSaga);
  yield takeLeading(UsersAction.GET_USER_INFO, getUserInfoSaga);
  yield takeEvery(UsersAction.GET_USER_QUOTA, getUserQuotaSaga);
}
