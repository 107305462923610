import React from 'react';
import {Result} from "antd";
import EmptyLayout from "../../../layouts/EmptyLayout";

const IncognitoWarning = props => {
  return (
    <EmptyLayout>
      <Result
        status="warning"
        title="Sorry, this page cannot be loaded in incognito mode. Please switch to a regular browser window or disable any extensions that may interfere with the page loading. Thank you for your understanding."
      />
    </EmptyLayout>
  );
};

export default IncognitoWarning;