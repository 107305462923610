import ActionUtility from "../ActionUtility";

export default class AvatarAction {
  static GET_ALL_USERS = "AvatarAction.GET_ALL_USERS";
  static GET_AVATAR_DATA = "AvatarAction.GET_AVATAR_DATA";
  static SET_AVATAR_DATA = "AvatarAction.SET_AVATAR_DATA";
  static GET_AVATAR_USAGE = "AvatarAction.GET_AVATAR_USAGE";
  static SET_AVATAR_USAGE = "AvatarAction.SET_AVATAR_USAGE";
  static GET_BLOCKED_AVATARS_DATA = "AvatarAction.GET_BLOCKED_AVATARS_DATA";
  static SET_BLOCKED_AVATARS_DATA = "AvatarAction.SET_BLOCKED_AVATARS_DATA";
  static GET_ACTIVITY_EVENTS_DATA = "AvatarAction.GET_ACTIVITY_EVENTS_DATA";
  static SET_ACTIVITY_EVENTS_DATA = "AvatarAction.SET_ACTIVITY_EVENTS_DATA";
  static ADD_ACTIVITY_EVENT = "AvatarAction.ADD_ACTIVITY_EVENT";
  static ADD_ACTIVITY_BULK = "AvatarAction.ADD_ACTIVITY_BULK";
  static ADD_ACTIVITY_TO_STORE = "AvatarAction.ADD_ACTIVITY_TO_STORE";
  static GET_CURRENT_ACTIVITY = "AvatarAction.GET_CURRENT_ACTIVITY";
  static SET_CURRENT_ACTIVITY = "AvatarAction.SET_CURRENT_ACTIVITY";
  static DELETE_ACTIVITY = "AvatarAction.DELETE_ACTIVITY";
  static UPDATE_AVATAR_STATE = "AvatarAction.UPDATE_AVATAR_STATE";
  static ASSIGN_AVATAR_TO_USER = "AvatarAction.ASSIGN_AVATAR_TO_USER";
  static UPDATE_ACTIVITY = "AvatarAction.UPDATE_ACTIVITY";
  static UPDATE_ACTIVITY_STORE = "AvatarAction.UPDATE_ACTIVITY_STORE";

  static GENERATE_AI_POSTS = "AvatarAction.GENERATE_AI_POSTS";

  static updateActivityStore(elem, id) {
    return ActionUtility.createAction(AvatarAction.UPDATE_ACTIVITY_STORE, {
      elem,
      id,
    });
  }

  static updateAvatarState(action) {
    return ActionUtility.createAction(AvatarAction.UPDATE_AVATAR_STATE, action);
  }

  static assignAvatarToUser(id, username, callback) {
    return ActionUtility.createAction(AvatarAction.ASSIGN_AVATAR_TO_USER, {
      id,
      username,
      callback,
    });
  }

  static getAllusers() {
    return ActionUtility.createAction(AvatarAction.GET_ALL_USERS);
  }

  static deleteActivity(id, type, callback) {
    return ActionUtility.createAction(AvatarAction.DELETE_ACTIVITY, {
      id,
      type,
      callback,
    });
  }

  static getAvatarUsage(data, callback) {
    return ActionUtility.createAction(AvatarAction.GET_AVATAR_USAGE, {
      data,
      callback,
    });
  }

  static setAvatarUsage(data) {
    return ActionUtility.createAction(AvatarAction.SET_AVATAR_USAGE, data);
  }

  static getAvatarData(callback) {
    return ActionUtility.createAction(AvatarAction.GET_AVATAR_DATA, {
      callback,
    });
  }

  static setAvatarData(action) {
    return ActionUtility.createAction(AvatarAction.SET_AVATAR_DATA, action);
  }

  static getBlockedAvatarData(data, callback) {
    return ActionUtility.createAction(AvatarAction.GET_BLOCKED_AVATARS_DATA, {
      data,
      callback,
    });
  }

  static setBlockedAvatarData(action) {
    return ActionUtility.createAction(
      AvatarAction.SET_BLOCKED_AVATARS_DATA,
      action
    );
  }

  static getCurrentActivity(activityId, activityType, callback) {
    return ActionUtility.createAction(AvatarAction.GET_CURRENT_ACTIVITY, {
      activityId,
      activityType,
      callback,
    });
  }

  static setCurrentActivity(action) {
    return ActionUtility.createAction(
      AvatarAction.SET_CURRENT_ACTIVITY,
      action
    );
  }

  static getActivityEvents(timeStamps, metaId, callback) {
    return ActionUtility.createAction(AvatarAction.GET_ACTIVITY_EVENTS_DATA, {
      timeStamps,
      metaId,
      callback,
    });
  }

  static setActivityEvents(action) {
    return ActionUtility.createAction(
      AvatarAction.SET_ACTIVITY_EVENTS_DATA,
      action
    );
  }

  static addActivityEventAsync(id, newElement, callback) {
    return ActionUtility.createAction(AvatarAction.ADD_ACTIVITY_EVENT, {
      id,
      newElement,
      callback,
    });
  }

  static addActivityEventBulk(id, activities, callback) {
    return ActionUtility.createAction(AvatarAction.ADD_ACTIVITY_BULK, {
      id,
      activities,
      callback,
    });
  }

  static updateActivity(idAvatar, elementToUpdate, callback, idActivity) {
    return ActionUtility.createAction(AvatarAction.UPDATE_ACTIVITY, {
      idAvatar,
      elementToUpdate,
      callback,
      idActivity,
    });
  }

  static addActivityEventToStore(action) {
    return ActionUtility.createAction(
      AvatarAction.ADD_ACTIVITY_TO_STORE,
      action
    );
  }

  static generateAIPosts(avatarId, number = 5, callback) {
    return ActionUtility.createAction(AvatarAction.GENERATE_AI_POSTS, {
      avatarId,
      number,
      callback,
    });
  }
}
