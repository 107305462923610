import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderLogo = styled.div`
    
`;

export const NavbarBrand = styled(Link)`
    
`;

