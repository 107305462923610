import ActionUtility from "../ActionUtility";

export default class AvatarsAction {

  static GET_AVAILABLE_AVATARS = "AvatarsAction.GET_AVAILABLE_AVATARS";

  static getAvailableAvatars(socialNetwork = '', module = '', callback) {
    return ActionUtility.createAction(AvatarsAction.GET_AVAILABLE_AVATARS, {socialNetwork, module, callback});
  }

}