import * as Styled from "./styled";

import React, {useCallback} from "react";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import SettingsAction from "../../../store/settings/settingsAction";
import {getChosenLang} from "../../../store/settings/settingsSelector";

import config from "../../../config/config";

const LangSelector = ({t, i18n}) => {
  const chosenLang = useSelector(getChosenLang);
  const dispatch = useDispatch();
  const setLanguage = useCallback(
    (language) => dispatch(SettingsAction.setLanguage(language)),
    [dispatch]
  );
  const {languages = []} = config;

  const handleClick = async (e) => {
    console.log(e.target.name);
    localStorage.setItem("ui_lang", e.target.name);
    setLanguage(e.target.name);
    await i18n.changeLanguage(e.target.value, () => {
      console.log("lang is changed", e.target.name);
      window.location.reload();
    });
  };

  return (
    <Styled.SelectLanguage>
      {/*<div>{t("Language")}:</div>*/}
      <Styled.LangOptions>
        {
          languages.map(lang => <Styled.Option
            alt={lang.title}
            title={lang.title}
            src={lang.icon}
            name={lang.code}
            onClick={handleClick}
            chosen={chosenLang === lang.code}
            key={lang.code}
          />)
        }
      </Styled.LangOptions>
    </Styled.SelectLanguage>
  )
}

export default withTranslation()(LangSelector);