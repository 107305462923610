import ActionUtility from "../ActionUtility";

export default class AlertsAction {
  static ADD_ALERT = "AlertsAction.ADD_ALERT";
  static GET_CRITERIAS = "AlertAction.GET_CRITERIAS";
  static SET_CRITERIAS = "AlertAction.SET_CRITERIAS";

  static SET_LOADING = "AlertAction.SET_LOADING";

  static PATCH_CRITERIA = "AlertAction.PATCH_CRITERIA";
  static DELETE_CRITERIA = "AlertAction.DELETE_CRITERIA";

  static GET_ALERTS = "AlertAction.GET_ALERTS";
  static EXPORT_ALERTS = "AlertAction.EXPORT_ALERTS";

  static UPDATE_ALERTS_STATE = "AlertAction.UPDATE_ALERTS_STATE";

  static INCREMENT_UNREAD_ALERTS = "AlertAction.INCREMENT_UNREAD_ALERTS";


  static setLoading(data) {
    return ActionUtility.createAction(AlertsAction.SET_LOADING, data);
  }

  static getCriteria(callback = null) {
    return ActionUtility.createAction(AlertsAction.GET_CRITERIAS, {
      callback,
    });
  }

  static patchCriteria(data, id, callback = null) {
    return ActionUtility.createAction(AlertsAction.PATCH_CRITERIA, {
      data,
      id,
      callback,
    });
  }

  static deleteCriteria(id) {
    return ActionUtility.createAction(AlertsAction.DELETE_CRITERIA, { id });
  }

  static setCriterias(data, callback) {
    return ActionUtility.createAction(AlertsAction.SET_CRITERIAS, data);
  }

  static addCriteria(data, callback = null) {
    return ActionUtility.createAction(AlertsAction.ADD_ALERT, {
      data,
      callback,
    });
  }

  static getAlerts(filters, callback) {
    return ActionUtility.createAction(AlertsAction.GET_ALERTS, {
      filters,
      callback,
    });
  }

  static exportAlerts(type, filters = {}, callback) {
    return ActionUtility.createAction(AlertsAction.EXPORT_ALERTS, {type, filters, callback});
  }

  static updateAlertsState(action) {
    return ActionUtility.createAction(AlertsAction.UPDATE_ALERTS_STATE, action);
  }

  static incrementUnreadAlerts(action) {
    return ActionUtility.createAction(AlertsAction.INCREMENT_UNREAD_ALERTS, action);
  }
}
