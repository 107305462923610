export const avatarsProfiles = [
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: null,
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "ru",
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "fb",
      name: "marvinsullivan",
    },
    flag: "uz",
    status: "Active",
    APALogin: false,
    activity: "Disabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "HTTPS",
    status: "Disabled",
    APALogin: false,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "",
    status: "Blocked",
    APALogin: true,
    activity: "Disabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "gb",
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "gb",
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "gb",
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "gb",
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "gb",
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "gb",
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
  {
    personalInfo: {
      nt: "viber",
      name: "marvinsullivan",
    },
    flag: "gb",
    status: "Active",
    APALogin: true,
    activity: "Enabled",
    module: "profiler",
  },
];
