export const getWebintTask = (state) => state.webint.task;
export const getWebintGroups = (state) => state.webint.groups;
export const getWebintTargets = (state) => state.webint.targets;
export const getWebintPosts = (state) => state.webint.posts;
export const getWebintTopPosts = (state) => state.webint.topPosts;
export const getWebintPostStat = (state) => state.webint.postStat;
export const getWebintSearchProgress = (state) => state.webint.searchProgress;
export const getWebintImages = (state) => state.webint.images;
export const getWebintVideos = (state) => state.webint.videos;
export const getWebintPosts100 = (state) => state.webint.posts100;
export const getWebintExportLoading = state => state.webint.exportLoading;
export const getWebintIsNotFound = state => state.webint.isNotFound;

