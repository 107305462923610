import caseHeader from '../assets/img/themes/oldVicenter/folder_header.png';
import addElement from "../assets/img/themes/oldVicenter/add_element.png";
import investigationCover from "../assets/img/themes/oldVicenter/case_cover.png";


export const oldWicenterTheme = {
  main_bg: '#FBFBFB',
  header_bg: '#4195D9',
  header_shadow: '#4195D9',
  header_menu: '#C9DFFF',
  header_menu_active: '#FFFFFF',
  header_user: '#FFFFFF',
  header_user_avatar: '#8A9CAB',
  header_user_avatar_bg: '#FFF',
  footer_color: '#FFF',
  sider_bg: '#FFF',
  sider_tree_color: '#000',
  sider_tree_selected_color: '#2B85D3',
  sider_tree_selected_bg: '#F3F9FE',
  case_header: caseHeader,
  case_bg: '#F3F9FE',
  case_border: '#A3C4DF',
  case_title_color: '#2B85D3',
  case_subtitle_color: '#99A3B9',
  investigation_cover: investigationCover,
  add_element: addElement,
  add_element_title: `
    color: #2B85D3;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  `,
  add_element_container: `
    border-radius: 15px;
    border: 1px dashed #3189D6;
    background: none;
  `,
  target_element_border: '15px',
  grid_switcher_bg: '#FFF',
  grid_switcher_border: '1px solid #EFEFEF',
  grid_switcher_highlight: '#FFF',
  grid_switcher_color: '#6a7996',
  grid_switcher_inactive_color: '#726f6f91',
  grid_widget_bg: '#FFF',
  grid_widget_shadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.22)',
  grid_widget_border: 'none',
  grid_widget_border_radius: '15px',
  grid_widget_margin: [25, 25],
  grid_widget_row_height: 15,
  grid_header_bg: '#FFF',
  grid_header_color: '#8A9CAB',
  grid_header_border: 'none',
  grid_resizer_position: `bottom: 10px; right: 10px;`,
  button_border: '#4195D9',
  button_border_radius: '15px',
  button_bg: '#4195D9',
  button_hover: '#8A9CAB',
  button_active: '#CFD4DA',
  button_text_bg: '#F8F8F8',
  modal_bg: '#FFF;',
  modal_border_radius: '15px',
  modal_title_color: '#2B85D3',
  input_border_radius: '10px',
  input_group_gap: '3px',
  post_line_bg: '#F8F8F8',
  post_line_border_radius: '15px',
  tabs_bg: '#F2F2F2',
  tabs_border_radius: '0',
  tabs_active_bg: '#98A3B9',
  tabs_active_color: '#F8F9FA',
  login_border: 'none',
  login_border_radius: '10px',
  login_box_shadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.22)',
  login_input_border: '1px solid #A3C4DF',
  login_input_border_radius: '10px',
}