import {put, select, takeEvery} from "redux-saga/effects";
import AuthAction from "./authAction";
import AppAction from "../app/appAction";
import SettingsAction from "../settings/settingsAction";
import jwt_decode from "jwt-decode";
import { apiAuthService } from "../../config/api";
import { notification } from "antd";
import {getUser} from "./authSelector";
import UsersAction from "../users/usersAction";

function* loginSaga(action) {
  const { username, password } = action.payload;
  const requestBody = {
    userName: username,
    password,
  };

  yield put(SettingsAction.updateState({ dataLoading: true }));
  try {
    const lastLocation = sessionStorage.getItem("redirect");
    //const {data} = yield axios.post(`${config.api_auth}/login`, requestBody, { headers: authHeader()});
    const { data } = yield apiAuthService.authenticateUser(requestBody);
    console.log("apiAuthService.authenticateUser", data);
    const { accessToken } = data;
    const { sub, roles, topPostsIsActive = false } = yield jwt_decode(accessToken);
    localStorage.setItem("auth", accessToken);

    yield put(
      AuthAction.loginSuccess({
        username: sub,
        roles: roles.split(","),
        topPostsIsActive,
        token: accessToken,
      })
    );
    yield put(UsersAction.getUserInfo(() => null));
    try {
      if (lastLocation) {
        sessionStorage.setItem("redirect", "");
        yield put(SettingsAction.updateState({ lastLocation: lastLocation }));
      } else {
        yield put(SettingsAction.updateState({ lastLocation: "/" }));
      }
    } catch (e) {
      yield put(AuthAction.loginError(true, e.message));
    }

    yield put(SettingsAction.updateState({ dataLoading: false }));
  } catch (e) {
    yield put(AuthAction.loginError(true, "Incorrect username or password"));
    yield put(SettingsAction.updateState({ dataLoading: false }));
  }
}

function* bearerLoginSaga(action) {
  const accessToken = action.payload;
  try {
    const {sub, roles, topPostsIsActive = false} = yield jwt_decode(accessToken);
    yield put(
      AuthAction.loginSuccess({
        username: sub,
        roles: roles.split(","),
        topPostsIsActive,
        token: accessToken,
      })
    );
  } catch (e) {
    yield put(AuthAction.loginError(true, "Incorrect login"));
  }
}

/*function* loginSuccessSaga(action) {
  const lastLocation = sessionStorage.getItem("redirect");
  //console.log('success', action.payload);
  //console.log('lastLocation from session', lastLocation);

  const {token} = action.payload;
  yield localStorage.setItem('auth', token);
  try {
    if (lastLocation) {
      yield sessionStorage.setItem("redirect", "");
      yield put(SettingsAction.updateState({ lastLocation: lastLocation }));
    } else {
      yield put(SettingsAction.updateState({ lastLocation: "/" }));
    }
  } catch (e) {
    yield put(AuthAction.loginError(true, e.message));
  }
}*/

// eslint-disable-next-line
function* loginErrorSaga(action) {
  notification.error({
    message: action.payload?.errorMessage || "Internal Server Error",
    placement: "bottomRight",
  });
  /*yield put(
    SettingsAction.showNotification({
      text: action.payload?.errorMessage || 'Internal Server Error',
      type: "error",
      duration: 5000,
    })
  );*/
}

function* logoutSaga(action) {
  try {
    const currentUser = yield select(getUser);

    console.log("in logout saga");
    if (currentUser) {
      yield apiAuthService.logout(currentUser.username);
    }

  } catch (e) {
    console.log(`Logout error: ${e}`);
    yield put(
      SettingsAction.showNotification({
        text: "Logout error",
        type: "error",
        duration: 5000,
      })
    );
  } finally {
    yield put(AppAction.resetStore());
    localStorage.removeItem("auth");
    yield put(SettingsAction.updateState({ lastLocation: "/login" }));
    if (action && action.payload) {
      const { currentLocation } = action.payload;
      if (currentLocation) {
        yield sessionStorage.setItem("redirect", currentLocation);
      }
    }
  }
}

/*function* getCredentialsSaga(action) {
  const callback = action.payload;
  const accessToken = localStorage.getItem("auth");
  if (accessToken) {
    const {sub, roles} = yield jwt_decode(accessToken);
    yield put(
      AuthAction.loginSuccess({username: sub, roles: roles.split(','), token: accessToken})
    );
    callback({sub, roles});
  } else {
    yield put(AuthAction.logout());
  }
}*/

export function* watchAuth() {
  yield takeEvery(AuthAction.LOGIN_INIT, loginSaga);
  yield takeEvery(AuthAction.BEARER_LOGIN_INIT, bearerLoginSaga);
  //yield takeEvery(AuthAction.LOGIN_SUCCESS, loginSuccessSaga);
  yield takeEvery(AuthAction.LOGIN_ERROR, loginErrorSaga);
  yield takeEvery(AuthAction.LOGOUT, logoutSaga);
  //yield takeEvery(AuthAction.GET_CREDENTIALS, getCredentialsSaga);
}
