import BaseReducer from "../BaseReducer";
import AmmAction from "./ammAction";

export default class AmmReducer extends BaseReducer {
  initialState = {
    avatars: [],
    metaAvatars: {},
    metaAvatar: {},
    metaAvatarLoading: false,
    isFilters: false,
    managementFilters: {
      /*SN: [],
      status: [],
      quarantineType: [],
      notifications: [],
      module: [],
      task: [],
      textFilter: "",*/
    },
    profilesFilters: null,
    auditLog: [],
    auditLogCattegories: [],
    blockedAvatars: []
  };

  /* SET ANY PROPERTY TO Amm store*/
  [AmmAction.UPDATE_AMM_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }

  [AmmAction.UPDATE_AMM_PROFILES_STATE](state, action) {
    return {
      ...state,
      metaAvatar: {
        ...state.metaAvatar,
        socialNetworks: [
          ...state.metaAvatar.socialNetworks.map((el) => {
            return (el.id === action.payload.id) ? { ...el, ...action.payload.info} : el;
          }),
        ],
      },
    };
  }

  [AmmAction.UPDATE_AVATAR_LOCAL_QUARANTINE](state, action) {
    return {
      ...state,
      metaAvatar: {
        ...state.metaAvatar,
        socialNetworks: [
          ...state.metaAvatar.socialNetworks.map((el) => {
            if (el.id === action.payload.data.id) {
              return {
                ...el,
                //quarantineMode: action.payload.data.quarantineMode,
                useQuarantine: action.payload.data.useQuarantine,
              };
            } else return el;
          }),
        ],
      },
    };
  }

  [AmmAction.UPDATE_AVATAR_LOCAL_STATUS](state, action) {
    return {
      ...state,
      metaAvatar: {
        ...state.metaAvatar,
        socialNetworks: [
          ...state.metaAvatar.socialNetworks.map((el) => {
            if (el.id === action.payload.data.id) {
              return {
                ...el,
                status: action.payload.data.status,
              };
            } else return el;
          }),
        ],
      },
    };
  }

  [AmmAction.TOGGLE_FILTERS](state) {
    return {
      ...state,
      isFilters: !state.isFilters,
    };
  }

  [AmmAction.SET_META_AVATARS](state, action) {
    return {
      ...state,
      metaAvatars: action.payload,
    };
  }

  [AmmAction.UPDATE_MANAGEMENT_FILTERS](state, action) {
    console.log(action.payload);
    return {
      ...state,
      managementFilters: {
        ...state.managementFilters,
        [action.payload.filterName]: action.payload.filterValue,
      },
    };
  }

  [AmmAction.DELETE_META_AVATAR_SUCCESS](state, action) {
    return {
      ...state,
      metaAvatars: {
        ...state.metaAvatars,
        content: [
          ...state.metaAvatars.content.filter(
            ({ meta }) => meta.id !== action.payload
          ),
        ],
      },
    };
  }
}
