import ActionUtility from "../ActionUtility";

export default class ProfilerAction {
  static UPDATE_PROFILER_STATE = "ProfilerAction.UPDATE_PROFILER_STATE";

  static SET_PERSON = "ProfilerAction.SET_PERSON";
  static GET_PERSON = "ProfilerAction.GET_PERSON";
  static GET_PERSONAL_INFO = "ProfilerAction.GET_PERSONAL_INFO";
  static ADD_PERSONAL_INFO = "ProfilerAction.ADD_PERSONAL_INFO";
  static UPDATE_PERSONAL_INFO = "ProfilerAction.UPDATE_PERSONAL";

  static GET_LOCATIONS = "ProfilerAction.GET_MAP_DATA";
  static GET_FRIENDS = "ProfilerAction.GET_FRIENDS";
  static FETCH_MORE_FRIENDS = "ProfilerAction.FETCH_MORE_FRIENDS";
  static FETCH_MORE_POSTS = "ProfilerAction.FETCH_MORE_POST";
  static GET_IMAGES = "ProfilerAction.GET_IMAGES";
  static GET_WEB_SEARCH = "ProfilerAction.GET_WEB_SEARCH";
  static GET_POSTS = "ProfilerAction.GET_POSTS";
  static GET_PREFERENCES = "ProfilerAction.GET_PREFERENCES";
  static GET_CONTACT_INFO = "ProfilerAction.GET_CONTACT_INFO";
  static GET_DEEP_WEB = "ProfilerAction.GET_DEEP_WEB";
  static GET_SYNOPSIS = "ProfilerAction.GET_SYNOPSIS";
  static SET_SYNOPSIS = "ProfilerAction.SET_SYNOPSIS";
  static GET_GENERAL_STAT = "ProfilerAction.GET_GENERAL_STAT";
  static GET_POST_STAT = "ProfilerAction.GET_POST_STAT";
  static GET_ACTIVITY_STAT = "ProfilerAction.GET_ACTIVITY_STAT";
  static GET_SEARCH_PROGRESS = "ProfilerAction.GET_SEARCH_PROGRESS";
  static GET_IMAGE_SEARCH_RESULTS = "ProfilerAction.GET_IMAGE_SEARCH_RESULTS";
  static GET_FACE_RECOGNITION_PROGRESS = "ProfilerAction.GET_FACE_RECOGNITION_PROGRESS";
  static GET_HISTORY = "ProfilerAction.GET_HISTORY";
  static GET_POST_ANALYSIS = "ProfilerAction.GET_POST_ANALYSIS";
  static GET_PSYCHO_PROFILE = "ProfilerAction.GET_PSYCHO_PROFILE";
  static START_PSYCHO_PROFILE = "ProfilerAction.START_PSYCHO_PROFILE";
  static UPDATE_SNIPER_URL = "ProfilerAction.UPDATE_SNIPER_URL";

  static ADD_SOURCE = "ProfilerAction.ADD_SOURCE";
  static UPDATE_SOURCES = "ProfilerAction.UPDATE_SOURCES";
  static UPDATE_SOURCE = "ProfilerAction.UPDATE_SOURCE";
  static INCREMENTAL_SOURCE = "ProfilerAction.INCREMENTAL_SOURCE";
  static DELETE_SOURCE = "ProfilerAction.DELETE_SOURCE";
  static RERUN_TARGET = "ProfilerAction.RERUN_TAEGET";
  static FETCH_TELEGRAM = "ProfilerAction.FETCH_TELEGRAM";
  static FETCH_GETCONTACT = "ProfilerAction.FETCH_GETCONTACT";
  static FETCH_NAME = "ProfilerAction.FETCH_NAME";
  static RERUN_CRAWLING = "ProfilerAction.RERUN_CRAWLING";

  static ADD_PROFILE_ITEM = "ProfilerAction.ADD_PROFILE_ITEM";
  static DELETE_PROFILE_ITEM = "ProfilerAction.DELETE_PROFILE_ITEM";

  static ADD_IMAGE = "ProfilerAction.ADD_IMAGE";
  static ADD_NAME = "ProfilerAction.ADD_NAME";
  static ADD_EMAIL = "ProfilerAction.ADD_EMAIL";
  static ADD_PHONE = "ProfilerAction.ADD_PHONE";
  static ADD_PASSWORD = "ProfilerAction.ADD_PASSWORD";
  static ADD_TELEGRAM = "ProfilerAction.ADD_TELEGRAM";

  static DELETE_PROFILE_INFO = "ProfilerAction.DELETE_PROFILE_INFO";

  static FETCH_FRIEND = "ProfilerAction.FETCH_FRIEND";
  static ANALYZE_CONTENT = "ProfilerAction.ANALYZE_CONTENT";
  static ATTACH_HYDRA = "ProfilerAction.ATTACH_HYDRA";
  static DETACH_HYDRA = "ProfilerAction.DETACH_HYDRA";

  static SET_PERSON_LOADING = "ProfilerAction.SET_PERSON_LOADING";

  static EXPORT = "ProfilerAction.EXPORT";
  static EXPORT_CASE = "ProfilerAction.EXPORT_CASE";

  static START_SILENT_REFRESH = "ProfilerAction.START_SILENT_REFRESH";

  static updateProfilerState(action) {
    return ActionUtility.createAction(ProfilerAction.UPDATE_PROFILER_STATE, action);
  }


  /* Locations */
  static getLocations(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_LOCATIONS, {id, callback,});
  }

  /* Person Section */
  static setPersonLoading(action) {
    return ActionUtility.createAction(
      ProfilerAction.SET_PERSON_LOADING,
      action
    );
  }
  static getPerson(id) {
    return ActionUtility.createAction(ProfilerAction.GET_PERSON, { id });
  }
  static setPerson(action) {
    return ActionUtility.createAction(ProfilerAction.SET_PERSON, action);
  }

  /* Personal Information Section */
  static getPersonalInfo(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_PERSONAL_INFO, {id, callback});
  }
  static addPersonalInfo(field, value, callback = null) {
    return ActionUtility.createAction(ProfilerAction.ADD_PERSONAL_INFO, {field, value, callback});
  }
  static updatePersonalInfo(field, value) {
    return ActionUtility.createAction(ProfilerAction.UPDATE_PERSONAL_INFO, {field, value});
  }

  /* Friends */
  static getFriends(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_FRIENDS, {id, callback,});
  }
  static fetchMoreFriends(targetId, socialNetwork, socialProfileId, size = 50, callback) {
    return ActionUtility.createAction(
      ProfilerAction.FETCH_MORE_FRIENDS,
      {targetId, socialNetwork, socialProfileId, size, callback,}
    );
  }

  /* Images */
  static getImages(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_IMAGES, {id, callback,});
  }

  /* Web Search */
  static getWebSearch(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_WEB_SEARCH, {id, callback,});
  }

  /* Posts */
  static getPosts(id, filters, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_POSTS, {id, filters, callback,});
  }
  static fetchMorePosts(targetId, socialNetwork, socialProfileId, size = 50, callback) {
    return ActionUtility.createAction(
      ProfilerAction.FETCH_MORE_POSTS,
      {targetId, socialNetwork, socialProfileId, size, callback,}
    );
  }

  /* Preferences */
  static getPreferences(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_PREFERENCES, {id, callback,});
  }

  /* Contact Info */
  static getContactInfo(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_CONTACT_INFO, {id, callback,});
  }

  /* DeepWeb */
  static getDeepWeb(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_DEEP_WEB, {id, callback,});
  }

  /* Synopsis */
  static getSynopsis(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_SYNOPSIS, {id, callback,});
  }
  static setSynopsis(id, content, callback) {
    return ActionUtility.createAction(ProfilerAction.SET_SYNOPSIS, {id, content, callback,});
  }

  /* Statistics */
  static getGeneralStat(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_GENERAL_STAT, {id, callback,});
  }
  static getPostStat(id, filters, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_POST_STAT, {id, filters, callback,});
  }
  static getActivityStat(id, socialNetwork = null, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_ACTIVITY_STAT, {id, socialNetwork, callback,});
  }

  /* Search progress */
  static getSearchProgress(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_SEARCH_PROGRESS, {id, callback,});
  }
  static getImageSearchResults(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_IMAGE_SEARCH_RESULTS, {id, callback,});
  }
  static getFaceRecognitionProgress(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_FACE_RECOGNITION_PROGRESS, {id, callback,});
  }

  /* History */
  static getHistory(id, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_HISTORY, {id, callback,});
  }

  /*Get AI post analysis*/
  static getPostAnalysis(targetId, postId, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_POST_ANALYSIS, {targetId, postId, callback,});
  }

  /*Get AI psycho profile*/
  static startPsychoProfile(targetId, callback) {
    return ActionUtility.createAction(ProfilerAction.START_PSYCHO_PROFILE, {targetId, callback,});
  }
  static getPsychoProfile(targetId, callback) {
    return ActionUtility.createAction(ProfilerAction.GET_PSYCHO_PROFILE, {targetId, callback,});
  }
  static updateSniperUrl(targetId, id, value, callback) {
    return ActionUtility.createAction(ProfilerAction.UPDATE_SNIPER_URL, {targetId, id, value, callback,});
  }

  /* Add source */
  static addSource(targetId, sourceData, callback) {
    return ActionUtility.createAction(ProfilerAction.ADD_SOURCE, {targetId, sourceData, callback,});
  }
  static updateSources(sourceData) {
    return ActionUtility.createAction(ProfilerAction.UPDATE_SOURCES, sourceData,);
  }

  /* Update source */
  static updateSource(targetId, sourceId, socialData, callback) {
    return ActionUtility.createAction(ProfilerAction.UPDATE_SOURCE, {targetId, sourceId, socialData, callback,});
  }
  static incrementalSource(targetId, sourceId, socialData, callback) {
    return ActionUtility.createAction(ProfilerAction.INCREMENTAL_SOURCE, {targetId, sourceId, socialData, callback});
  }
  static deleteSource(targetId, socialId, callback) {
    return ActionUtility.createAction(ProfilerAction.DELETE_SOURCE, {targetId, socialId, callback,});
  }

  /*Rerun Target*/
  static reRunTarget(targetId, callback) {
    return ActionUtility.createAction(ProfilerAction.RERUN_TARGET, {targetId, callback,});
  }
  static fetchTelegram(targetId, callback) {
    return ActionUtility.createAction(ProfilerAction.FETCH_TELEGRAM, {targetId, callback,});
  }
  static fetchGetContact(targetId, callback) {
    return ActionUtility.createAction(ProfilerAction.FETCH_GETCONTACT, {targetId, callback,});
  }
  static fetchName(targetId, lookupRequestId, callback) {
    return ActionUtility.createAction(ProfilerAction.FETCH_NAME, {targetId, lookupRequestId, callback,});
  }
  static rerunCrawling(targetId, lookupRequestId, callback) {
    return ActionUtility.createAction(ProfilerAction.RERUN_CRAWLING, {targetId, lookupRequestId, callback,});
  }

  /* profile items add/delete*/
  static addProfileItem(targetId, lriId, callback) {
    return ActionUtility.createAction(ProfilerAction.ADD_PROFILE_ITEM, {targetId, lriId, callback,});
  }
  static deleteProfileItem(targetId, lriId, callback) {
    return ActionUtility.createAction(ProfilerAction.DELETE_PROFILE_ITEM, {targetId, lriId, callback,});
  }

  /* Add image*/
  static addImage(targetId, formData, callback) {
    return ActionUtility.createAction(ProfilerAction.ADD_IMAGE, {targetId, formData, callback,});
  }

  /* Add name*/
  static addName(targetId, name, callback) {
    return ActionUtility.createAction(ProfilerAction.ADD_NAME, {targetId, name, callback,});
  }

  /* Add email*/
  static addEmail(targetId, email, callback) {
    return ActionUtility.createAction(ProfilerAction.ADD_EMAIL, {targetId, email, callback,});
  }

  /* Add phone*/
  static addPhone(targetId, phone, callback) {
    return ActionUtility.createAction(ProfilerAction.ADD_PHONE, {targetId, phone, callback,});
  }

  /* Add password*/
  static addPassword(targetId, password, callback) {
    return ActionUtility.createAction(ProfilerAction.ADD_PASSWORD, {targetId, password, callback,});
  }

  /* Add telegram*/
  static addTelegram(targetId, telegramId, callback) {
    return ActionUtility.createAction(ProfilerAction.ADD_TELEGRAM, {targetId, telegramId, callback,});
  }

  /*Remove item from profiler*/
  static deleteProfileInfo(targetId, lrID, callback) {
    return ActionUtility.createAction(ProfilerAction.DELETE_PROFILE_INFO, {targetId, lrID, callback,});
  }

  /* Fetch friend */
  static fetchFriend(targetId, friend, callback) {
    return ActionUtility.createAction(ProfilerAction.FETCH_FRIEND, {targetId, friend, callback,});
  }

  /* Analyze content */
  static analyzeContent(content, callback) {
    return ActionUtility.createAction(ProfilerAction.ANALYZE_CONTENT, {content, callback,});
  }

  /* Attach hydra item */
  static attachHydra(targetId, hydraItemId, callback) {
    return ActionUtility.createAction(ProfilerAction.ATTACH_HYDRA, {targetId, hydraItemId, callback,});
  }

  /* Detach hydra item */
  static detachHydra(targetId, hydraItemId, callback) {
    return ActionUtility.createAction(ProfilerAction.DETACH_HYDRA, {targetId, hydraItemId, callback,});
  }

  /* Export */
  static export(type, sections) {
    return ActionUtility.createAction(ProfilerAction.EXPORT, {type, sections});
  }
  static exportCase(type, caseId, callback) {
    return ActionUtility.createAction(ProfilerAction.EXPORT_CASE, {type, caseId, callback,});
  }

  static startSilentRefresh(targetId, callback) {
    return ActionUtility.createAction(ProfilerAction.START_SILENT_REFRESH, {targetId, callback,});
  }
}
