import { SupportService } from "../../services/support";
import { capitalize } from "../../helpers/capitalize";

export const prepareCreateMetaAvatar = async (info) => {
  const postData = {
    gender: info.gender?.toUpperCase(),
    firstName: info.firstName,
    lastName: info.lastName,
    phone: info.phone,
    bday: info.birthDate,
    password: info.password,
    details: info.description,
    location: info?.location,
    isDefault: info?.isDefault,
    proxy: {},
  };
  postData.email = {
    address: info.email,
  };

  if (info?.images?.length > 0) {
    //const [photo] = info.images;
    //postData.base64Photo = await SupportService.toBase64(photo.originFileObj);

    const unresolved = info.images.map(async(photo) => {
      const base64image = await SupportService.toBase64(photo.originFileObj);
      return base64image;
    })

    postData.base64Photos = await Promise.all(unresolved)
  }

  // {
  //   "id": "string",
  //   "email": {
  //   "address": "string",
  //     "domain": "string",
  //     "login": "string",
  //     "password": "string",
  //     "incomingServer": "string",
  //     "outgoingServer": "string",
  //     "emailProtocol": "UNDEFINED"
  // },
  //   "username": "string",
  //   "proxy": {
  //   "custom": true,
  //     "country": "string",
  //     "domain": "string",
  //     "ipAddress": "string",
  //     "port": "string",
  //     "login": "string",
  //     "password": "string",
  //     "proxyType": "HTTPS"
  // },
  //   "gender": "MALE",
  //   "firstName": "string",
  //   "lastName": "string",
  //   "phone": "string",
  //   "base64Photo": "string",
  //   "login": "string",
  //   "password": "string",
  //   "details": "string",
  //   "socialNetworks": [],
  //   "bday": "2022-01-24"
  // }
  const { proxy } = info;
  if (proxy) {
    if (proxy.proxyTypeSelect === "standard") {
      postData.proxy.country = proxy.country;
    }
    if (
      proxy.proxyTypeSelect === "custom" ||
      proxy.proxyTypeSelect === "templates"
    ) {
      postData.proxy = {
        custom: true,
        domain: proxy.domainName,
        ipAddress: proxy.domainName,
        port: proxy.port,
        login: proxy.proxyUser,
        password: proxy.proxyPass,
        proxyType: "HTTPS",
      };
    }
  }

  console.log("after post", postData);

  return postData;
};

export const prepareLogsCattegories = (data) => {
  return SupportService.unique(data.map((el) => el.auditType));
};

export const prepareUpdateMetaAvatar = async (info) => {
  console.log("before post", info);

  const postData = {
    gender: info.gender?.toUpperCase(),
    firstName: info.firstName,
    lastName: info.lastName,
    phone: info.phone,
    bday: info.birthDate,
    base64Photo: info.base64Photo,
    email: {
      address: info.email,
    },
    password: info.password,
    details: info.description,
    location: info?.location,
    isDefault: info?.isDefault,
    proxy: {},
  };
  if (info?.images?.length > 0) {
    //const [photo] = info.images;
    //postData.base64Photo = await SupportService.toBase64(photo.originFileObj);
    const unresolved = info.images.map(async(photo) => {
      return photo.url || await SupportService.toBase64(photo.originFileObj);
    })

    postData.base64Photos = await Promise.all(unresolved)
  }
  const { proxy } = info;
  if (proxy) {
    if (proxy.proxyTypeSelect === "custom") {
      postData.proxy = {
        custom: proxy.custom,
        domain: proxy.domainName,
        ipAddress: proxy.domainName,
        port: proxy.port,
        login: proxy.proxyUser,
        password: proxy.proxyPass,
        proxyType: "HTTPS",
      };
    }
    if (proxy.proxyTypeSelect === "templates") {
      postData.proxy = {
        custom: proxy.custom,
        domain: proxy.domainNameStandard,
        ipAddress: proxy.domainNameStandard,
        port: proxy.portStandard,
        login: proxy.proxyUserStandard,
        password: proxy.proxyPassStandard,
        proxyType: "HTTPS",
      };
    }
  }
  console.log("after post", postData);

  return postData;
};

export const transformMetaAvatarsList = (list = []) => {
  const { content = [], ...rest } = list;
  const out = { content: [], ...rest };
  content.forEach((meta) => {
    out.content.push({
      meta: {
        ...meta,
        avatar: meta?.base64Photos?.[0] || meta?.base64Photo,
        name: capitalize(meta.firstName) + " " + capitalize(meta.lastName),
        id: meta.id,
        phone: meta.phone,
        email: meta.email.address,
        username: meta.username,
        metaId: meta.metaAvatarID || meta.id,
      },
      avatars: meta.socialNetworks,
    });
  });

  return out;
};

export const transformBlockedAvatars = (list = []) => {
  const { content = []/*, ...rest*/ } = list;
  //console.log('transformBlockedAvatars', content);
  const filtered = content
    .filter((el) => el.socialNetworks.length)
    .map((el) => el.socialNetworks)
    .reduce((flat, current) => {
      return flat.concat(current);
    }, [])
    .filter((el) => el.statusMessage);
  const out = SupportService.getBlockedReasons()
    .map((el) => {
      return {
        ...el,
        number: filtered.filter(
          //(toFilter) => toFilter.blockedReason === el.type
          (toFilter) => {
            return toFilter.statusMessage?.indexOf(el.type) === 0
          }
        ).length,
      };
    })
    .filter((el) => el.number > 0);
  return out;
};

export const filterMetaAvatarsList = (list = [], filters = {}) => {
  const matchName = ({ meta, avatars }, { name }) => {
    return name
      ? meta.name?.toLowerCase()?.indexOf(name?.toLowerCase()) > -1
      : true;
  };
  const matchId = ({ meta, avatars }, { id = "" }) => {
    return id ? meta.id.toLowerCase().indexOf(id.toLowerCase()) > -1 : true;
  };
  const matchUsername = ({ meta }, { username = "" }) => {
    return username
      ? meta.username.toLowerCase().indexOf(username.toLowerCase()) > -1
      : true;
  };
  const matchEmail = ({ meta }, { email = "" }) => {
    return email
      ? meta.email.toLowerCase().indexOf(email.toLowerCase()) > -1
      : true;
  };
  const matchPhone = ({ meta }, { phone = "" }) => {
    return phone
      ? meta.phone.toLowerCase().indexOf(phone.toLowerCase()) > -1
      : true;
  };

  //profile ID
  const matchProfileId = ({ meta, avatars }, { profileId = "" }) => {
    return profileId
      ? avatars.filter(
          (pr) =>
            pr.username?.toLowerCase().indexOf(profileId.toLowerCase()) > -1
        )?.length > 0
      : true;
  };
  const filterProfileId = ({ avatars }, { profileId = "" }) => {
    return profileId
      ? avatars.filter(
          (pr) =>
            pr.username?.toLowerCase().indexOf(profileId.toLowerCase()) > -1
        )
      : avatars;
  };

  //social network
  const matchSocialNetworks = ({ meta, avatars }, { socialNetworks = [] }) => {
    return socialNetworks?.length
      ? avatars.filter((pr) => socialNetworks.includes(pr.domain))?.length > 0
      : true;
  };
  const filterSocialNetworks = ({ avatars }, { socialNetworks = [] }) => {
    return socialNetworks?.length
      ? avatars.filter((pr) => socialNetworks.includes(pr.domain))
      : avatars;
  };

  //tasks
  const matchModules = ({ meta, avatars }, { modules = [] }) => {
    return modules?.length
      ? avatars.filter((pr) => modules.includes(pr.module))?.length > 0
      : true;
  };
  const filterModules = ({ avatars }, { modules = [] }) => {
    return modules?.length
      ? avatars.filter((pr) => modules.includes(pr.module))
      : avatars;
  };
  const matchTaskId = ({ avatars }, { task = "" }) => {
    return task
      ? avatars.filter((pr) =>
          pr.assignedTaskIds?.find(
            (el) => el.toLowerCase().indexOf(task.toLowerCase()) > -1
          )
        )?.length > 0
      : true;
  };
  const filterTaskId = ({ avatars }, { task = "" }) => {
    return task
      ? avatars.filter((pr) =>
          pr.assignedTaskIds?.find(
            (el) => el.toLowerCase().indexOf(task.toLowerCase()) > -1
          )
        )
      : avatars;
  };

  //quarantine types
  const matchQuarantineType = ({ meta, avatars }, { quarantineTypes = [] }) => {
    return quarantineTypes?.length
      ? avatars.filter((pr) =>
          quarantineTypes.includes(pr.useQuarantine ? "AUTO" : "MANUAL")
        )?.length > 0
      : true;
  };
  const filterQuarantineType = ({ avatars }, { quarantineTypes = [] }) => {
    return quarantineTypes?.length
      ? avatars.filter((pr) =>
          quarantineTypes.includes(pr.useQuarantine ? "AUTO" : "MANUAL")
        )
      : avatars;
  };

  //status
  const matchStatus = ({ meta, avatars }, { statuses = [] }) => {
    return statuses?.length
      ? avatars.filter((pr) => statuses.includes(pr.status))?.length > 0
      : true;
  };
  const filterStatus = ({ avatars }, { statuses = [] }) => {
    return statuses?.length
      ? avatars.filter((pr) => statuses.includes(pr.status))
      : avatars;
  };

  //blocked reasons
  const matchBlockedReasons = ({ meta, avatars }, { blockedReasons = [] }) => {
    return blockedReasons?.length
      ? avatars.filter((pr) => blockedReasons.includes(pr.blockedReason))
          ?.length > 0
      : true;
  };
  const filterBlockedReasons = ({ avatars }, { blockedReasons = [] }) => {
    return blockedReasons?.length
      ? avatars.filter((pr) => blockedReasons.includes(pr.blockedReason))
      : avatars;
  };

  //status message
  const matchMessage = ({ meta, avatars }, { message = "" }) => {
    return message
      ? avatars.filter(
          (pr) =>
            pr.statusMessage?.toLowerCase().indexOf(message.toLowerCase()) > -1
        )?.length > 0
      : true;
  };
  const filterMessage = ({ avatars }, { message = "" }) => {
    return message
      ? avatars.filter(
          (pr) =>
            pr.statusMessage?.toLowerCase().indexOf(message.toLowerCase()) > -1
        )
      : avatars;
  };

  const out = list.filter((meta) => {
    meta.avatars = filterProfileId(meta, filters);
    meta.avatars = filterSocialNetworks(meta, filters);
    meta.avatars = filterModules(meta, filters);
    meta.avatars = filterTaskId(meta, filters);
    meta.avatars = filterQuarantineType(meta, filters);
    meta.avatars = filterStatus(meta, filters);
    meta.avatars = filterBlockedReasons(meta, filters);
    meta.avatars = filterMessage(meta, filters);

    return (
      matchName(meta, filters) &&
      matchId(meta, filters) &&
      matchUsername(meta, filters) &&
      matchPhone(meta, filters) &&
      matchEmail(meta, filters) &&
      matchProfileId(meta, filters) &&
      matchSocialNetworks(meta, filters) &&
      matchModules(meta, filters) &&
      matchTaskId(meta, filters) &&
      matchQuarantineType(meta, filters) &&
      matchStatus(meta, filters) &&
      matchBlockedReasons(meta, filters) &&
      matchMessage(meta, filters)
    );
  });

  //console.log("out filters", out);

  return out;
};

export const transformMetaAvatar = (meta) => {
  const out = { ...meta };

  out.gender = meta.gender?.toLowerCase();
  out.socialNetworks = out.socialNetworks?.map((ava) => {
    ava.status = ava.status || "ACTIVE";
    return ava;
  });
  out.avatar = meta?.base64Photos?.[0] || meta?.base64Photo;

  return out;
};

export const prepareCreateAvatar = (info) => {
  const postData = {
    //id: "",
    username: info.username,
    password: info.password,
    domain: info.domain,
    proxy: {},
    userAgent: "",
    additionalInfo: info.additionalInfo,
    useQuarantine: info.useQuarantine,
    useAlerts: true,
    updateStatus: "AUTO",
    module: info.module,
    assignedTaskIds: [],
    mobileUserAgent: info?.mobileUserAgent,
    desktopUserAgent: info?.desktopUserAgent,
  };

  if (info.useQuarantine) {
    postData.quarantineInfo = {
      from: info.quarantineFrom,
      to: info.quarantineTo,
      numberOfUses: info.quarantineNumberOfUsers,
      daysInQuarantine: info.quarantineDays,
    };
  }

  const { proxy } = info;
  if (proxy) {
    if (proxy.proxyTypeSelect === "custom") {
      postData.proxy = {
        custom: proxy.custom,
        domain: proxy.domainName,
        ipAddress: proxy.domainName,
        port: proxy.port,
        login: proxy.proxyUser,
        password: proxy.proxyPass,
        proxyType: "HTTPS",
      };
    }
    if (proxy.proxyTypeSelect === "templates") {
      postData.proxy = {
        custom: proxy.custom,
        domain: proxy.domainNameStandard,
        ipAddress: proxy.domainNameStandard,
        port: proxy.portStandard,
        login: proxy.proxyUserStandard,
        password: proxy.proxyPassStandard,
        proxyType: "HTTPS",
      };
    }
  }

  // "id": "string",
  //   "username": "string",
  //   "password": "string",
  //   "domain": "FB",
  //   "status": "VALID",
  //   "statusMessage": "string",
  //   "proxy": {
  //   "custom": true,
  //     "country": "string",
  //     "domain": "string",
  //     "ipAddress": "string",
  //     "port": "string",
  //     "login": "string",
  //     "password": "string",
  //     "proxyType": "HTTPS"
  // },
  // "userAgent": "string",
  //   "additionalInfo": {
  //   "apiKey": "string",
  //     "apiSecret": "string",
  //     "apiAccessToken": "string",
  //     "apiAccessTokenSecret": "string",
  //     "apiAppId": "string",
  //     "apiRefreshToken": "string",
  //     "apiSecretKey": "string"
  // },
  // "useAlerts": true,
  //   "useQuarantine": true,
  //   "quarantineInfo": {
  //   "from": 0,
  //     "to": 0,
  //     "numberOfUses": 0,
  //     "daysInQuarantine": 0
  // },
  // "updatedStatus": "AUTO",
  //   "module": "PROFILER",
  return postData;
};

export const prepareUpdateAvatar = (info) => {
  const postData = {
    username: info.username,
    password: info.password,
    domain: info.domain,
    proxy: {},
    userAgent: "",
    additionalInfo: info.additionalInfo,
    useQuarantine: info.useQuarantine,
    useAlerts: true,
    updateStatus: "AUTO",
    module: info.module,
    assignedTaskIds: [],
    mobileUserAgent: info?.mobileUserAgent,
    desktopUserAgent: info?.desktopUserAgent,
  };

  if (info.useQuarantine) {
    postData.quarantineInfo = {
      from: info.quarantineFrom,
      to: info.quarantineTo,
      numberOfUses: info.quarantineNumberOfUsers,
      daysInQuarantine: info.quarantineDays,
    };
  }

  const { proxy } = info;
  if (proxy) {
    if (proxy.proxyTypeSelect === "standard") {
      postData.proxy.country = proxy.country;
    }
    if (proxy.proxyTypeSelect === "custom") {
      postData.proxy = {
        custom: proxy.custom,
        domain: proxy.domainName,
        ipAddress: proxy.domainName,
        port: proxy.port,
        login: proxy.proxyUser,
        password: proxy.proxyPass,
        proxyType: "HTTPS",
      };
    }
    if (proxy.proxyTypeSelect === "templates") {
      postData.proxy = {
        custom: proxy.custom,
        domain: proxy.domainNameStandard,
        ipAddress: proxy.domainNameStandard,
        port: proxy.portStandard,
        login: proxy.proxyUserStandard,
        password: proxy.proxyPassStandard,
        proxyType: "HTTPS",
      };
    }
  }

  // "id": "string",
  //   "username": "string",
  //   "password": "string",
  //   "domain": "FB",
  //   "status": "VALID",
  //   "statusMessage": "string",
  //   "proxy": {
  //   "custom": true,
  //     "country": "string",
  //     "domain": "string",
  //     "ipAddress": "string",
  //     "port": "string",
  //     "login": "string",
  //     "password": "string",
  //     "proxyType": "HTTPS"
  // },
  // "userAgent": "string",
  //   "additionalInfo": {
  //   "apiKey": "string",
  //     "apiSecret": "string",
  //     "apiAccessToken": "string",
  //     "apiAccessTokenSecret": "string",
  //     "apiAppId": "string",
  //     "apiRefreshToken": "string",
  //     "apiSecretKey": "string"
  // },
  // "useAlerts": true,
  //   "useQuarantine": true,
  //   "quarantineInfo": {
  //   "from": 0,
  //     "to": 0,
  //     "numberOfUses": 0,
  //     "daysInQuarantine": 0
  // },
  // "updatedStatus": "AUTO",
  //   "module": "PROFILER",
  return postData;
};

export const prepareSaveProxy = ({ proxy }) => {
  /*domainName: ""
  port: ""
  proxyPassword: ""
  proxyProtocol: "HTTPS"
  proxyType: "custom"
  proxyUser: ""*/

  /*{
    "custom": true,
    "country": "string",
    "domain": "string",
    "ipAddress": "string",
    "port": "string",
    "login": "string",
    "password": "string",
    "proxyType": "HTTPS"
  }*/

  const postData = {
    custom: proxy.proxyType === "custom",
    country: proxy.country,
    domain: proxy.domainName,
    ipAddress: proxy.domainName,
    port: proxy.port,
    login: proxy.proxyUser,
    password: proxy.proxyPassword,
    proxyType: proxy.proxyProtocol,
  };

  return postData;
};

export const transformIntoNodeTost = (
  textMessage,
  dataArray,
  linkCallbacks
) => {
  return (
    <p>
      {textMessage}
      {dataArray.map((el) => {
        // eslint-disable-next-line
        return <a onClick={() => linkCallbacks(el)}>{el.id}</a>;
      })}
    </p>
  );
};
