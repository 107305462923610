import React from 'react';
import {Image, Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {SupportService} from "../../../../support";
import {withTranslation} from "react-i18next";

const SearchResults = ({t, data}) => {
  const {searchProgress} = data;
  const {searchResults = []} = searchProgress;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Search results")}</Text>


      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
        {
          searchResults.map((res, idx) =>
            <View key={idx} style={{width: '150px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
              <View>
                <Image style={{width: '50px', height: '50px', marginRight: '10px'}} src={res.avatar}/>
              </View>
              <View>
                <Text style={{fontSize:'10px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{res.name}</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <Link src={SupportService.getSocialProfileUrl(res.socialNetwork, res.profileId)} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <SocialIcon network={res.socialNetwork} />
                    <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{res.profileId}</Text>
                  </Link>
                </View>
              </View>
            </View>
          )
        }
      </View>
    </View>
  );
};

export default withTranslation()(SearchResults);