import ActionUtility from "../ActionUtility";

export default class RekognitionAction {
  static DETECT = "RekognitionAction.DETECT";
  static SEARCH = "RekognitionAction.SEARCH";
  static ENROLL = "RekognitionAction.ENROLL";
  static DELETE = "RekognitionAction.DELETE";

  static RECOGNIZE_FACES = "RekognitionAction.RECOGNIZE_FACES";

  /* Detect */
  static detect(image, callback) {
    return ActionUtility.createAction(RekognitionAction.DETECT, {image, callback,});
  }

  /* Search */
  static search(image, profileId = null, callback) {
    return ActionUtility.createAction(RekognitionAction.SEARCH, {image, profileId, callback,});
  }

  /* Enroll */
  static enroll(image, imageId, targetId, callback) {
    return ActionUtility.createAction(RekognitionAction.ENROLL, {image, imageId, targetId, callback,});
  }

  /* Delete */
  static delete(image, imageId, targetId, callback) {
    return ActionUtility.createAction(RekognitionAction.DELETE, {image, imageId, targetId, callback,});
  }

  /*Recognize faces*/
  static recognizeFaces(targetId, callback) {
    return ActionUtility.createAction(RekognitionAction.RECOGNIZE_FACES, {targetId, callback,});
  }

}
