import { put, takeEvery } from "redux-saga/effects";
import { apiProxyService } from "../../config/api";
import SettingsAction from "../settings/settingsAction";
import ProxyAction from "./proxyAction";
import { prepareProxy } from "./proxyTransformer";

function* getProxytSaga(action) {
  const { filter, callback } = action.payload;
  const page = filter?.page ? filter.page : 0;
  const size = filter?.size ? filter.size : 20;
  try {
    const { data } = yield apiProxyService.getProxiesListPaged(size, page);
    yield put(ProxyAction.updateProxyState({ proxies: data }));
    callback(data);
  } catch (e) {
    callback([]);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* createProxyTemplate(action) {
  const { data, callback } = action.payload;
  try {
    const reqObject = prepareProxy(data);
    const { response } = yield apiProxyService.createProxyServer(reqObject);
    yield callback(response);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* deleteProxySaga(action) {
  const { data, callback } = action.payload;
  try {
    yield apiProxyService.deleteProxyServer(data.id);
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* updateProxySaga(action) {
  const { data, callback, id } = action.payload;
  try {
    const reqObject = {
      ...prepareProxy(data),
      id,
    };
    const { response } = yield apiProxyService.updateProxyServer(reqObject);
    yield callback(response);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

export function* watchProxy() {
  yield takeEvery(ProxyAction.GET_ALL_PROXY_TEMPLATES, getProxytSaga);
  yield takeEvery(ProxyAction.CREATE_PROXY_TEMPLATE, createProxyTemplate);
  yield takeEvery(ProxyAction.DELETE_PROXY, deleteProxySaga);
  yield takeEvery(ProxyAction.UPDATE_PROXY, updateProxySaga);
}
