import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18next from "i18next";

const LanguageWrapper = ({ children, chosenLang }) => {
  useEffect(() => {
    i18next.changeLanguage(chosenLang);
  }, [chosenLang]);

  return <div>{children}</div>;
};

const mapStateToProps = state => {
  return {
    chosenLang: state.settings.chosenLang
  };
};

export default connect(mapStateToProps)(withTranslation()(LanguageWrapper));
