import BaseReducer from "../BaseReducer";
import PopupAction from "./popupAction";

export default class PopupReducer extends BaseReducer {
  initialState = {
    isPopupShown: false,
    popupContent: null,
  };

  [PopupAction.SHOW_POPUP](state, action) {
    return {
      ...state,
      isPopupShown: true,
      popupContent: action.payload,
    };
  }

  [PopupAction.CLOSE_POPUP](state, action) {
    return {
      ...state,
      isPopupShown: false,
      popupContent: null,
    };
  }
}
