import { put, select, takeEvery } from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import { getFromStorage, saveToStorage } from "../../helpers/gridSaver";
import { getLayoutItems } from "./settingsSelector";
import { getUser } from "../auth/authSelector";
import { notification } from "antd";
import AuthAction from "../auth/authAction";
import {transformSomeErrorMessages} from "./settingsTransformer";

function* showNotificationSaga(action) {
  const { message } = action.payload;
  yield notification.success({ message, placement: "bottomRight" });
  //yield delay(duration);
  //yield put(RootScopeAction.clearNotifications());
}

function* handleAPICustomMessageSaga(action) {
  const { reactNode, tostConfig, type } = action.payload;
  try {
    switch (type) {
      case "success":
        notification.success({
          message: reactNode,
          placement: "bottomRight",
          ...tostConfig,
        });
        break;
      case "error":
        notification.error({
          message: reactNode,
          placement: "bottomRight",
          ...tostConfig,
        });
        break;
      case "info":
        notification.info({
          message: reactNode,
          placement: "bottomRight",
          ...tostConfig,
        });
        break;
      default:
        notification.error({
          message: "Something wrong with this notification",
        });
        break;
    }
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* handleAPIErrorSaga(action) {
  console.log("action.payload", action.payload.message);
  let { message, response } = action.payload;
  let dataMessage;
  if (response) {
    const { data, status } = response;
    if (data && data.message) {
      dataMessage = transformSomeErrorMessages(data.message);
    }
    if (status === 401) {
      yield put(AuthAction.logout({}));
    }
  }

  const toast = dataMessage || message;
  /*yield put(SettingsAction.showNotification({
    text: toast,
    type: "error",
    duration: 5000
  }));*/
  notification.error({ message: toast, placement: "bottomRight" });
}

function* handleInitLayout(action) {
  //console.log("init Layout saga", action.payload);
  const { keyLayout, defaultLayout, keyStorage } = action.payload;
  const storageItems = yield getFromStorage(keyStorage, null);
  if (storageItems) {
    const validItems = storageItems.filter((item) =>
      defaultLayout.includes(item)
    );
    if (storageItems.length !== validItems.length) {
      saveToStorage(keyStorage, validItems);
    }
    yield put(SettingsAction.setLayout(keyLayout, storageItems));
  } else {
    yield put(SettingsAction.setLayout(keyLayout, defaultLayout));
    saveToStorage(keyStorage, defaultLayout);
  }
}

function* handleChangeLayout(action) {
  console.log("delete Layout saga", action.payload);
  const { keyLayout } = action.payload;
  const updatedLayouts = yield select((state) =>
    getLayoutItems(state, keyLayout)
  );
  const { username } = yield select(getUser);

  console.log("new layout", updatedLayouts);
  if (updatedLayouts) {
    saveToStorage(`grid-layout-${keyLayout}-${username}`, updatedLayouts);
  }
}

export function* watchSettings() {
  yield takeEvery(SettingsAction.SHOW_NOTIFICATION, showNotificationSaga);
  yield takeEvery(SettingsAction.HANDLE_API_ERROR, handleAPIErrorSaga);
  yield takeEvery(SettingsAction.INIT_LAYOUT, handleInitLayout);
  yield takeEvery(SettingsAction.DELETE_LAYOUT_ITEM, handleChangeLayout);
  yield takeEvery(SettingsAction.ADD_LAYOUT_ITEM, handleChangeLayout);
  yield takeEvery(
    SettingsAction.HANDLE_API_CUSTOM_MESSAGE,
    handleAPICustomMessageSaga
  );
}
