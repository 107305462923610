import React from 'react';
import {Text, View, Link} from "@react-pdf/renderer";
import {withTranslation} from "react-i18next";

const Groups = withTranslation()(({t, data}) => {
  return (
    <View style={{borderBottom: '1px solid #ececec'}}>
      <Text style={{marginBottom: '10px', fontFamily: 'pdfFont', fontSize: '18px'}}>{t("Webint Users Statistics")}</Text>
      <View style={{}} >
        <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px', width: '100%'}}>
          <View style={{width: '280px'}}>
            <Text style={{fontSize: '12px', fontFamily: 'pdfFont'}}>{t("User")}</Text>
          </View>
          <View style={{width: '280px',}}>
            <Text style={{fontSize: '12px', fontFamily: 'pdfFont'}}>{t("Date")}</Text>
          </View>
          <View style={{width: '500px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
            <Text style={{fontSize: '12px', fontFamily: 'pdfFont'}}>{t("Avatars")}</Text>
          </View>
          <View style={{width: '300px',}}>
            <Text style={{fontSize: '12px', fontFamily: 'pdfFont',}}>{t("Task name")}</Text>
          </View>
          <View style={{width: '270px', }}>
            <Text style={{fontSize: '12px', fontFamily: 'pdfFont',}}>{t("Monitoring")}</Text>
          </View>
        </View>
        {
          data.map(activity => <View key={activity.id} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px', width: '100%'}}>
            <View style={{width: '280px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <Text style={{fontSize: '8px'}}>{activity.user}</Text>
            </View>
            <View style={{width: '280px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <Text style={{fontSize: '8px'}}>{activity.date}</Text>
            </View>
            <View style={{width: '500px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <Text style={{fontSize: '8px'}}>{activity.avatars}</Text>
            </View>
            <View style={{width: '300px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <Link src={`${window?.location?.origin}${activity.taskLink}`} style={{fontSize: '8px', fontFamily: 'pdfFont',}}>{activity.taskName}</Link>
            </View>
            <View style={{width: '270px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <Text style={{fontSize: '8px', fontFamily: 'pdfFont',}}>{activity.monitoringText}</Text>
            </View>
          </View>)
        }
      </View>
    </View>
  );
});

export default withTranslation()(Groups);