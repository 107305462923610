import moment from "moment";

class ExportJson {
  constructor(data) {
    this.data = Array.isArray(data) ? [...data] : {...data};
    this.fileName = 'export'
  }
  prepareProfiler() {
    const {person} = this.data;
    this.fileName = "PROFILER_" + person?.id + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  prepareWebint() {
    const {task} = this.data;
    this.fileName = "WEBINT_" + task?.id + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  prepareAudit() {
    this.fileName = "AUDIT_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  prepareAvatarAudit() {
    this.fileName = "AVATAR_AUDIT_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  prepareAvatarBlocked() {
    this.fileName = "AVATAR_BLOCKED_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  export() {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.data));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", this.fileName + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();

    return this;

    /*const url = window.URL.createObjectURL(new Blob([this.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', this.fileName + ".json");
    document.body.appendChild(link);
    link.click();

    return this;*/
  }
}

export default ExportJson