import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {Icon} from "../../Icons";
import {withTranslation} from "react-i18next";

const Locations = ({t, data}) => {
  const {locations = []} = data;
  return (
    <View>
      <Text style={{marginBottom: '20px'}}>{t("Locations")}</Text>

      {
        locations.map(({country, city}, idx) =>
          <View key={idx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <View style={{width: '50%', display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
              <Icon type={'mappin'} />
              <Text style={{fontSize: '13px', fontFamily: 'pdfFont',}}>{country}</Text>
            </View>
            <View style={{width: '50%'}}>
              <Text style={{fontSize: '13px', color: '#98adbe', fontFamily: 'pdfFont',}}>{city}</Text>
              {/*<Text style={{fontSize: '13px', color: '#98adbe'}}>lng: 14.66</Text>*/}
            </View>
          </View>
        )
      }

    </View>
  );
};

export default withTranslation()(Locations);