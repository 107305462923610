import React, { memo } from "react";
import * as Styled from "./styled";

import {ThemeProvider} from "styled-components";
import {themes} from "../../config/themes";
import config from "../../config/config";

const LoginLayout = memo((props) => {
  return (
    <ThemeProvider theme={themes?.[config.theme]}>
      <Styled.Content>
        {props.children}
      </Styled.Content>
    </ThemeProvider>
  );
});

export default LoginLayout;
