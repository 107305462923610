import React from "react";
import styled from "styled-components";
const images = require.context('../../../../assets/img/tree', true);

const IconContainer = styled.div`
  margin-right: 8px;
  display: inline-block;  
`;

const TreeIcon = ({type, expanded = false, style, title = ''}) => {
  try{
    const img = images(`./${type.toLowerCase()}${expanded ? '_exp' : ''}.png`);
    return <IconContainer style={style}><img alt={title ? title : type} title={title ? title : type} src={img.default} /></IconContainer>;
  } catch (e) {
    return <></>;
  }
};

export default TreeIcon;