import EN from "../assets/img/flags/gb.svg";
import UZ from "../assets/img/flags/uz.svg";
import RU from "../assets/img/flags/ru.svg";
import SP from "../assets/img/flags/sp.svg";
import FR from "../assets/img/flags/fr.svg";
import PT from "../assets/img/flags/pt.svg";

import locale_en from "antd/es/locale/en_US";
import locale_ru from "antd/es/locale/ru_RU";
import locale_sp from "antd/es/locale/es_ES";
import locale_fr from "antd/es/locale/fr_FR";
import locale_pt from "antd/es/locale/pt_PT";

import i18n from "i18next";
const env = window?._env_ || process.env;

const envLanguages = (env?.REACT_APP_LANGUAGES || '').split(',');

const locales = {
  languages: [
    {code: 'en', title: i18n.t('English'), icon: EN, locale: locale_en},
    {code: 'ru', title: i18n.t('Russian'), icon: RU, locale: locale_ru},
    {code: 'uz', title: i18n.t('Uzbek'), icon: UZ, locale: locale_en},
    {code: 'uz_cyrl', title: i18n.t('Uzbek Cyrillic'), icon: UZ, locale: locale_en},
    {code: 'sp', title: i18n.t('Spanish'), icon: SP, locale: locale_sp},
    {code: 'fr', title: i18n.t('French'), icon: FR, locale: locale_fr},
    {code: 'pt', title: i18n.t('Portuguese'), icon: PT, locale: {...locale_pt, Image: {
          preview: i18n.t('Preview')
        }}},
  ].filter(({code}) => envLanguages.includes(code))
}

export default locales;