export const regularTheme = {
  main_bg: "#f0efeb",
  white: "#fff",
  footer_copyright: "#B1B1B1",
  blue_bg: "#1D2331",
  dark_blue: "#1A7AC5",
  light_blue_bg: "#75B4E6",
  light_grey: "#DDDDDD",
  medium_grey: "#777777",
  widget_border: "#F3F3F3",
  yellow: "rgb(249,231,132)",
  green: "rgb(171,225,136)",
  dark_green: "#078C3C",
  red: "rgb(238,99,82)",
  form_bg: "#F8F9FA",
  count: "#EF6352",
  badge_warning_bg: "#FFC107",
  badge_warning_text: "#111111",
  targets_text: "#919191",
  create_case_text: "#A7B4C7",
  active_link: "#007BFF",
  status_red: "#FF5C45",
  status_yellow: "#FFA500",
  status_green: "#8BC34A",
  text: "#000",
  alert_text: "#A5A5A5",
  text_input_placeholder: "#CCCCCC",
  black_label: '#403838',
  toasts: {
    error: "#D00408",
    success: "#078C3C",
    warning: "#FFA500",
    general: "#3994DB",
  },
  wide_screen: "1024px",
  popup: {
    xs: '350px',
    sm: '500px',
    lg: '800px',
  },
  grid: {
    gutter: [{ xs: 8, sm: 16, md: 24, lg: 24 }, { xs: 8, sm: 16, md: 24, lg: 24 }],
    cell: {xs: 24, sm: 12, md: 12, lg: 6, xl: 4, xxl: 3},
  }
}