import React from "react";
import styled from "styled-components";

const Container = styled.div`
  //margin: 0 5px 3px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  img {
    object-fit: cover;
  }
`;
const TextContainer = styled.div`
  font-family: Roboto, sans-serif;
  margin-left: 5px;
`;

const Icon = ({
  type,
  style,
  styleImg,
  styleText,
  size = "",
  className = "",
  text,
  title = "",
}) => {
  const images = require.context("../../../../assets/img/icons", true);
  try {
    let img = images("./" + type.toLowerCase() + size + ".png");
    return (
      <Container className={className} style={style}>
        <img
          style={styleImg}
          alt={type}
          title={title ? title : type}
          src={img.default}
        />
        {!!text && <TextContainer style={styleText}>{text}</TextContainer>}
      </Container>
    );
  } catch (e) {
    return <></>;
  }
};

export default Icon;
