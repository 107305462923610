import BaseReducer from "../BaseReducer";
import ProxyAction from "./proxyAction";

export default class ProxyReducer extends BaseReducer {
  initialState = {
    proxies: [],
    pageNumber: 1,
    pageSize: 20,
  };
  [ProxyAction.SET_ALL_PROXY_TEMPLATES](state, action) {
    return {
      ...state,
      proxies: action.payload,
    };
  }

  [ProxyAction.UPDATE_PROXY_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }
}
