import moment from "moment";
import {imageByIndex} from "../../../../helpers/imageByIndex";
import {SupportService} from "../../../support";
import i18n from "i18next";

export class ExportXlsWebint {
  static exportTask = ({task}) => {
    const data = [
      ['ID', task.id],
      [i18n.t('Name'), task.name],
      [i18n.t('Type'), task.type],
      [i18n.t('Keywords'), task?.keywords?.map(({name}) => name).join(', ')],
      [i18n.t('Image URL'), imageByIndex(task.imageId)],
    ];

    return data;
  }

  static exportTargets = ({targets = []}) => {
    const data = [
      [i18n.t('Profile ID'), i18n.t('Name'), i18n.t('Social Network'), i18n.t('Type'), i18n.t('Profile Image')],
    ];

    targets.forEach(({profileId, socialNetwork, profileName, type, profileImageUrl}) => {
      data.push([profileId, profileName, socialNetwork, type, profileImageUrl]);
    })

    return data;
  }

  static exportGroups = ({groups = []}) => {
    const data = [
      [
        i18n.t('Group ID'),
        i18n.t('Group Name'),
        i18n.t('Social Network'),
        i18n.t('Type'),
        i18n.t('URL'),
        i18n.t('Members'),
        i18n.t('History'),
        i18n.t('Image URL')
      ],
    ];

    groups.forEach(({groupId, groupName, socialNetwork, groupData, groupImageUrl}) => {
      data.push([groupId, groupName, socialNetwork, groupData.groupType, groupData.url, groupData.membersCount, groupData.history, groupImageUrl]);
    })

    return data;
  }

  static exportPosts = ({posts}) => {
    const data = [
      [
        i18n.t('Social Network'),
        i18n.t('Profile ID'),
        i18n.t('Profile Name'),
        i18n.t('Published Date'),
        i18n.t('Text'),
        i18n.t('Shared Text'),
        i18n.t('Likes'),
        i18n.t('Comments'),
        i18n.t('Reactions'),
        i18n.t('Resonance'),
        i18n.t('Sentiment'),
        i18n.t('Link')
      ],
    ];

    posts.forEach(({socialNetwork, originalPostUid, originalPostUserName, timeStamp, text, sharedText, likeCount, commentsCount, totalReactionsCount, resonanceScore, sentiment, postUrl}) => {
      data.push([socialNetwork, originalPostUid, originalPostUserName, moment(timeStamp).format("YYYY/MM/DD HH:mm"), text, sharedText, likeCount, commentsCount, totalReactionsCount, resonanceScore, sentiment?.sentiment, postUrl]);
    })

    return data;
  }

  static exportTopPosts = ({topPosts}) => {
    const data = [
      [
        i18n.t('Social Network'),
        i18n.t('Profile ID'),
        i18n.t('Profile Name'),
        i18n.t('Published Date'),
        i18n.t('Text'),
        i18n.t('Shared Text'),
        i18n.t('Likes'),
        i18n.t('Comments'),
        i18n.t('Reactions'),
        i18n.t('Resonance'),
        i18n.t('Sentiment'),
        i18n.t('Link')
      ],
    ];

    topPosts.forEach(({socialNetwork, originalPostUid, originalPostUserName, timeStamp, text, sharedText, likeCount, commentsCount, totalReactionsCount, resonanceScore, sentiment, postUrl}) => {
      data.push([socialNetwork, originalPostUid, originalPostUserName, moment(timeStamp).format("YYYY/MM/DD HH:mm"), text, sharedText, likeCount, commentsCount, totalReactionsCount, resonanceScore, sentiment?.sentiment, postUrl]);
    })

    return data;
  }

  static exportImages = ({images = []}) => {
    const data = [
      [i18n.t('Source'), i18n.t('Keyword'), i18n.t('Image URL')],
    ];

    images.forEach(({searchEngine, keyword, imageUrl}) => {
      data.push([searchEngine, keyword, imageUrl]);
    })

    return data;
  }

  static exportVideos = ({videos = []}) => {
    const data = [
      [i18n.t('Source'), i18n.t('Keyword'), i18n.t('Title'), i18n.t('Description'), i18n.t('Likes'), i18n.t('Comments'), i18n.t('Link')],
    ];

    videos.forEach(({searchEngine, keyword, resultTitle, resultPreview, videoLikesCount, videoCommentsCount, resultLink}) => {
      data.push([searchEngine, keyword, resultTitle, SupportService.stripHtmlTags(resultPreview), videoLikesCount, videoCommentsCount, resultLink]);
    })

    return data;
  }

}