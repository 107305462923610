export const prepareAddCriteria = (form) => {
  if (form.type === "keyword") {
    return {
      alertType: "KEYWORD",
      keyword: form.keyword.keyword,
      activeForProfiler: form.keyword.module.includes("profiler"),
      activeForWebint: form.keyword.module.includes("massMapper"),
      telegramIntegration: form.keyword.duplicate
        ? {
            apiKey: form.keyword.APIKey,
            chatId: form.keyword.chatId,
          }
        : null,
    };
  }
  if (form.type === "photo") {
    return {
      alertType: "FACE_IMAGE",
      activeForProfiler: form.photo.module.includes("profiler"),
      activeForWebint: form.photo.module.includes("massMapper"),
      faceImageURL: form.photo.files,
      faceImageMatchScore: form.photo.likeness,
      telegramIntegration: form.photo.duplicate
        ? {
            apiKey: form.photo.APIKey,
            chatId: form.photo.chatId,
          }
        : null,
    };
  }
  if (form.type === "auditory") {
    return {
      alertType: "POST_AUDITORY_IN_GROUP",
      auditoryThreshold: form.auditory.threshold,
      activeForProfiler: form.auditory.module.includes("profiler"),
      activeForWebint: form.auditory.module.includes("massMapper"),
      targetID: form.auditory.taskId,
      telegramIntegration: form.auditory.duplicate
        ? {
            apiKey: form.auditory.APIKey,
            chatId: form.auditory.chatId,
          }
        : null,
    };
  }
  if (form.type === "members") {
    return {
      alertType: "GROUP_MEMBERS",
      membersThreshold: form.members.threshold,
      activeForProfiler: form.members.module.includes("profiler"),
      activeForWebint: form.members.module.includes("massMapper"),
      targetID: form.members.taskId,
      telegramIntegration: form.members.duplicate
        ? {
            apiKey: form.members.APIKey,
            chatId: form.members.chatId,
          }
        : null,
    };
  }
  if (form.type === "members") {
    return {
      alertType: "GROUP_MEMBERS",
      membersThreshold: form.members.threshold,
      activeForProfiler: form.members.module.includes("profiler"),
      activeForWebint: form.members.module.includes("massMapper"),
      targetID: form.members.taskId,
      telegramIntegration: form.members.duplicate
        ? {
            apiKey: form.members.APIKey,
            chatId: form.members.chatId,
          }
        : null,
    };
  }
  if (form.type === "user") {
    return {
      alertType: "USER_ACTIVITY_IN_GROUP",
      targetID: form.user.taskId,
      userSocialNetworkType: form.user.sn,
      userSocialNetworkID: form.user.snId,
      telegramIntegration: form.user.duplicate
        ? {
            apiKey: form.user.APIKey,
            chatId: form.user.chatId,
          }
        : null,
    };
  }
  if (form.type === "system") {
    return {
      alertType: "SYSTEM_ALERT",
      telegramIntegration: {
        apiKey: form.system.APIKey,
        chatId: form.system.chatId,
      },
    };
  }
  if (form.type === "posts") {
    return {
      alertType: "GROUP_PUBLICATIONS_BURST",
      targetID: form.posts.taskId,
      //membersThreshold: form.posts.threshold,
      publicationBurstThreshold: form.posts.threshold,
      telegramIntegration: form.posts.duplicate
        ? {
            apiKey: form.posts.APIKey,
            chatId: form.posts.chatId,
          }
        : null,
    };
  }
  if (form.type === "comments") {
    return {
      alertType: "GROUP_COMMENTS_BURST",
      targetID: form.comments.taskId,
      //membersThreshold: form.comments.threshold,
      commentsBurstThreshold: form.comments.threshold,
      telegramIntegration: form.comments.duplicate
        ? {
            apiKey: form.comments.APIKey,
            chatId: form.comments.chatId,
          }
        : null,
    };
  }
};

export const performAlertsFilters = (filters = {}) => {
  const performedFilters = {...filters};
  if (performedFilters?.alertCriteria && Object.keys(performedFilters?.alertCriteria).length === 0) {
    delete performedFilters.alertCriteria;
  }
  console.log('performedFilters', performedFilters);
  return performedFilters;
}

export const transformAlertsList = (alerts = {}) => {
  return {...alerts, content: [...alerts.content.map(alert => {
      if (alert?.content?.indexOf('<i>null</i>')) {
        alert.content = alert.content.replace('<i>null</i>', '<i>image</i>');
      }
      return alert;
    })]
  };
}
