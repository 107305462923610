import moment from "moment";
import i18n from "i18next";

export class ExportXlsAvatarActivities {
  static exportActivities = (activities = []) => {
    const data = [
      [
        i18n.t('ID'),
        i18n.t('Activity'),
        i18n.t('Domain'),
        i18n.t('Date'),
        i18n.t('Time'),
        i18n.t('Login'),
        i18n.t('Post'),
        i18n.t('URL'),
        i18n.t('Status')
      ],
    ];

    activities.forEach(({id, activity, domain, timestamp, login, text, url, status}) => {
      data.push([
        id,
        activity,
        domain,
        moment(timestamp, 'x').format("DD-MM-YYYY"),
        moment(timestamp, 'x').format("HH:ss"),
        login,
        text,
        url,
        status,
      ]);
    })

    return data;
  }

}