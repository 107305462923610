import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {CountryIcon} from "../../Icons";
import {withTranslation} from "react-i18next";

const Phones = ({t, data}) => {
  const { person, personalInfo } = data;
  const {phone} = person;
  const {countryCode, operators = [],} = personalInfo;

  return (
    <View style={{borderBottom: '1px solid #ececec'}}>
      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}}>
        <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
          <Text style={{fontSize: '14px'}}>{t("Phones")}:</Text>
        </View>

        <View>
          <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
            <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px', width: '50%'}}>
              <Text style={{fontSize: '14px'}}>{phone}</Text>
            </View>
            <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px', width: '50%'}}>
              <Text style={{fontSize: '14px'}}>{operators?.join(', ')}</Text>
            </View>
          </View>
          <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
            {
              !!countryCode && <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px', width: '50%'}}>
                <CountryIcon country={countryCode} />
                <Text style={{fontSize: '14px'}}>{countryCode}</Text>
              </View>
            }
            <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px', width: '50%'}}>
              <Text style={{fontSize: '14px'}}>IMSI</Text>
            </View>
          </View>
        </View>
      </View>
    </View>

  );
};

export default withTranslation()(Phones);