import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {ROLES} from "../config/routes";
import AuthAction from "../store/auth/authAction";
import {getIsAuth} from "../store/auth/authSelector";

const PrivateRoute = memo(
  ({ component: Component, layout: Layout, needRole = ROLES.user, ...rest }) => {
    const dispatch = useDispatch();
    //const userData = useSelector(getUser);
    const isAuth = useSelector(getIsAuth);

    useEffect(() => {
      //console.log('isAuth', isAuth);
      if (!isAuth) {
        //console.log('dispatch logout')
        dispatch(AuthAction.logout())
      }
    }, [isAuth, dispatch]);

    return isAuth && (
      <Route
        {...rest}
        render={(props) =>
          isAuth ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
);

export default PrivateRoute;
