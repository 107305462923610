import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import moment from "moment";
import {withTranslation} from "react-i18next";

const Groups = withTranslation()(({t, data}) => {
  const { activityData, from, to } = data;

  return (
    <View style={{borderBottom: '1px solid #ececec'}}>
      <Text style={{marginBottom: '10px', fontFamily: 'pdfFont', fontSize: '18px'}}>{t("Groups activity")}</Text>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont', fontSize: '10px'}}>{moment(from, 'x').format('DD-MM-YYYY')} - {moment(to, 'x').format('DD-MM-YYYY')}</Text>
      <View style={{}} >
        {
          activityData.map((activity, idx) => <View key={idx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px', width: '50%'}}>
            <View style={{width: '200px'}}>
              <Text style={{fontSize: '12px', marginRight: '30px'}}>{activity.day}</Text>
            </View>
            <View style={{width: '200px'}}>
              <Text style={{fontSize: '12px', color: '#727272', fontFamily: 'pdfFont' }}>{activity.type}</Text>
            </View>
            <View style={{width: '200px'}}>
              <Text style={{fontSize: '12px', color: '#727272', fontFamily: 'pdfFont' }}>{activity.value}</Text>
            </View>
          </View>)
        }
      </View>
    </View>
  );
});

export default Groups;