import * as XLSX from "xlsx"
import * as FileSaver from "file-saver"
import moment from "moment";
import {ExportXlsProfiler} from "./ExportXlsProfiler";
import {ExportXlsWebint} from "./ExportXlsWebint";
import {ExportXlsAudit} from "./ExportXlsAudit";
import {ExportXlsAvatarActivities} from "./ExportXlsAvatarActivities";
import i18n from "i18next";
import {ExportXlsAlerts} from "./ExportXlsAlerts";
import {ExportXlsProfilerCase} from "./ExportXlsProfilerCase";
import config from "../../../config/config";

class ExportXls {
  constructor(data) {
    this.data = [];
    if (Array.isArray(data)) {
      this.rawData = [...data];
    } else {
      this.rawData = {...data};
    }
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.workbook = {
      Sheets: {},
      SheetNames: []
    }
  }

  createWorkSheet(wsData, wsName) {
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    this.workbook = {
      Sheets: { ...this.workbook.Sheets, [wsName]: ws },
      SheetNames: [ ...this.workbook.SheetNames, wsName] };
  }

  prepareProfiler() {
    const {person} = this.rawData;
    this.fileName = "PROFILER_" + person?.id + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");

    this.createWorkSheet(ExportXlsProfiler.exportPerson(this.rawData), i18n.t('Person'));
    this.createWorkSheet(ExportXlsProfiler.exportFoundNames(this.rawData), i18n.t('Found names'));
    this.createWorkSheet(ExportXlsProfiler.exportSocialNetworks(this.rawData), i18n.t('Social profiles'));
    this.createWorkSheet(ExportXlsProfiler.exportLocations(this.rawData), i18n.t('Locations'));
    this.createWorkSheet(ExportXlsProfiler.exportEmails(this.rawData), i18n.t('Emails'));
    this.createWorkSheet(ExportXlsProfiler.exportFriends(this.rawData), i18n.t('Friends'));
    this.createWorkSheet(ExportXlsProfiler.exportImages(this.rawData), i18n.t('Images'));
    this.createWorkSheet(ExportXlsProfiler.exportImagResults(this.rawData), i18n.t('Image Search Results'));
    this.createWorkSheet(ExportXlsProfiler.exportWebSearch(this.rawData), i18n.t('Web results'));
    this.createWorkSheet(ExportXlsProfiler.exportPosts(this.rawData), i18n.t('Published posts'));
    this.createWorkSheet(ExportXlsProfiler.exportPreferences(this.rawData), i18n.t('Preferences'));
    this.createWorkSheet(ExportXlsProfiler.exportContactInfo(this.rawData), i18n.t('Contact info'));
    this.createWorkSheet(ExportXlsProfiler.exportDeepWeb(this.rawData), i18n.t('Deep Web'));
    if (config.profilerPsychoProfile) {
      this.createWorkSheet(ExportXlsProfiler.exportPsychoProfiles(this.rawData), i18n.t('Psycho Profile'));
    }

  }

  prepareProfilerCase(caseId = '') {
    this.fileName = `PROFILER_CASE_${caseId}_${moment().format("YYYY_MM_DD_HH_mm_ss")}`;

    this.createWorkSheet(ExportXlsProfilerCase.exportFoundNames(this.rawData), i18n.t('Found names'));
    this.createWorkSheet(ExportXlsProfilerCase.exportSocialNetworks(this.rawData), i18n.t('Social profiles'));
    this.createWorkSheet(ExportXlsProfilerCase.exportLocations(this.rawData), i18n.t('Locations'));
    this.createWorkSheet(ExportXlsProfilerCase.exportPhones(this.rawData), i18n.t('Phones'));
    this.createWorkSheet(ExportXlsProfilerCase.exportGenders(this.rawData), i18n.t('Gender'));
    this.createWorkSheet(ExportXlsProfilerCase.exportLastOnline(this.rawData), i18n.t('Last Online'));
    this.createWorkSheet(ExportXlsProfilerCase.exportEmails(this.rawData), i18n.t('Emails'));
    this.createWorkSheet(ExportXlsProfilerCase.exportCarrier(this.rawData), i18n.t('Operator (s)'));
    this.createWorkSheet(ExportXlsProfilerCase.exportDeepWeb(this.rawData), i18n.t('Deep Web'));

  }

  prepareWebint() {
    const {task} = this.rawData;
    this.fileName = "WEBINT_" + task?.id + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");

    this.createWorkSheet(ExportXlsWebint.exportTask(this.rawData), i18n.t('Task'));
    this.createWorkSheet(ExportXlsWebint.exportTargets(this.rawData), i18n.t('Targets'));
    this.createWorkSheet(ExportXlsWebint.exportGroups(this.rawData), i18n.t('Groups'));
    this.createWorkSheet(ExportXlsWebint.exportPosts(this.rawData), i18n.t('Posts'));
    this.createWorkSheet(ExportXlsWebint.exportTopPosts(this.rawData), i18n.t('Top Posts'));
    this.createWorkSheet(ExportXlsWebint.exportImages(this.rawData), i18n.t('Images'));
    this.createWorkSheet(ExportXlsWebint.exportVideos(this.rawData), i18n.t('Videos'));

  }

  prepareAudit() {
    console.log('rawData', this.rawData);
    this.fileName = "AUDIT_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.createWorkSheet(ExportXlsAudit.exportAudit(this.rawData), i18n.t('Audit'));
  }

  prepareAlerts() {
    console.log('rawData', this.rawData);
    this.fileName = "ALERTS_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.createWorkSheet(ExportXlsAlerts.exportAlerts(this.rawData), i18n.t('Alerts'));
  }

  prepareAvatarActivities() {
    console.log('rawData', this.rawData);
    this.fileName = "AVATAR_ACTIVITIES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.createWorkSheet(ExportXlsAvatarActivities.exportActivities(this.rawData), i18n.t('Avatar activities'));
  }

  export() {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    /*const ws = XLSX.utils.aoa_to_sheet(this.data);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };*/

    const wb = this.workbook;

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, this.fileName + fileExtension);

    return this;
  }
}

export default ExportXls