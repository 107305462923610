import ActionUtility from "../ActionUtility";

export default class AuditAction {

  static UPDATE_AUDIT_STATE = "AuditAction.UPDATE_AUDIT_STATE";
  static GET_AUDIT = "AuditAction.GET_AUDIT";
  static EXPORT_AUDIT = "AuditAction.EXPORT_AUDIT";
  static CREATE_AUDIT = "AuditAction.CREATE_AUDIT";

  static GET_MONTHLY_STAT = "AuditAction.GET_MONTHLY_STAT";

  static updateAuditState(action) {
    return ActionUtility.createAction(AuditAction.UPDATE_AUDIT_STATE, action);
  }

  static getAudit(filters = {}, callback) {
    return ActionUtility.createAction(AuditAction.GET_AUDIT, {filters, callback});
  }

  static exportAudit(type, filters = {}, callback) {
    return ActionUtility.createAction(AuditAction.EXPORT_AUDIT, {type, filters, callback});
  }

  static createAudit(data = {}) {
    return ActionUtility.createAction(AuditAction.CREATE_AUDIT, data);
  }

  static getMonthlyStat(filters = {}, callback) {
    return ActionUtility.createAction(AuditAction.GET_MONTHLY_STAT, {filters, callback});
  }

}