import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {withTranslation} from "react-i18next";
import {SupportService} from "../../../../support";

const ContactInfo = withTranslation()(({t, data}) => {
  const { contactInfo } = data;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Contact info")}</Text>

      {
        contactInfo.map(ci => {
          return ci.contactInfo.map(({items, title}, idx) =>
            <View key={idx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px',  maxWidth: '400px'}}>
              <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{title}:</Text>
              </View>

              <View>
                {
                  items.map(({subTitle, type = 'card', item = {}, info}, i) =>
                    <View key={i} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px'}}>
                      {
                        type === 'card' && <>
                          <Text style={{fontSize: '8px', fontWeight: 'bold', marginRight: '10px', fontFamily: 'pdfFont',}}>{subTitle}</Text>
                          <Text style={{fontSize: '8px', fontFamily: 'pdfFont',}}>{info}</Text>
                        </>
                      }
                      {
                        type === 'object' && <View key={'i' + i} style={{display: 'flex', flexFlow: 'column', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginRight: '10px'}}>
                          {
                            Object.keys(item).map((oneKey,itemIdx) => {
                              return (
                                <View key={itemIdx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px'}}>
                                  <Text style={{fontSize: '8px', fontWeight: 'bold', marginRight: '10px', fontFamily: 'pdfFont',}}>{SupportService.getTitle(oneKey)}:</Text>
                                  <Text style={{fontSize: '8px', fontFamily: 'pdfFont'}}>{item[oneKey]}</Text>
                                </View>
                              )
                            })
                          }
                        </View>
                      }
                    </View>
                  )
                }
              </View>
            </View>
          )
        })
      }
    </View>
  );
});

export default ContactInfo;