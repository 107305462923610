import ActionUtility from "../ActionUtility";

export default class PopupAction {

  static SHOW_POPUP = "PopupAction.SHOW_POPUP";
  static CLOSE_POPUP = "PopupAction.CLOSE_POPUP";

  static showPopup(content) {
    return ActionUtility.createAction(PopupAction.SHOW_POPUP, content);
  }

  static closePopup() {
    return ActionUtility.createAction(PopupAction.CLOSE_POPUP);
  }
}