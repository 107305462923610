import caseHeader from '../assets/img/themes/irbisPro/folder_header.png';
import addElement from "../assets/img/themes/irbisPro/add_element.png";
import investigationCover from "../assets/img/case_cover.png";


export const irbisProTheme = {
  main_bg: '#F0F2FA',
  header_bg: '#03173D',
  header_shadow: '#03173D',
  header_menu: '#FFF',
  header_menu_active: '#34ACE1',
  header_user: '#FFFFFF',
  header_user_avatar: '#8A9CAB',
  header_user_avatar_bg: '#FFF',
  footer_color: '#FFF',
  sider_bg: '#163964',
  sider_tree_color: '#FFF',
  sider_tree_selected_color: '#FFF',
  sider_tree_selected_bg: '#305079',
  tree_icon_investigation: 'investigation_white',
  tree_icon_case: 'case_white',
  tree_icon_profiler: 'profiler_white',
  tree_icon_webint: 'webint_white',
  case_header: caseHeader,
  case_bg: '#FFF',
  case_border: 'rgba(255, 255, 255, 0.20)',
  case_title_color: '#0F305A',
  case_subtitle_color: '#3898D3',
  investigation_cover: investigationCover,
  add_element: addElement,
  add_element_title: `
    color: #0F305A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  add_element_container: `
    border-radius: 10px;
    border: 1px dashed #0F305A;
    background: none;
  `,
  target_element_border: '10px',
  grid_switcher_bg: '#163964',
  grid_switcher_border: '1px solid #EFEFEF',
  grid_switcher_highlight: '#FFF',
  grid_switcher_color: '#FFF',
  grid_switcher_inactive_color: '#556B7D',
  grid_widget_bg: '#FFF',
  grid_widget_shadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.22);',
  grid_widget_border: 'none',
  grid_widget_border_radius: '15px',
  grid_widget_margin: [25, 25],
  grid_widget_row_height: 15,
  grid_header_bg: '#FFF',
  grid_header_color: '#03173D',
  grid_header_border: 'none',
  grid_resizer_position: `bottom: 10px; right: 10px;`,
  button_border: '#3898D3',
  button_border_radius: '5px',
  button_bg: '#3898D3',
  button_hover: '#6896B4',
  button_active: '#276A94',
  button_text_bg: '#FFF',
  modal_bg: '#FFF;',
  modal_border_radius: '15px',
  modal_title_color: '#0F305A',
  input_border_radius: '5px',
  input_group_gap: '3px',
  post_line_bg: '#F0F2FA',
  post_line_border_radius: '0',
  tabs_bg: '#8B929D',
  tabs_border_radius: '5px',
  tabs_active_bg: '#34ACE1',
  tabs_active_color: '#F8F9FA',
  login_border: 'none',
  login_bg: '#03173D',
  login_border_radius: '10px',
  login_box_shadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.22)',
  login_input_border: '1px solid #A3C4DF',
  login_input_border_radius: '10px',
}