import moment from "moment";
import i18next from "i18next";

export class ExportXlsAlerts {
  static exportAlerts = (alerts = []) => {
    const data = [
      [
        i18next.t('ID'),
        i18next.t('Module'),
        i18next.t('Target ID'),
        i18next.t('Social Network'),
        i18next.t('Avatar'),
        i18next.t('Date'),
        i18next.t('Content'),
        i18next.t('Type'),
        i18next.t('Criteria'),
      ],
    ];

    alerts.forEach(alert => {
      data.push([
        alert.id,
        alert.module,
        alert?.targetId,
        alert?.socialNetwork,
        alert?.avatar,
        moment(alert.createdTime, 'x').format("DD-MM-YYYY"),
        alert.content,
        alert.alertType,
        alert?.alertCriteria?.keyword || alert.alertCriteria?.faceImageURL || alert.alertCriteria?.auditoryThreshold,
      ]);
    })

    return data;
  }

}