import styled from "styled-components";
import ArrowToTop from "../../../assets/img/arrow_top.png";
import {Layout} from "antd";

const { Footer } = Layout;

export const CustomFooter = styled(Footer)`
  background: ${props => props.theme.header_bg};
  color: ${props => props.theme.footer_color};
  padding: 14px 50px;  
`;

export const Container = styled.div`
  
`;

export const Copyright = styled.div`
  
`;

export const Logo = styled.p`
  
`;

export const ToTopButton = styled.button`
  background: transparent url(${ArrowToTop}) no-repeat center;
  border: 0;
  cursor: pointer;
  height: 14px;
  width: 14px;
  position: fixed;
  bottom: 10px;
  right: 30px;
  z-index: 99;
`;
