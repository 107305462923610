export const prepareProxy = (form) => {
  const reqObject = {
    custom: true,
    country: "",
    domain: "",
    ipAddress: "",
    port: "",
    login: "",
    password: "",
    proxyType: "HTTPS",
  };
  if (form.proxy) {
    if (form.proxy.proxyTypeSelect === "standard") {
      reqObject.country = form.proxy.country;
    }
    if (form.proxy.proxyTypeSelect === "custom") {
      reqObject.domain = form.proxy.domainName;
      reqObject.ipAddress = form.proxy.domainName;
      reqObject.port = form.proxy.port;
      reqObject.login = form.proxy.proxyUser;
      reqObject.password = form.proxy.proxyPass;
    }
  }
  return reqObject;
};
