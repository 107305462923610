import React from "react";

import * as Styled from "./styled";
import "./style.css";

import MainLogo from "./MainLogo";
import MainMenu from "./MainMenu";

import { Layout} from 'antd';
import UserMenu from "./UserMenu";
import MainQuota from "./MainQuota";
import config from "../../../config/config";
const { Header} = Layout;

const MainHeader = () => (
  <Styled.CustomHeader id={'topnav'}>
    <MainLogo />
    <MainMenu />
    {
      config.irbisHeaderQuota && <MainQuota />
    }
    <UserMenu />
  </Styled.CustomHeader>
);

export default MainHeader;