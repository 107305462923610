import React, {memo} from 'react';
import usePermission from "./usePermission";

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted = memo(({to, fallback, loadingComponent, children, ...props}) => {
  // We "connect" to the provider thanks to the PermissionContext
  const [allowed] = usePermission(to);

  /*if (loading) {
    return <>{loadingComponent}</>;
  }*/

  // If the user has that permission, render the children
  if (!to || allowed) {
    return <>{children}</>;
  }

  // Otherwise, render the fallback
  return <>{fallback}</>;
});

export default Restricted;