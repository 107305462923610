import React from 'react';
import {Image, Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {withTranslation} from "react-i18next";

const Videos = ({t, data}) => {
  const {videos = []} = data;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Videos")}</Text>


      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
        {
          videos.map(({thumbnailLink, resultTitle, resultLink, resultPreview, searchEngine}) =>
            <View key={thumbnailLink} style={{width: '350px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
              <Image style={{width: '150px', height: '150px', marginRight: '10px', objectFit: 'contain'}} src={thumbnailLink}/>
              <View>
                <Link src={resultLink} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <SocialIcon network={searchEngine} />
                  <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{resultTitle}</Text>
                </Link>
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{resultPreview}</Text>
              </View>
            </View>
          )
        }
      </View>

      <Text break />
    </View>
  );
};

export default withTranslation()(Videos);