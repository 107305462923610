import React, {memo, Suspense, useEffect, useCallback, useContext, useState} from "react";
import {Switch, useLocation, useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {ROLES, Routes} from "../config/routes";
import AppRoute from "./AppRoute";
import Loading from "../components/UI/Loading";
import MainLayout from "../layouts/MainLayout";
import {getChosenLang, getLastLocation} from "../store/settings/settingsSelector";
import PrivateRoute from "./PrivateRoute";
import SettingsAction from "../store/settings/settingsAction";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import InvestigationAction from "../store/investigation/investigationAction";
import PermissionContext from "../components/Permissions/PermissionContext";
import {storageSupported} from "../helpers/storageSupported";
import {ConfigProvider} from "antd";
import IncognitoWarning from "../components/Containers/IncognitoWarning";
import config from "../config/config";

import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/uz';
import 'moment/locale/uz-latn';

function Waiting(Component) {
  return (props) => (
    <Suspense fallback={<Loading style={{height: '90.2vh'}} />}>
      <Component {...props} />
    </Suspense>
  );
}

export default memo(() => {
  const lastLocation = useSelector(getLastLocation);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const setActiveRoute = useCallback(
    (route) => dispatch(SettingsAction.setActiveRoute(route)),
    [dispatch]
  );
  const chosenLang = useSelector(getChosenLang);
  const [locale, setLocale] = useState()
  /*const getCredentials = useCallback(
    (cb) => dispatch(AuthAction.getCredentials(cb)),
    [dispatch]
  );*/
  const {isAllowedTo} = useContext(PermissionContext);

  useEffect(() => {
    if (storageSupported()) {
      if (location.pathname && location.pathname.indexOf('login') === -1 && location.pathname && location.pathname.indexOf('logout') === -1) {
        sessionStorage.setItem('redirect', location.pathname);
        /*getCredentials((data) => {
          //console.log("get creds", data)
        });*/
      }
    }
    return () => null;
  });

  useEffect(() => {
    if (storageSupported()) {
      if (location.pathname && location.pathname.indexOf('login') === -1 && location.pathname.indexOf('logout') === -1) {
        sessionStorage.setItem('redirect', location.pathname);
      }
    }
    setActiveRoute(location.pathname);

    //@TODO: nobody knows why this code is required for investigation tree building
    if (location?.pathname?.indexOf('/investigations') >= 0) {
      dispatch(InvestigationAction.clearHierarchy());
    }
  // eslint-disable-next-line
  },[location.pathname, setActiveRoute]);

  useEffect(
    () => {
      //console.log('location', lastLocation);
      if (lastLocation) {
        history.push(lastLocation);
      }
    },[lastLocation, history]
  );

  useEffect(
    () => {
      if (chosenLang) {
        const currentLocale = config.languages.find(ln => ln.code === chosenLang)?.locale
        setLocale(currentLocale);
        moment.locale(chosenLang);
        //console.log('CH', chosenLang, config.languages.find(ln => ln.code === chosenLang)?.locale);
      }
      // eslint-disable-next-line
    },[chosenLang, config]
  );

  return (
    <>
      {
        !storageSupported()
          ? <IncognitoWarning />
          : <TransitionGroup component={null}>
            <CSSTransition
              key={location.pathname}
              classNames="page"
              timeout={100}
            >
              <ConfigProvider locale={locale}>
                <Switch location={location}>
                  {
                    Routes
                      .filter(({permission}) => {
                        if (permission && !isAllowedTo(permission)) {
                          return false
                        }
                        return true
                      })
                      .map(
                        ({
                           location,
                           needRole,
                           component,
                           layout = MainLayout
                         },
                         key
                        ) => {
                          switch (needRole) {
                            case ROLES.admin:
                            case ROLES.user: return (
                              <PrivateRoute
                                key={key}
                                exact
                                path={location.pathname}
                                component={Waiting(component)}
                                layout={layout}
                                needRole={needRole}
                              />
                            )
                            default: return (
                              <AppRoute
                                key={key}
                                exact
                                path={location.pathname}
                                component={Waiting(component)}
                                layout={layout}
                              />
                            )
                          }
                        }
                      )
                  }
                  {/*<AppRoute path="*" component={Waiting(NotFoundPage)} layout={MainLayout} />*/}
                </Switch>
              </ConfigProvider>
            </CSSTransition>
          </TransitionGroup>
      }

    </>
  );
});
