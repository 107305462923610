import { put, takeEvery } from "redux-saga/effects";
import {apiAvatarsService} from "../../config/api";
import AvatarsAction from "./avatarsAction";
import {prepareAvailableAvatars} from "./avatarsTransformer";
import SettingsAction from "../settings/settingsAction";


function* getAvailableAvatarsSaga(action) {
  const {socialNetwork = '', module = '', callback} = action.payload;
  try {
    const {data} = yield apiAvatarsService.getAvailableAvatars(socialNetwork || '', module || '', null);
    const available = prepareAvailableAvatars(data);
    console.log('getAvailableAvatarsSaga', available);
    callback(available);
  } catch (e) {
    callback({});
    yield put(SettingsAction.handleAPIError(e));
  }
}

export function* watchAvatars() {
  yield takeEvery(AvatarsAction.GET_AVAILABLE_AVATARS, getAvailableAvatarsSaga);
}