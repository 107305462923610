import React from 'react';
import {Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon, Icon} from "../../Icons";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {ImageRender} from "../../Images";

const Posts = ({t, data}) => {
  const {posts = []} = data;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Published posts")}</Text>
      {
        posts.map((post, idx) =>
          <View key={idx} style={{paddingBottom: '20px'}}>
            <View>
              <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px'}}>
                {
                  post.socialNetwork && <SocialIcon network={post.socialNetwork} />
                }
                <Text style={{fontSize: '10px', color: '#b1b1b1', fontFamily: 'pdfFont',}}>{moment(post.timeStamp).format('YYYY-MM-DD HH:mm')}</Text>
              </View>
              <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{post.text}</Text>
              {
                !!post.sharedText && <Text style={{fontSize: '12px', paddingLeft: '20px', marginTop: '10px', borderLeft: '4px solid #e9ecef', fontFamily: 'pdfFont',}}>{post.sharedText}</Text>
              }
            </View>
            {
              post.imagesUrl?.length > 0 && <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
                {
                  post.imagesUrl.map(img =>
                    <View key={img} style={{width: '100px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '3px', marginBottom: '3px'}}>
                      {ImageRender(img, {width: '100px', height: '100px', marginRight: '3px', objectFit: 'cover'})}
                    </View>
                  )
                }
              </View>
            }
            {
              post.externalLinkUrl?.length > 0 && <View style={{display: 'flex', flexFlow: 'column', flexDirection: 'column', flexWrap: 'wrap'}}>
                {
                  post.externalLinkUrl.map((url, idx) =>
                    <View key={idx} style={{fontSize: '10px', color: '#b1b1b1', fontFamily: 'pdfFont', display: 'flex', width: '400px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                      <Link src={url}>{url}</Link>
                    </View>
                  )
                }
              </View>
            }
            {
              post.videosUrl?.length > 0 && <View style={{display: 'flex', flexFlow: 'column', flexDirection: 'column', flexWrap: 'wrap'}}>
                {
                  post.videosUrl.map((video, idx) =>
                    <View key={idx} style={{fontSize: '10px', color: '#b1b1b1', fontFamily: 'pdfFont', display: 'flex', width: '400px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                      <Link src={video}>{video}</Link>
                    </View>
                  )
                }
              </View>
            }
            <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '10px'}}>
              <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
                <Icon type={'post_preview_like'} />
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont', color: '#98A3B9'}}>{post.likeCount}</Text>
              </View>
              <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
                <Icon type={'post_preview_comment'} />
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont', color: '#98A3B9'}}>{post.commentsCount}</Text>
              </View>
              <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
                <Icon type={'post_preview_auditory'} />
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont', color: '#98A3B9'}}>{post.totalReactionsCount}</Text>
              </View>
            </View>
          </View>
        )
      }
    </View>
  );
};

export default withTranslation()(Posts);