import { put, takeEvery } from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import AuditAction from "./auditAction";
import {apiAuditService} from "../../config/api";
import ExportFactory from "../../services/Export/ExportFactory";


function* getAuditSaga(action) {
  const {filters = {}, callback} = action.payload;
  try {
    const {data} = yield apiAuditService.searchAudits(filters);
    yield put(AuditAction.updateAuditState({audit: data}));
    callback(data);
  } catch (e) {
    callback([]);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportAuditSaga(action) {
  const {type, filters = {}, callback} = action.payload;
  try {
    const {data} = yield apiAuditService.exportAudits(type, {...filters, page: 0, size: 2000});
    const {content = []} = data;

    const exportInstance = yield ExportFactory.getExportHandler(type, content);
    yield exportInstance.prepareAudit();
    yield exportInstance.export();

    callback(data);
  } catch (e) {
    callback([]);
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* createAuditSaga(action) {
  const auditData = action.payload;
  try {
    yield apiAuditService.createAudit(auditData);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getMonthlyStatSaga(action) {
  const {filters = {}, callback} = action.payload;
  try {
    const {data} = yield apiAuditService.getGlobalStatistic(filters);
    yield put(AuditAction.updateAuditState({monthlyStat: data}));
    callback(data);
  } catch (e) {
    callback([]);
    yield put(SettingsAction.handleAPIError(e));
  }
}

export function* watchAudit() {
  yield takeEvery(AuditAction.GET_AUDIT, getAuditSaga);
  yield takeEvery(AuditAction.EXPORT_AUDIT, exportAuditSaga);
  yield takeEvery(AuditAction.CREATE_AUDIT, createAuditSaga);
  yield takeEvery(AuditAction.GET_MONTHLY_STAT, getMonthlyStatSaga);
}