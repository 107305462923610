import {
  Document,
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
  ExternalHyperlink
} from "docx";
import i18n from "i18next";
import moment from "moment";
import {SupportService} from "../../../support";
import DocxService from "../DocxService";
import config from "../../../../config/config";

class ExportDocxProfiler {
  static dimensions = {};

  static renderTitlePage = ({person}) => {
    const {photo, id, name, phone} = person;

    let mainImage = new Paragraph({
      text: "\n\n\n\n\n\n\n\n",
      heading: HeadingLevel.HEADING_1,
      alignment: AlignmentType.CENTER,
    });

    if (photo) {
      mainImage = new Paragraph({
        children: [
          DocxService.createImage(photo)
        ],
        alignment: AlignmentType.CENTER,
      });
    }

    return {
      ...DocxService.createFooter(),
      children: [
        mainImage,
        new Paragraph({
          text: (name || '') + "\n\n\n\n\n\n\n\n",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: (phone || id) + "\n\n\n\n\n\n\n\n",
          heading: HeadingLevel.HEADING_3,
          alignment: AlignmentType.CENTER,
        }),
      ],
    };
  }

  static renderPersonalInfo = ({personalInfo, person}) => {
    //names
    const namesContent = [];
    personalInfo.names.forEach(({network, name}) => {
      namesContent.push(DocxService.createColumns(
          [
            {text: network, bold: true},
            {text: name},
          ], 2000
        )
      );
    })

    //phones
    const phonesContent = [];
    personalInfo.phones.forEach((phone) => {
      phonesContent.push(DocxService.createColumns(
          [
            {text: personalInfo?.countryCode, bold: true}, {text: phone?.data, }
          ], 2000
        )
      );
    })


    //operators
    const operators = [];
    personalInfo.operators.forEach(operator => {
      operators.push(
        new Paragraph({
          text: operator,
          bold: true,
        })
      );
    });

    //genders
    const gendersContent = [];
    personalInfo.genders.forEach(({source, gender}) => {
      gendersContent.push(DocxService.createColumns(
          [
            {text: source, bold: true},
            {text: gender},
          ], 2000
        )
      );
    })

    //addresses
    const addressContent = [];
    personalInfo.address.forEach(({address, sn}) => {
      addressContent.push(DocxService.createColumns(
          [
            {text: sn, bold: true},
            {text: address},
          ], 2000
        )
      );
    })

    //emails
    const emailContent = [];
    personalInfo.emails.forEach(({source, data}) => {
      emailContent.push(DocxService.createColumns(
          [
            {text: source, bold: true},
            {text: data},
          ], 2000
        )
      );
    })

    //lastOnline
    const lastOnline = [];
    personalInfo.lastOnline.forEach(({source, status}) => {
      lastOnline.push(DocxService.createColumns(
          [
            {text: source, bold: true},
            {text: status},
          ], 2000
        )
      );
    })

    //statuses
    const statuses = [];
    personalInfo.statuses.forEach(({source, status}) => {
      statuses.push(DocxService.createColumns(
          [
            {text: source, bold: true},
            {text: status},
          ], 2000
        )
      );
    })

    //profiles
    const profiles = [];
    personalInfo.networks.forEach(({sid, profile}) => {
      profiles.push(new Paragraph({
        text: `${sid}:`,
        //bold: true,
        heading: HeadingLevel.HEADING_5,
        //bullet: { level: 0, },
      }));
      profiles.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: profile,
                  style: "Hyperlink",
                }),
              ],
              link: SupportService.getSocialProfileUrl(sid, profile),
            }),
          ]
        })
      );
      profiles.push(new Paragraph({
        text: '',
        heading: HeadingLevel.HEADING_1,
        thematicBreak: true,
      }));
    });


    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Found Name (s)')),
        ...namesContent,

        DocxService.createHeading(i18n.t('Phones')),
        ...phonesContent,

        DocxService.createHeading(i18n.t('Networks')),
        ...profiles,

        DocxService.createHeading(i18n.t('Operators')),
        ...operators,

        DocxService.createHeading(i18n.t('Gender')),
        ...gendersContent,

        DocxService.createHeading(i18n.t('Address')),
        ...addressContent,

        DocxService.createHeading(i18n.t('Emails')),
        ...emailContent,

        DocxService.createHeading(i18n.t('IMSI')),
        new Paragraph({
          text: config?.profilerShowIMSI && personalInfo.IMSI,
        }),

        DocxService.createHeading(i18n.t('Last Online')),
        ...lastOnline,

        DocxService.createHeading(i18n.t('Status')),
        ...statuses,

        DocxService.createEmptyRow(),

        DocxService.createHeading(i18n.t('Social')),
        new Paragraph({
          text: `${i18n.t("likes")}: ${person.commonStatistics.likes || 0}`,
          bullet: { level: 0, },
        }),
        new Paragraph({
          text: `${i18n.t("friends")}: ${person.commonStatistics.friends || 0}`,
          bullet: { level: 0, },
        }),
        new Paragraph({
          text: `${i18n.t("mentions")}: ${person.commonStatistics.mentions || 0}`,
          bullet: { level: 0, },
        }),
        new Paragraph({
          text: `${i18n.t("posts")}: ${person.commonStatistics.posts || 0}`,
          bullet: { level: 0, },
        }),
      ],
    };
  }

  static renderContactInfo = ({contactInfo = []}) => {
    const contactRows = [];

    contactInfo.forEach(({sid, contactInfo: contactinfo = []}) => {
      contactinfo.forEach(({title, items}) => {
        const contactLines = [];

        items.forEach(({subTitle, info, type = 'card', item = {}}) => {
          if (type === 'card') {
            contactLines.push(DocxService.createColumns([
              {text: subTitle},
              {text: info,}
            ], 3000));
          }
          if (type === 'object') {
            Object.keys(item).forEach((oneKey,i)=>{
              contactLines.push(DocxService.createColumns([
                {text: SupportService.getTitle(oneKey)},
                {text: item[oneKey],}
              ], 3000));
            })
          }
        })

        contactRows.push(
          new Paragraph({
            children: [
              new TextRun({text: title, bold: true}),
            ]
          }),
          ...contactLines,
        );

      })
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Contact Info')),
        ...contactRows,
      ],
    };
  }

  static renderFriends = ({friends = []}) => {
    const friendRows = [];

    friends.forEach(({socialNetwork, avatar, name, friendProfileId}) => {
      try {
        const tAvatar = SupportService.getImageForRender(avatar, socialNetwork);
        friendRows.push(new Paragraph({
          children: [
            DocxService.createImage(tAvatar, {width: 50, height: 50}, false)
          ]
        }));
      } catch (e) {
        console.log('eee', e);
      }

      friendRows.push(DocxService.createColumns(
          [
            {text: name, bold: true},
            {text: socialNetwork},
          ], 2000
        )
      );
      friendRows.push(new Paragraph({
        children: [
          new ExternalHyperlink({
            children: [
              new TextRun({
                text: friendProfileId,
                style: "Hyperlink",
              }),
            ],
            link: SupportService.getSocialProfileUrl(socialNetwork, friendProfileId),
          }),
          DocxService.createEmptyRow(),
        ]
      }))
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Friends')),
        ...friendRows
      ]
    };
  };

  static renderPhotos = ({images = []}) => {
    const imageRows = [];

    images.forEach(({source, imageId}) => {
      imageRows.push(DocxService.createImage(imageId, {width: 270, height: 270}))
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Photos')),
        new Paragraph({
          children: imageRows,
        }),
      ],
    }
  };

  static renderPosts = ({posts = []}) => {
    const postsContent = [];

    posts.forEach(post => {
      let postText = post.text;
      if (post.isSharedPost) {
        postText = `${i18n.t("Shared post")}: ${post.sharedText}`
      }
      postsContent.push(new Paragraph({
        text: moment(post.timeStamp).format('YYYY-MM-DD HH:mm:ss'),
        italics: true,
      }));
      postsContent.push(new Paragraph({
        text: SupportService.stripHtmlTags(postText)
      }));
      if (post?.externalLinkUrl?.length) {
        postsContent.push(
          new Paragraph({
            children: [
              new ExternalHyperlink({
                children: [
                  new TextRun({
                    text: `${i18n.t("Shared link")}: ${post?.externalLinkUrl?.[0]}`,
                    style: "Hyperlink",
                  }),
                ],
                link: post?.externalLinkUrl?.[0],
              }),
            ]
          })
        );
      }
      postsContent.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: post.postUrl,
                  style: "Hyperlink",
                }),
              ],
              link: post.postUrl,
            }),
          ]
        })
      );
      postsContent.push(DocxService.createEmptyRow());
      postsContent.push(DocxService.createEmptyRow());
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Published Posts')),
        ...postsContent,
      ],
    };
  };

  static renderSearchResults = ({searchProgress}) => {
    const {searchResults = []} = searchProgress;

    const tResults = [];
    searchResults.forEach(({avatar, socialNetwork, profileId, profileName}) => {
      tResults.push(new Paragraph({
        children: [DocxService.createImage(avatar, {width: 50, height: 50}, false)]
      }));
      tResults.push(new Paragraph({
        text: profileName,
        heading: HeadingLevel.HEADING_5,
      }));
      tResults.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: profileId,
                  style: "Hyperlink",
                }),
              ],
              link: SupportService.getSocialProfileUrl(socialNetwork, profileId),
            }),
          ]
        })
      );
      tResults.push(new Paragraph({
        text: '',
        heading: HeadingLevel.HEADING_1,
        thematicBreak: true,
      }));
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Search Results')),
        ...tResults,
      ],
    }
  };

  static renderImageSearchResults = ({imageSearchResults}) => {
    const {imageResults = []} = imageSearchResults;

    const tResults = [];
    imageResults.forEach(({avatar, profile, profileUrl, profileId, name: profileName, confidence}) => {
      tResults.push(new Paragraph({
        children: [DocxService.createImage(avatar, {width: 50, height: 50}, false)]
      }));
      tResults.push(new Paragraph({
        text: profileName,
        heading: HeadingLevel.HEADING_5,
      }));
      tResults.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: profile,
                  style: "Hyperlink",
                }),
              ],
              link: profile ? profile : `${window?.location?.origin}${profileUrl}`,
            }),
          ]
        })
      );
      tResults.push(new Paragraph({
        text: `${SupportService.getRoundNumber(confidence)}%`,
        heading: HeadingLevel.HEADING_6,
      }));
      tResults.push(new Paragraph({
        text: '',
        heading: HeadingLevel.HEADING_1,
        thematicBreak: true,
      }));
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Image Search Results')),
        ...tResults,
      ],
    }
  };

  static renderWebResults = ({webResults = []}) => {
    const webContent = [];

    webResults.forEach(({resultTitle, resultPreview, searchEngine, resultLink}) => {
      webContent.push(new Paragraph({
        text: resultTitle,
        bold: true,
        heading: HeadingLevel.HEADING_3,
      }));
      webContent.push(DocxService.createEmptyRow());
      webContent.push(new Paragraph({
        text: SupportService.stripHtmlTags(resultPreview)
      }));
      webContent.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: resultLink,
                  style: "Hyperlink",
                }),
              ],
              link: resultLink,
            }),
          ]
        })
      );
      webContent.push(DocxService.createEmptyRow());
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Web Results')),
        ...webContent,
      ],
    }
  };

  static renderDeepWeb = ({deepWeb = []}) => {
    const deepWebContent = [];

    deepWeb?.hydraNewData?.forEach(({display}) => {
      const deepWebLines = [];

      Object.entries(display)?.forEach(([key, value]) => {
        deepWebLines.push(DocxService.createColumns([
          {text: SupportService.getTitle(key)},
          {text: Array.isArray(value) ? JSON.stringify(value) : value,}
        ], 3000));
      })

      deepWebContent.push(
        ...deepWebLines,
        DocxService.createEmptyRow(),
      );
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Deep Web')),
        ...deepWebContent,
      ],
    }
  }

  static renderLocations = ({locations = []}) => {
    const locationsContent = [];

    locations?.forEach(({country, city}) => {
      const locationsLines = [];

        locationsLines.push(DocxService.createColumns([
          {text: country},
          {text: city,}
        ], 3000));

      locationsContent.push(
        ...locationsLines,
        DocxService.createEmptyRow(),
      );
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Locations')),
        ...locationsContent,
      ],
    }
  }

  static renderPsychoProfile = ({psychoProfile}) => {

    if (!config.profilerPsychoProfile) {
      return {
        children: [],
      };
    }

    let psychoItems = [];
    let summaryItems = [];

    if (psychoProfile) {
      const { summary = {}, psycho = {} } = psychoProfile;
      summaryItems = summary?.result?.summaries || [];
      psychoItems = psycho?.result?.profiles || [];
    }

    const psychoContent = [];
    const summaryContent = [];

    psychoItems?.forEach(({personName, psychologicalPortrait, levelOfDanger, predictedCharacteristics}, idx) => {
      psychoContent.push(new Paragraph({text: personName, bold: true, heading: HeadingLevel.HEADING_3,}));
      psychoContent.push(new Paragraph({text: psychologicalPortrait,}));
      psychoContent.push(new Paragraph({text: predictedCharacteristics.join(', '),}));

      const psychoLines = [];
      psychoLines.push(DocxService.createColumns([
        {text: i18n.t("Level of Danger"),},
        {text: levelOfDanger,}
      ], 3000));

      psychoContent.push(...psychoLines, DocxService.createEmptyRow());

    });

    summaryItems?.forEach(({summary, country, age, occupation, gender}, idx) => {
      summaryContent.push(new Paragraph({text: summary, bold: true, heading: HeadingLevel.HEADING_3,}));
      const summaryLines = [];
      summaryLines.push(
        DocxService.createColumns([{text: i18n.t("Country")}, {text: country}], 3000),
        DocxService.createColumns([{text: i18n.t("Age")}, {text: age}], 3000),
        DocxService.createColumns([{text: i18n.t("Occupation")}, {text: occupation}], 3000),
        DocxService.createColumns([{text: i18n.t("Gender")}, {text: gender}], 3000),
      );

      summaryContent.push(...summaryLines, DocxService.createEmptyRow());
    });

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Psycho Profile')),
        ...psychoContent,
        DocxService.createHeading(i18n.t('Psycho Summary')),
        ...summaryContent,
      ],
    }
  }

  static renderSynopsis = ({synopsis = ''}) => {
    const sContent = [];
    sContent.push(new Paragraph({
      text: SupportService.stripHtmlTags(synopsis)
    }));

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Synopsis')),
        ...sContent,
      ],
    }
  };


  static renderDoc(data) {
    const {sections = []} = data;
    const docSections = [
      this.renderTitlePage(data),
    ];

    if (sections.includes('personal')) {
      docSections.push(this.renderPersonalInfo(data));
    }
    if (sections.includes('contact')) {
      docSections.push(this.renderContactInfo(data));
    }
    if (sections.includes('friends')) {
      docSections.push(this.renderFriends(data));
    }
    if (sections.includes('photos')) {
      docSections.push(this.renderPhotos(data));
    }
    if (sections.includes('posts')) {
      docSections.push(this.renderPosts(data));
    }
    if (sections.includes('search_results')) {
      docSections.push(this.renderSearchResults(data));
      docSections.push(this.renderImageSearchResults(data));
    }
    if (sections.includes('web_results')) {
      docSections.push(this.renderWebResults(data));
    }
    if (sections.includes('deep_web')) {
      docSections.push(this.renderDeepWeb(data));
    }
    if (sections.includes('psycho')) {
      docSections.push(this.renderPsychoProfile(data));
    }
    if (sections.includes('locations')) {
      docSections.push(this.renderLocations(data));
    }
    if (sections.includes('synopsis')) {
      docSections.push(this.renderSynopsis(data));
    }

    return new Document({
      sections: docSections,
    });

  }
}

export default ExportDocxProfiler;