import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {SupportService} from "../../../../support";
import {withTranslation} from "react-i18next";

const Summary = ({t, data}) => {
  const { person } = data;
  const {commonStatistics} = person;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Social")}</Text>

      <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', border: '1px solid #ececec', padding: '20px'}}>
        <View style={{width: '25%'}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{SupportService.getStatNum(commonStatistics.friends || 0)} {t("friends")}</Text>
        </View>
        <View style={{width: '25%'}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{SupportService.getStatNum(commonStatistics.likes || 0)} {t("likes")}</Text>
        </View>
        <View style={{width: '25%'}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{SupportService.getStatNum(commonStatistics.mentions || 0)} {t("mentions")}</Text>
        </View>
        <View style={{width: '25%'}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{SupportService.getStatNum(commonStatistics.posts || 0)} {t("posts")}</Text>
        </View>
      </View>
    </View>
  );
};

export default withTranslation()(Summary);