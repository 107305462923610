import XRegExp from "xregexp";
import moment from "moment";
import i18n from "i18next";
import md5 from "md5";
import config from "../config/config";

export class SupportService {
  static isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static isString(str) {
    return typeof str === "string";
  }

  static isBase64(url) {
    if (
      url.indexOf("http:") === -1 &&
      url.indexOf("https:") === -1 &&
      url.indexOf("/app/") !== 0 &&
      url.indexOf("img") !== 0
    ) {
      return true;
    }
    return false;
  }

  static isHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  static objectSize(obj) {
    if (obj) {
      return Object.keys(obj).length;
    }
  }

  static getUrl(url) {
    if (url) {
      if (url.indexOf("http") === 0) {
        return url;
      }
      if (url.indexOf("//") === 0) {
        return url;
      }
      return "https://" + url;
    }
  }

  static getTargetUrl(targetId, module = 'PROFILER') {
    return `/${module.toLowerCase()}/${targetId}`;
  }

  static socialNetworks = [
    {
      name: "Facebook",
      sid: "FB",
      id: "facebook",
      domain: "facebook.com",
      tokens: [],
    },
    {
      name: "Facebook",
      sid: "FACEBOOK",
      id: "facebook",
      domain: "facebook.com",
      hidden: true,
      tokens: [],
    },
    {
      name: "Linkedin",
      sid: "LINKEDIN",
      id: "linkedin",
      domain: "linkedin.com",
      tokens: [],
    },
    {
      name: "Twitter",
      sid: "TWITTER",
      id: "twitter",
      domain: "twitter.com",
      tokens: [
        { name: "apiKey", label: i18n.t("API key") },
        { name: "apiSecret", label: i18n.t("API secret") },
        { name: "apiAccessToken", label: i18n.t("API access token") },
        {
          name: "apiAccessTokenSecret",
          label: i18n.t("API secret access token"),
        },
      ],
    },
    {
      name: "Telegram",
      sid: "TELEGRAM",
      id: "telegram",
      domain: "te.me",
      tokens: [],
    },
    {
      name: "Instagram",
      sid: "INSTAGRAM",
      id: "instagram",
      domain: "instagram.com",
      tokens: [],
    },
    {
      name: "Vkontakte",
      sid: "VK",
      id: "vkontakte",
      domain: "vk.com",
      tokens: [
        { name: "apiAccessToken", label: i18n.t("API access token") },
      ],
    },
    {
      name: "Odnoklassniki",
      sid: "OK",
      id: "odnoklassniki",
      domain: "ok.ru",
      tokens: [
        { name: "apiAppId", label: i18n.t("API app id") },
        { name: "apiKey", label: i18n.t("API key") },
        { name: "apiSecret", label: i18n.t("API secret") },
      ],
    },
    {
      name: "My.Mail.ru",
      sid: "MY_MAIL_RU",
      id: "my_mail_ru",
      domain: "my.mail.ru",
      tokens: [
        { name: "apiAppId", label: i18n.t("API app id") },
        { name: "apiSecret", label: i18n.t("API secret") },
        { name: "apiRefreshToken", label: i18n.t("API refresh token") },
      ],
    },
    {
      name: "Tiktok",
      sid: "TIKTOK",
      id: "tiktok",
      domain: "tiktok.com",
      tokens: [],
      //hidden: true,
    },
  ];
  static activeSocialNetworks = this.socialNetworks.filter((sn) => !sn.hidden);
  static activeProfilerSocialNetworks = this.socialNetworks.filter((sn) => {
    if (sn.id === 'telegram') {
      return !sn.hidden && config.profilerUseHydra
    }
    return !sn.hidden
  } /*&& sn.id !== 'telegram'*/);
  static activeSocialNetworksForAmm = this.activeSocialNetworks.filter((sn) => {
    return sn.name !== "Tiktok" && sn.name !== "Telegram";
  });
  static activeWebintGroupSocialNetworks = [
    ...this.socialNetworks.filter((sn) => !sn.hidden).filter(sn => sn.id !== 'tiktok'),
    {
      name: "Youtube",
      sid: "YOUTUBE",
      id: "youtube",
      domain: "youtube.com",
      tokens: [],
    },
  ];
  static activeWebintPostSocialNetworks = [
    {
      name: "Facebook",
      sid: "FB",
      id: "facebook",
      domain: "facebook.com",
      tokens: [],
    },
    {
      name: "Instagram",
      sid: "INSTAGRAM",
      id: "instagram",
      domain: "instagram.com",
      tokens: [],
    },
    {
      name: "Twitter",
      sid: "TWITTER",
      id: "twitter",
      domain: "twitter.com",
      tokens: [
        { name: "apiKey", label: i18n.t("API key") },
        { name: "apiSecret", label: i18n.t("API secret") },
        { name: "apiAccessToken", label: i18n.t("API access token") },
        {
          name: "apiAccessTokenSecret",
          label: i18n.t("API secret access token"),
        },
      ],
    },
  ].filter((sn) => {
    if (sn?.sid === 'TWITTER') {
      return config.webintPostsTwitterSearch;
    }
    return true;
  });
  static activeWebintKeywordsGroupsSocialNetworks = [
    {
      name: "Facebook",
      sid: "FB",
      id: "facebook",
      domain: "facebook.com",
      tokens: [],
    },
    {
      name: "Vkontakte",
      sid: "VK",
      id: "vkontakte",
      domain: "vk.com",
      tokens: [
        { name: "apiAccessToken", label: i18n.t("API access token") },
      ],
    },
    {
      name: "Odnoklassniki",
      sid: "OK",
      id: "odnoklassniki",
      domain: "ok.ru",
      tokens: [
        { name: "apiAppId", label: i18n.t("API app id") },
        { name: "apiKey", label: i18n.t("API key") },
        { name: "apiSecret", label: i18n.t("API secret") },
      ],
    },
    {
      name: "Linkedin",
      sid: "LINKEDIN",
      id: "linkedin",
      domain: "linkedin.com",
      tokens: [],
    },
    {
      name: "Youtube",
      sid: "YOUTUBE",
      id: "youtube",
      domain: "youtube.com",
      tokens: [],
    },
  ];

  static activeAvatarSocialNetworks = [
    ...this.socialNetworks.filter((sn) => !sn.hidden).filter(sn => sn.id !== 'tiktok' && sn.id !== "telegram"),
    {
      name: "Search4faces",
      sid: "SEARCH4FACES",
      id: "search4faces",
      domain: "search4faces.com",
      tokens: [{ name: "apiKey", label: "API key" }],
    },
    {
      name: "FindClone",
      sid: "FINDCLONE",
      id: "findclone",
      domain: "findclone.ru",
    },
  ];

  static sentiments = [
    {
      name: i18n.t("Positive"),
      id: "pos",
    },
    {
      name: i18n.t("Neutral"),
      id: "neu",
    },
    {
      name: i18n.t("Negative"),
      id: "neg",
    },
  ];
  static getSentiments = () => {
    return [
      {
        name: i18n.t("Positive"),
        id: "pos",
      },
      {
        name: i18n.t("Neutral"),
        id: "neu",
      },
      {
        name: i18n.t("Negative"),
        id: "neg",
      },
    ];
  }
  static getSentimentBadge = (sentiment) => {
    switch (sentiment?.toLowerCase()) {
      case 'positive':
        return 'success';
      case 'negative':
        return 'error';
      case 'neutral':
        return 'default';
      default:
        return 'default';
    }
  }
  static getAvatarStatuses = () => {
    return {
      VALID: i18n.t('Valid'),
      DISABLED: i18n.t('Disabled'),
      BLOCKED: i18n.t('Blocked'),
      QUARANTINE: i18n.t('Quarantine'),
      IN_PROGRESS: i18n.t('In progress'),
    };
  }

  static getCommonStatistics = (key) => {
    return {
      comments: i18n.t('Comments'),
      friends: i18n.t('Friends'),
      likes: i18n.t('Likes'),
      mentions: i18n.t('Mentions'),
      posts: i18n.t('Posts'),
    }?.[key];
  }

  static getFetchFriendsStatus = (key) => {
    return {
      STARTED: i18n.t('Started'),
      IN_PROGRESS: i18n.t('In Progress'),
      SCHEDULE: i18n.t('Schedule'),
      ADDED: i18n.t('Added'),
      COMPLETED: i18n.t('Completed'),
      FAILED: i18n.t('Failed'),
      TIMEOUT_ERROR: i18n.t('Timeout error'),
    }?.[key] || key;
  }


  static proxyCountries = [
    { text: "Azerbaijan", code: "994", id: "AZ" },
    { text: "Belgium", code: "32", id: "BE" },
    { text: "Canada", code: "1", id: "CA" },
    { text: "Czech Republic", code: "420", id: "CZ" },
    { text: "Denmark", code: "45", id: "DK" },
    { text: "Egypt", code: "20", id: "EG" },
    { text: "Finland", code: "358", id: "FI" },
    { text: "France", code: "33", id: "FR" },
    { text: "Germany", code: "49", id: "DE" },
    { text: "Kazakhstan", code: "7", id: "KZ" },
    { text: "Kyrgyzstan", code: "996", id: "KG" },
    { text: "Malaysia", code: "60", id: "MY" },
    { text: "Norway", code: "47", id: "NO" },
    { text: "Russia", code: "7", id: "RU" },
    { text: "South Korea", code: "82", id: "KR" },
    { text: "Sweden", code: "46", id: "SE" },
    { text: "Switzerland", code: "41", id: "CH" },
    { text: "Turkey", code: "90", id: "TR" },
    { text: "Ukraine", code: "380", id: "UA" },
    { text: "United Arab Emirates", code: "971", id: "AE" },
    { text: "United Kingdom", code: "44", id: "GB" },
    { text: "United States", code: "1", id: "US" },
  ];

  static postReactions = [
    "like",
    "sad",
    "love",
    "haha",
    "angry",
    "care", //fb
    "appreciation",
    "celebrate",
    "curious",
    "insightful", //linkedin
    "wow",
    "adore",
    "super",
    "hahaha",
    "yay",
    "nice", //ok
  ];

  static isValidEntityName(str) {
    const Exp = XRegExp("^[0-9\\p{L}_ -]+$");
    if (str.match(Exp)) {
      return true;
    }
    return false;
  }

  static isValidId(str) {
    const Exp = /^[^/:\s]+$/i;
    return str.match(Exp);
  }

  static isValidEmail(str) {
    //var Exp = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|media|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
    const Exp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!str.match(Exp)) {
      return false;
    } else {
      return true;
    }
  }

  static getImageSrc(url) {
    if (url) {
      if (url.indexOf("http") === 0) {
        return url;
      }
      if (url.indexOf("/app/img/") === 0) {
        return url;
      }
      if (url.indexOf("data:image") === 0) {
        return url;
      }
      if (url.indexOf("i.mycdn") === 0) {
        return `//${url}`;
      }
      return "data:image/png;base64," + url;
    }
  }

  static getImageForRender(url, sn) {
    if (sn === 'INSTAGRAM' && url?.indexOf('http') === 0) {
      return `${config.api_base_path}/api/image/proxy?image=${encodeURIComponent(url)}`
    }
    return url;
  }

  static isImageInternal(url) {
    if (url) {
      if (url.indexOf("http") === 0) {
        return false;
      }
      if (url.indexOf("data:image") === 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  static getStatNum = (number) => {
    if (number > 1000 && number < 1000000) {
      const num = number / 1000;
      return `${num.toFixed(1)}K`;
    } else if (number > 1000000) {
      const num2 = number / 1000000;
      return `${num2.toFixed(1)}M`;
    } else {
      return number;
    }
  };

  static getRoundNumber = (number) => {
    return Math.round(number * 100) / 100;
  };

  static getFirstLetterBig = (string) => {
    if (!string) return string;
    return string[0].toUpperCase() + string.slice(1);
  };

  static getSocialProfileUrl = function (network, id) {
    if (network === "FB") return "https://facebook.com/" + id;
    if (network === "FACEBOOK") return "https://facebook.com/" + id;
    if (network === "TWITTER") return "https://twitter.com/" + id;
    if (network === "LINKEDIN") return "https://www.linkedin.com/in/" + id;
    if (network === "LI") return "https://www.linkedin.com/in/" + id;
    if (network === "GOOGLEPLUS") return "https://plus.google.com/" + id;
    if (network === "GPLUS") return "https://plus.google.com/" + id;
    if (network === "ANGELLIST") return "https://angel.co/" + id;
    if (network === "FOURSQUARE") return "https://foursquare.com/user/" + id;
    if (network === "OK") {
      let okLink = "https://ok.ru/";
      if (isNaN(id)) {
        return okLink + id;
      } else {
        return okLink + "profile/" + id;
      }
    }
    if (network === "VK") {
      if (/^\d+$/.test(id)) {
        return "https://vk.com/id" + id;
      } else {
        return "https://vk.com/" + id;
      }
    }
    if (network === "INSTAGRAM") return "https://www.instagram.com/" + id;
    if (network === "TELEGRAM") {
      if (id?.indexOf("http") > -1) {
        return id;
      }
      return "https://t.me/" + id;
    }
    if (network === "MY_MAIL_RU") return "https://my.mail.ru/" + id;
    if (network === "TUMBLR") return `https://${id}.tumblr.com/`;
    if (network === "JOBZELLA") return `https://www.jobzella.com/${id}`;
    if (network === "GAB") return `https://www.gab.com/${id}`;
    if (network === "GOOGLE_MAPS")
      return `https://www.google.com/maps/contrib/${id}/reviews`;
    if (network === "TIKTOK")
      return `https://www.tiktok.com/${id}`;
    if (network === "GITHUB") {
      return `https://github.com/${id}`;
    }
    if (network === "YOUTUBE") {
      return `https://www.youtube.com/@${id}`;
    }

    return false;
  };

  static recognizeSocialNetwork = (url) => {
    if (url?.indexOf("vk.com")) {
      return "VK";
    }
    if (url?.indexOf("ok.ru")) {
      return "OK";
    }
    return "";
  };

  static getVideoEmbedUrl = (link) => {
    // eslint-disable-next-line
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = link.match(regExp);

    if (match && match[2].length === 11) {
      return `//www.youtube.com/embed/${match[2]}`;
    } else {
      return null;
    }
  };

  static getDateFromMiliSeconds(miliseconds, dateFormat = "en-Us") {
    var options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    const date = new Date(miliseconds);
    return date.toLocaleString(dateFormat, options);
  }

  static getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static getMd5Color = function (text) {
    if (!text) return false;
    const ColorLuminance = function (hex, lum) {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, "");
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      let rgb = "",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    };
    let color = md5(text).substring(0, 6);
    color = ColorLuminance(color, -0.2);

    return color;
  };

  static getAlertTypeColor(alertType = '') {
    switch (alertType) {
      case "KEYWORD":
        return "#98A3B9";
      case "FACE_IMAGE":
        return "#9f13a9";
      case "POST_AUDITORY_IN_GROUP":
        return "#77D1A6";
      case "GROUP_MEMBERS":
        return "#3994DB";
      case "GROUP_PUBLICATIONS_BURST":
        return "#9FDAE7";
      case "GROUP_COMMENTS_BURST":
        return "#B5A6D6";
      case "USER_ACTIVITY_IN_GROUP":
        return "#FFCF80";
      case "SYSTEM_ALERT":
        return "#EF6352";
      default:
        return "none";
    }
  }

  static getChartColor = (phrase) => {
    switch (phrase) {
      //sentiment
      case "NEUTRAL":
        return "#3994DB";
      case "NEGATIVE":
        return "#EF6352";
      case "POSITIVE":
        return "#ABE188";

      //post
      case "PUBLISHED":
        return "#495861";

      //socialNetwork
      case "FB":
        return "#3994DB";

      //Activity
      case "LIKES":
        return "#3977C6";
      case "FRIENDS":
        return "#DA6F2B";
      case "MENTIONS":
        return "#F4D166";
      case "POSTS":
        return "#13a942";
      case "COMMENTS":
        return "#9f13a9";

      //Available avatars
      case "ACTIVE":
        return "#4DC591";
      case "BLOCKED":
        return "#EF6352";
      case "DISABLED":
        return "#FFD460";
      case "QUARANTINE":
        return "#98A3B9";
      //avatar usage
      case "FACEBOOK":
        return "#F68634";
      case "VIBER":
        return "#4DC591";
      case "LINKEDIN":
        return "#FFCF80";
      //Webint
      case "MALE":
        return "#98A3B9";
      case "FEMALE":
        return "#FFCF80";
      default:
        return "#3994DB";
    }
  };

  static avatarActivitiesItems = () => {
    return [
      { name: i18n.t("Autologin"), value: "AUTOLOGIN", color: "#98A3B9" },
      {
        name: "URL",
        value: "URL",
        color: "#FFD460",
      },
      {
        name: i18n.t("User post"),
        value: "USER_POST",
        color: "#EF6352",
      },
      {
        name: i18n.t("Group post"),
        value: "GROUP_POST",
        color: "#4DC591",
      },
      {
        name: i18n.t("Like of a post"),
        value: "LIKE_POST",
        color: "#B1E4EF",
      },
      {
        name: i18n.t("Comment to a post"),
        value: "COMMENT_POST",
        color: "#DD99C2",
      },
    ];
  };

  static getBlockedReasons = () => [
    {
      type: "CHECKPOINT_PAGE_REDIRECT",
      name: "CHECKPOINT PAGE REDIRECT",
      color: "#EF6352",
    },
    { type: "LIMITED_ACCESS", name: " LIMITED ACCESS", color: "#FFD460" },
    { type: "COOKIE_EXPIRED", name: "COOKIE EXPIRED", color: "#98A3B9" },
    { type: "RUNNING", name: "RUNNING", color: "#4DC591" },
    //{ type: "FINISHED", name: "FINISHED", color: "#9FDAE7" },
    { type: "PENDING", name: "PENDING", color: "#DD99C2" },
    { type: "INVALID_TARGET", name: "INVALID TARGET", color: "#FF11AA" },
    {
      type: "INCOMPLETE_AVATAR_PROFILE",
      name: "INCOMPLETE AVATAR PROFILE",
      color: "#445566",
    },
    { type: "MAINTENANCE", name: "MAINTENANCE", color: "#0784fc" },
    {
      type: "INVALID_CREDENTIALS",
      name: "INVALID CREDENTIALS",
      color: "#AAFF66",
    },
    { type: "INVALID_KEYPHRASE", name: "INVALID KEYPHRASE", color: "#11c6ef" },
    {
      type: "INVALID_REQUIRED_ARGUMENT",
      name: "INVALID REQUIRED ARGUMENT",
      color: "#F68634",
    },
    { type: "HTTP_ERROR", name: "HTTP ERROR", color: "#EF6352" },
    { type: "UNKNOWN", name: "UNKNOWN", color: "#FFCF80" },
    { type: "MANUAL_BLOCKED", name: "MANUAL BLOCKED", color: "#ABE188" },
    { type: "CREATED_AVATAR", name: "CREATED AVATAR", color: "#3994DB" },
  ];

  static getRangeByPeriod = (period) => {
    switch (period) {
      case "1D":
        return { start: moment().subtract(1, "days"), end: moment() };
      case "1W":
        return { start: moment().subtract(1, "week"), end: moment() };
      case "1M":
        return { start: moment().subtract(1, "month"), end: moment() };
      case "6M":
        return { start: moment().subtract(6, "months"), end: moment() };
      case "1Y":
        return { start: moment().subtract(1, "year"), end: moment() };
      default:
        return null;
    }
  };

  static getPostsRangeByPeriod = (period) => {
    switch (period) {
      case "1D":
        return {
          start: moment().startOf('day'),
          end: moment().endOf('day')
        };
      case "1W":
        return {
          start: moment().subtract(1, "week").startOf('day'),
          end: moment().endOf('day')
        };
      case "1M":
        return {
          start: moment().subtract(1, "month").startOf('day'),
          end: moment().endOf('day')
        };
      case "6M":
        return {
          start: moment().subtract(6, "months").startOf('day'),
          end: moment().endOf('day')
        };
      case "1Y":
        return {
          start: moment().subtract(1, "year").startOf('day'),
          end: moment().endOf('day')
        };
      default:
        return null;
    }
  };

  static getActivityRectangleColor = (activityType) => {
    switch (activityType) {
      case "USER_POST":
      case "POST":
        return "#EF6352";
      case "URL":
        return "#FFD460";
      case "AUTOLOGIN":
        return "#98A3B9";
      case "GROUP_POST":
        return "#4DC591";
      case "LIKE_POST":
        return "#9FDAE7";
      case "COMMENT_POST":
        return "#DD99C2";
      case "AUDIT":
        return "#FF11AA";
      case "PROFILE":
        return "#445566";
      case "WEBINT":
        return "#0784fc";
      case "LOGIN":
        return "#AAFF66";
      case "USER":
        return "#11c6ef";
      default:
        return "";
    }
  };

  static webintTargetTypes = () => {
    return {
      GROUP_MOST_POSTED_PROFILE_ITEM: i18n.t("Most Posted"),
      GROUP_MOST_LIKED_PROFILE_ITEM: i18n.t("Most Liked"),
      GROUP_MOST_COMMENTED_PROFILE_ITEM: i18n.t("Most Commented"),
      GROUP_ADMIN_PROFILE_ITEM: i18n.t("Admin"),
      GROUP_MODERATOR_PROFILE_ITEM: i18n.t("Moderator"),
      GROUP_NEW_MEMBER_PROFILE_ITEM: i18n.t("New Member"),
      GROUP_MOST_ACTIVE_PROFILE_ITEM: i18n.t("Most Active"),
      GROUP_MOST_INFLUENCING_PROFILE_ITEM: i18n.t("Most Influencing"),
      GROUP_BOT_PROFILE_ITEM: i18n.t("Group Bot"),
      GROUP_MEMBERS_ITEM: i18n.t("Group Member"),
      GROUP_BANNED_ITEM: i18n.t("Banned"),
      GROUP_CREATOR_ITEM: i18n.t("Creator"),
      GROUP_LEFT_ITEM: i18n.t("Left"),
      GROUP_RESTRICTED_ITEM: i18n.t("Restricted"),
      GROUP_UNKNOWN_ITEM: i18n.t("Unknown"),
      //MOST_ACTIVE_PROFILE_ITEM: i18n.t("Most Active"),

      /*MEMBER: $filter('translate')('Group Member (Telegram)'),
      ADMINISTRATOR: $filter('translate')('Admin (Telegram)'),
      BANNED: $filter('translate')('Banned (Telegram)'),
      CREATOR: $filter('translate')('Creator (Telegram)'),
      LEFT: $filter('translate')('Left (Telegram)'),
      RESTRICTED: $filter('translate')('Restricted (Telegram)'),
      UNKNOWN: $filter('translate')('Unknown (Telegram)')*/
    }
  };

  static auditTypes = [
    "LOGIN",
    "LOGOUT",
    "CREATE_JOB",
    "CREATE_RANGE_JOB",
    "JOB",
    "PING",
    "IMAGE",
    "IMAGE_CLEAR_STORAGE",
    "AUDIT",
    "USER",
    "PROFILE",
    "WEBINT",
    "HEALTH_STATUS",
    "DELETE",
    "SEARCH_4_FACES",
    //new gen
    "SYSTEM",
    "ALERTS",
    "PROFILER",
    "AVATARS",
    "ACTIVITIES",
  ];

  static auditSubTypes = [
    ///"USER_NOT_FOUND", // User not found.
    "GET_AUDITS_LOG", // Get audits.
    //TODO delete this point
    "SUCCESS_LOGIN", // Success login.
    //TODO delete this point
    "SUCCESS_LOGOUT", // Success logout.
    ///"PING", // Ping.
    ///"GET_ALL_STATUSES", // Get all statuses.
    ///"GET_SERVICE_STATUS", // Get service status.
    ///"GET_IMAGE", // Get image.
    ///"CLEAR_STORAGE_IN_REKOGNITION", // Clear storage in rekognition.
    ///"CREATE_ADDITIONAL_JOB", //Create additional job.
    ///"CHANGE_JOB_STATUS", // Change job status.
    ///"GET_MAIN_JOB", // Get main job.
    ///"SEARCH_PROFILES", // Search profiles.
    ///"EXPORT_PROFILES", // Export profiles
    ///"EXPORT_PROFILES_JSON", // Search profiles.
    ///"SEARCH_PROFILE_BY_IMAGE", // Search profile by image.
    ///"SEARCH_PROFILES_BY_PHONE", // Search profiles by phone.
    ///"GET_PROFILE_BY_IMAGE_IDS",
    //TODO delete this point
    "REGISTER_NEW_USER", // Register new user.
    "SEARCH_USERS", // Search users.
    ///"COULD_NOT_FIND_PHONE", // Could not find phone.
    ///"COULD_NOT_FIND_ITERATION", // Could not find iteration.
    ///"COULD_NOT_FIND_BATCH", // Could not find batch.
    ///"COULD_NOT_FIND_JOB", // Could not find job.
    ///"USER_DOES_NOT_EXIST", // User does not exist.
    ///"USERNAME_ADDRESS_ALREADY_IN_USE", // UserName Address already in use!
    ///"COULD_NOT_DELETE_ITSELF", // Could not delete itself!
    ///"JOB_DOES_NOT_EXIST", // Job does not exist.
    ///"INAPPROPRIATE_JOB_STATUS", // You could not set job to this state.
    ///"JOB_SENT_ALL_PHONES_TO_LOOKUP", // Job sent all phones to Lookup already.
    ///"MAIN_JOB_COULD_NOT_BE_CANCELED", // Main job could not be canceled.
    ///"IMPORT_PROFILE",
    ///"DETECT_FACES",
    ///"SEARCH_FACE",
    ///"JOB_LIST",
    ///"JOB_PROFILES",
    ///"UPDATE",
    //TODO delete this point
    ///"GET_CASE_PROFILES",
    ///"QUOTA_OBJECT_NOT_SET",
    ///"QUOTA_MAX_CRAWLER_PER_USER_INVALID",
    ///"QUOTA_MAX_CASES_PER_USER_INVALID",
    "QUOTA_MAX_LOOKUP_PER_USER_INVALID",
    ///"QUOTA_MAX_PERIODICALOPS_PER_USER_INVALID",
    ///"UNDEFINED_CASE_TYPE",
    ///"INSUFFICIENT_QUOTA_TO_PERFORM_OPERATION",
    ///"QUOTA_IS_EXPIRED",
    ///"QUOTA_IS_NOT_STARTED_YET",
    ///"SEND_AVATAR_ACTIVITY_TO_CRAWLER", // Sending activity avatar to crawler for execution
    ///"INVALID_OR_FORBIDDEN_USERNAME",
    //TODO delete this point
    ///"ADD_TARGET_INFORMATION",

    //new gen
    //System

    "LOGIN",
    "LOGOUT",
    "ADD_USER",
    "DELETE_USER",
    "UPDATE_USER",
    "EXPORT",
    "IMPORT",
    "MONITORING_ON",
    "MONITORING_OFF",
    "AUDIT",
    "CREATE_CASE",
    "DELETE_CASE",
    "CREATE_INVESTIGATION",
    "DELETE_INVESTIGATION",

    //Alerts

    "CREATE_ALERT",
    "DELETE_ALERT",

    //Profiler

    "CREATE_PROFILE",
    "DELETE_PROFILE", //renamed from DELETE_TARGET for conformity
    "GET_PROFILE",
    "UPDATE_PROFILE", //renamed from ADD_TARGET_INFORMATION for conformity
    "CRAWL_PROFILE", //renamed from DEEP_CRAWL for conformity
    "CRAWL_PROFILER", //
    "FACE_RECOGNITION",
    "DELETE_SOURCE",
    "LINK_ANALYSIS",
    "DELETE_LOOKUPREQUEST",

    //Webint

    "CREATE_TASK",
    "DELETE_TASK",
    "GET_TASK",
    "UPDATE_TASK",
    "CRAWL_TASK",

    //Avatars

    "CREATE_AVATAR",
    "DELETE_AVATAR",
    "GET_AVATAR",
    "EDIT_AVATAR",
    "UPDATE_AVATAR",
    "MANUAL_QUARANTINE",
    "AUTO_QUARANTINE",
    "EDIT_QUARANTINE_SCHEME",
    "UPDATE_AVATAR_MODULE",
    "TASK_LINK_UPDATE",
    "EXPORT_LOGS",
    "EDIT_PROXY",
    "ADD_PROFILE",
    //DELETE_PROFILE",  double
    "EDIT_PROFILE",
    "APA_AVATAR_INTERACTION",

    //Activities

    "CREATE_ACTIVITY",
    "UPDATE_ACTIVITY",
    "DELETE_ACTIVITY",
    "AUTOLOGIN",
    "URL",
    "USER_POST",
    "GROUP_POST",
    "LIKE_POST",
    "COMMENT_POST",
  ];

  static getBase64NotAsync(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  static getFileRealType(file, callback) {
    const fileReader = new FileReader();
    fileReader.onloadend = function(e) {
      const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
      let header = "";
      for(let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      console.log('HEADER', header);
      let type;
      switch (header) {
        case "89504e47":
          type = "image/png";
          break;
        case "47494638":
          type = "image/gif";
          break;
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          type = "image/jpeg";
          break;
        default:
          type = null; // Or you can use the blob.type as fallback
          break;
      }
      callback(type)
    };
    fileReader.readAsArrayBuffer(file);
  }

  static getTitle = (text) => {
    let finalResult = text;
    if (text) {
      if (text.indexOf("_") > 0) {
        finalResult = text
          .split("_")
          .map(
            (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
          )
          .join(" ");
      } else {
        const result = text.replace(/([A-Z])/g, " $1");
        finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      }
    }

    return finalResult;
  };

  static capitalizeTitle = (text) => {
    let finalResult = text;
    if (text) {
      if (text.indexOf("_") > 0) {
        finalResult = text
          .split("_")
          .map(
            (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
          )
          .join(" ");
      } else {
        finalResult =
          text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }
    }

    return finalResult;
  };

  static getActivityPreviewStatusImage(activityStatus) {
    switch (activityStatus) {
      case "SUCCESS":
        return "sucess";
      case "FAILED":
        return "fail";
      default:
        return "pending";
    }
  }

  static getActivityPreviewStatusText(activityStatus) {
    switch (activityStatus) {
      case "SUCCESS":
        return i18n.t("Success");
      case "FAILED":
        return i18n.t("Fail");
      default:
        return i18n.t("Pending");
    }
  }

  /**
   * Translates seconds into human readable format of seconds, minutes, hours, days, and years
   *
   * @param  {number} seconds The number of seconds to be processed
   * @return {string}         The phrase describing the amount of time
   */
  static durationTime(seconds) {
    const levels = [
      [Math.floor(seconds / 31536000), "y"],
      [Math.floor((seconds % 31536000) / 86400), "d"],
      [Math.floor(((seconds % 31536000) % 86400) / 3600), "h"],
      [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), "m"],
      [(((seconds % 31536000) % 86400) % 3600) % 60, "s"],
    ];
    let returntext = "";

    for (var i = 0, max = levels.length; i < max; i++) {
      if (levels[i][0] === 0) continue;
      returntext +=
        " " +
        levels[i][0] +
        "" +
        (levels[i][0] === 1
          ? levels[i][1].substr(0, levels[i][1].length - 1)
          : levels[i][1]);
    }

    return returntext.trim();
  }

  static stripHtmlTags = (string) => {
    return string ? string.replace(/<\/?[^>]+(>|$)/g, "") : "";
  };

  static toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  static getAvatarActivityZeroTimestamps = (dateToCountTimeStamp) => {
    const start = new Date(dateToCountTimeStamp);
    start.setDate(0);
    const end = new Date(dateToCountTimeStamp);
    end.setDate(31);
    return [start.getTime(), end.getTime()];
  };

  static getMetaAvatarProxyType = (proxyItem) => {
    if (proxyItem) {
      if (proxyItem.custom)
        return {
          custom: true,
          proxyTypeSelect: "custom",
          domainName: proxyItem.domain,
          port: proxyItem.port,
          proxyUser: proxyItem.login,
          proxyPass: proxyItem.password,
        };
      else if (!proxyItem.custom && proxyItem.domain)
        return {
          custom: false,
          proxyTypeSelect: "templates",
          domainNameStandard: proxyItem.domain,
          portStandard: proxyItem.port,
          proxyUserStandard: proxyItem.login,
          proxyPassStandard: proxyItem.password,
        };
      else {
        return { proxyTypeSelect: "notUsed" };
      }
    } else {
      return {};
    }
  };

  static alertsFormSeter = (item) => {
    const initialForm = {
      id: "",
      type: "",
      keyword: {
        keyword: "",
        module: false,
        duplicate: false,
        APIKey: "",
        chatId: "",
      },
      photo: {
        module: false,
        duplicate: false,
        likeness: 60,
        APIKey: "",
        chatId: "",
        files: "",
      },
      auditory: {
        id: "",
        threshold: "",
        module: [],
        duplicate: false,
        APIKey: "",
        chatId: "",
      },
      members: {
        taskId: "",
        threshold: "",
        module: [],
        duplicate: false,
        APIKey: "",
        chatId: "",
      },
      posts: {
        taskId: "",
        threshold: "",
        //module: false,
        duplicate: false,
        APIKey: "",
        chatId: "",
      },
      comments: {
        taskId: "",
        threshold: "",
        //module: false,
        duplicate: false,
        APIKey: "",
        chatId: "",
      },
      user: {
        snId: "",
        sn: "FB",
        taskId: "",
        duplicate: false,
        APIKey: "",
        chatId: "",
      },
      system: {
        APIKey: "",
        chatId: "",
      },
    };
    if (item) {
      switch (item.alertType) {
        case "GROUP_MEMBERS":
          initialForm.id = item.id;
          initialForm.type = "members";
          initialForm.members.taskId = item.targetID || "";
          initialForm.members.duplicate =
            !!item?.telegramIntegration?.apiKey || false;
          initialForm.members.threshold = item?.membersThreshold || "";
          initialForm.members.module = [
            item.activeForProfiler && "profiler",
            item.activeForWebint && "massMapper",
          ];
          initialForm.members.APIKey = item?.telegramIntegration?.apiKey || "";
          initialForm.members.chatId = item?.telegramIntegration?.chatId || "";
          break;
        case "KEYWORD":
          initialForm.id = item.id;
          initialForm.type = "keyword";
          initialForm.keyword.keyword = item.keyword || "";
          initialForm.keyword.duplicate =
            !!item?.telegramIntegration?.apiKey || false;
          initialForm.keyword.module = [
            item.activeForProfiler && "profiler",
            item.activeForWebint && "massMapper",
          ];
          initialForm.keyword.APIKey = item?.telegramIntegration?.apiKey || "";
          initialForm.keyword.chatId = item?.telegramIntegration?.chatId || "";
          break;
        case "FACE_IMAGE":
          initialForm.id = item.id;
          initialForm.type = "photo";
          initialForm.photo.likeness = item.faceImageMatchScore || 60;
          initialForm.photo.files = item.faceImageURL || "";
          initialForm.photo.duplicate =
            !!item?.telegramIntegration?.apiKey || false;
          initialForm.photo.module = [
            item.activeForProfiler && "profiler",
            item.activeForWebint && "massMapper",
          ];
          initialForm.photo.APIKey = item?.telegramIntegration?.apiKey || "";
          initialForm.photo.chatId = item?.telegramIntegration?.chatId || "";
          break;
        case "POST_AUDITORY_IN_GROUP":
          initialForm.id = item.id;
          initialForm.type = "auditory";
          initialForm.auditory.threshold = item.auditoryThreshold || "";
          initialForm.auditory.taskId = item.targetID || "";
          initialForm.auditory.duplicate =
            !!item?.telegramIntegration?.apiKey || false;
          initialForm.auditory.module = [
            item.activeForProfiler && "profiler",
            item.activeForWebint && "massMapper",
          ];
          initialForm.auditory.APIKey = item?.telegramIntegration?.apiKey || "";
          initialForm.auditory.chatId = item?.telegramIntegration?.chatId || "";
          break;
        case "GROUP_PUBLICATIONS_BURST":
          initialForm.id = item.id;
          initialForm.type = "posts";
          initialForm.posts.threshold = item?.publicationBurstThreshold || "";
          initialForm.posts.taskId = item.targetID || "";
          initialForm.posts.duplicate =
            !!item?.telegramIntegration?.apiKey || false;
          initialForm.posts.module = [
            item.activeForProfiler && "profiler",
            item.activeForWebint && "massMapper",
          ];
          initialForm.posts.APIKey = item?.telegramIntegration?.apiKey || "";
          initialForm.posts.chatId = item?.telegramIntegration?.chatId || "";
          break;
        case "GROUP_COMMENTS_BURST":
          initialForm.id = item.id;
          initialForm.type = "comments";
          initialForm.comments.threshold = item?.commentsBurstThreshold || "";
          initialForm.comments.taskId = item.targetID || "";
          initialForm.comments.duplicate =
            !!item?.telegramIntegration?.apiKey || false;
          initialForm.comments.module = [
            item.activeForProfiler && "profiler",
            item.activeForWebint && "massMapper",
          ];
          initialForm.comments.APIKey = item?.telegramIntegration?.apiKey || "";
          initialForm.comments.chatId = item?.telegramIntegration?.chatId || "";
          break;
        case "USER_ACTIVITY_IN_GROUP":
          initialForm.id = item.id;
          initialForm.type = "user";
          initialForm.user.taskId = item.targetID || "";
          initialForm.user.duplicate =
            !!item?.telegramIntegration?.apiKey || false;
          initialForm.user.snId = item.userSocialNetworkID || "";
          initialForm.user.sn = item.userSocialNetworkType || "FB";
          initialForm.user.APIKey = item?.telegramIntegration?.apiKey || "";
          initialForm.user.chatId = item?.telegramIntegration?.chatId || "";
          break;
        case "SYSTEM_ALERT":
          initialForm.id = item.id;
          initialForm.type = "system";
          initialForm.system.duplicate = true;
          initialForm.system.APIKey = item?.telegramIntegration?.apiKey || "";
          initialForm.system.chatId = item?.telegramIntegration?.chatId || "";
          break;
        default:
          return initialForm;
      }
    }
    return initialForm;
  };

  static ammProfileStatus(value) {
    switch (value) {
      case "VALID":
        return i18n.t("Valid");
      case "BLOCKED":
        return i18n.t("Blocked");
      case "REQUIRES_VALIDATION":
        return i18n.t("Requires validation");
      case "DISABLED":
        return i18n.t("Disabled");
      case "QUARANTINE":
        return i18n.t("Quarantine");
      default:
        return null;
    }
  }
  static readableProxyType(value) {
    switch (value) {
      case "CUSTOM":
        return "CUSTOM";
      case "STANDART":
        return "STANDART";
      case "NOT_USED":
        return "NOT USED";
      default:
        return "UNKNOWN";
    }
  }
  static checkErrors = (errorsObject) => {
    return Object.values(errorsObject)
      .map((elem) => !!elem)
      .includes(true);
  };

  static highlightKeywords = (text, keyword) => {
    if (text) {
      text = text?.replace(
        keyword,
        `<span class="highlight-keyword">${keyword}</span>`
      );
    }
    return text;
  };

  static replaceHashtags = (text, sn) => {
    if (text) {
      // eslint-disable-next-line
      //const regex = /#([^#^\s^\>^\\^\<^\:^\,^\.^\;^\-^\'^\"^\!^\—^\(^\)^\[^\]^\…^\?^\”^\’^\/]+)/gi;
      const regex = new RegExp(`#([^#^\\s^\\>^\\\\^\\<^\\:^\\,^\\.^\\;^\\-^\\'^\\"^\\!^\\—^\\(^\\)^\\[^\\]^\\…^\\?^\\”^\\’^\\/]+)`, "gi");
      switch (sn) {
        case "FB":
        case "FACEBOOK":
          return text.customReplace(
            regex,
            (value) =>
              `<a href='https://www.facebook.com/hashtag/${value.substring(
                1
              )}' class='hashtag_link' target='_blank'>${value}</a>`
          );
        case "TWITTER":
          return text.customReplace(
            regex,
            (value) =>
              `<a href='https://twitter.com/hashtag/${value.substring(
                1
              )}' class='hashtag_link' target='_blank'>${value}</a>`
          );
        case "INSTAGRAM":
          return text.customReplace(
            regex,
            (value) =>
              `<a href='https://www.instagram.com/explore/tags/${value.substring(
                1
              )}' class='hashtag_link' target='_blank'>${value}</a>`
          );
        case "LINKEDIN":
          return text.customReplace(
            regex,
            (value) =>
              `<a href='https://www.linkedin.com/feed/hashtag/?keywords=${value.substring(
                1
              )}' class='hashtag_link' target='_blank'>${value}</a>`
          );
        case "VK":
          return text.customReplace(
            regex,
            (value) =>
              `<a href='https://vk.com/feed?q=%23${value.substring(
                1
              )}&section=search' class='hashtag_link' target='_blank'>${value}</a>`
          );
        case "MY_MAIL_RU":
          return text.customReplace(
            regex,
            (value) =>
              `<a href='https://my.mail.ru/hashtag/${value.substring(
                1
              )}' class='hashtag_link' target='_blank'>${value}</a>`
          );
        default:
          return text;
      }
    }
    return text;
  };

  static webintTaskTypes = {
    groups: "GROUP_CHANNEL",
    keywords: "KEYWORDS",
    posts: "PROFILE_POSTS",
    keywords_groups: "GROUP_KEYWORDS",
  };
  static webintTaskTypesReverse = {
    GROUP_CHANNEL: "groups",
    KEYWORDS: "keywords",
    PROFILE_POSTS: "posts",
    GROUP_KEYWORDS: "keywords_groups",
  };
  static webintTaskTypesLabel = key => {
    return {
      GROUP_CHANNEL: i18n.t("Groups"),
      KEYWORDS: i18n.t("Keywords"),
      PROFILE_POSTS: i18n.t("Posts"),
      GROUP_KEYWORDS: i18n.t("Keywords Groups"),
    }?.[key] || key
  };

  static myMailPrefixesList = ["mail/", "bk/", "inbox/", "list/"];

  static countSum = (array) => {
    //console.log(array);
    return array.reduce((a, b) => {
      return a + b.value;
    }, 0);
  };

  static withOffset(cron) {
    const cronArray = cron.split(" ");
    let out;
    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    out = +cronArray[2] + timezoneOffset;

    if (out < 0) {
      out = 24 + out;
    } else if (out > 23) {
      out = 24 - out;
    }
    cronArray[2] = `${out}`;
    //console.log('withOffset', out, timezoneOffset);
    return cronArray.join(" ");
  }
  static unique(arr) {
    let result = [];

    for (let str of arr) {
      if (!result.includes(str)) {
        result.push(str);
      }
    }

    return result;
  }

  static dateStringReverser = (dateString, separator) => {
    return dateString.split(separator).reverse().join("-");
  };

  static alertFormType = {
    keyword: "KEYWORD",
    photo: "FACE_IMAGE",
    auditory: "POST_AUDITORY_IN_GROUP",
    members: "GROUP_MEMBERS",
    posts: "GROUP_PUBLICATIONS_BURST",
    comments: "GROUP_COMMENTS_BURST",
    user: "USER_ACTIVITY_IN_GROUP",
    system: "SYSTEM_ALERT",
  };

  static alertFormTypeLabel = key => {
    return {
      KEYWORD: i18n.t("Keyword"),
      FACE_IMAGE: i18n.t("Face Image"),
      POST_AUDITORY_IN_GROUP: i18n.t("Post Auditory in Group"),
      GROUP_MEMBERS: i18n.t("Group Members"),
      GROUP_PUBLICATIONS_BURST: i18n.t("Group Publications Burst"),
      GROUP_COMMENTS_BURST: i18n.t("Group Comments Burst"),
      USER_ACTIVITY_IN_GROUP: i18n.t("User Activity in Group"),
      SYSTEM_ALERT: i18n.t("System Alert"),
    }?.[key] || key
  }

  static availableAlertFormType = () => {
    const available = this.alertFormType;
    if (!config.availableAlerts.includes('keywords')) delete available['keywords'];
    if (!config.availableAlerts.includes('face')) delete available['photo'];
    if (!config.availableAlerts.includes('auditory')) delete available['auditory'];
    if (!config.availableAlerts.includes('members')) delete available['members'];
    if (!config.availableAlerts.includes('posts')) delete available['posts'];
    if (!config.availableAlerts.includes('comments')) delete available['comments'];
    if (!config.availableAlerts.includes('activity')) delete available['user'];
    if (!config.availableAlerts.includes('system')) delete available['system'];

    return available;
  }

  static cleanFilters = (obj) => {
    let cleanObj = {};
    Object.keys(obj).forEach(val => {
      const newVal = obj[val];
      if (Array.isArray(newVal)) {
        if (newVal.length > 0) {
          cleanObj = { ...cleanObj, [val]: newVal };
        }
      } else if (newVal) {
        cleanObj = { ...cleanObj, [val]: newVal };
      }
    });

    return cleanObj;
  }

  static historyPush = (url, backButton = false) => {
    //console.log('path', window.location.pathname, backButton)
    if (url !== window.location.pathname && !backButton) {
      window.history.pushState(null, null, url);
    }
  }

  static isEmptyInfo = (info = []) => {
    let empty = true;
    info.forEach(item => {
      if (!(item.hasOwnProperty('info') && item.info === '')){
        empty = false
      }
    })
    return empty;
  }

  static extractSocialMediaInfo = (url) => {
    // Regular expression to match social media URL patterns
    // eslint-disable-next-line
    const linkedInRegex = /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in\/([^\/?]+)\/?|company\/([^\/?]+)\/?)/
    // eslint-disable-next-line
    const instagramRegex = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p\/([^\/]+)\/?|(?:[^\/]+\/)?([^\/?]+))/
    // eslint-disable-next-line
    const flickrRegex = /(?:https?:\/\/)?(?:www\.)?flickr\.com\/photos\/([^\/]+)\/?/
    // eslint-disable-next-line
    const tiktokRegex = /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@([^\/?]+)\/?/
    // eslint-disable-next-line
    const facebookRegex = /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:[^\/?]+\/)?(?:posts\/([^\/?]+)\/|(?:[^\/?]+\/)?([^\/?]+)\/?)/
    // eslint-disable-next-line
    const twitterRegex = /(?:https?:\/\/)?(?:www\.)?twitter\.com\/([^\/?]+)\/?/
    // eslint-disable-next-line
    const githubRegex = /(?:https?:\/\/)?(?:www\.)?github\.com\/([^\/?]+)\/?/


    // Extract matches from the LinkedIn URL
    const linkedInMatches = url.match(linkedInRegex)

    if (linkedInMatches) {
      const userId = linkedInMatches[1]
      const companyId = linkedInMatches[2]

      if (userId) {
        return {
          network: 'linkedin',
          user_id: userId,
        }
      } else if (companyId) {
        return {
          network: 'linkedin',
          user_id: companyId,
        }
      }
    }

    // Extract matches from the Instagram URL
    const instagramMatches = url.match(instagramRegex)

    if (instagramMatches) {
      const postId = instagramMatches[1]
      const instaUserId = instagramMatches[2]

      if (postId) {
        return {
          network: 'instagram',
          user_id: postId,
        }
      } else if (instaUserId) {
        return {
          network: 'instagram',
          user_id: instaUserId,
        }
      }
    }

    // Extract matches from the Flickr URL
    const flickrMatches = url.match(flickrRegex)

    if (flickrMatches) {
      const flickrUserId = flickrMatches[1]

      if (flickrUserId) {
        return {
          network: 'flickr',
          user_id: flickrUserId,
        }
      }
    }

    const tiktokMatches = url.match(tiktokRegex)

    if (tiktokMatches) {
      const tiktokUserId = tiktokMatches[1]

      if (tiktokUserId) {
        return { network: 'tiktok', user_id: tiktokUserId }
      }
    }

    const facebookMatches = url.match(facebookRegex)

    if (facebookMatches) {
      const postId = facebookMatches[1]
      const facebookUserId = facebookMatches[2]

      if (postId) {
        return { network: 'fb', user_id: postId }
      } else if (facebookUserId) {
        return { network: 'fb', user_id: facebookUserId }
      }
    }

    const twitterMatches = url.match(twitterRegex)

    if (twitterMatches) {
      const twitterUserId = twitterMatches[1]

      if (twitterUserId) {
        return { network: 'twitter', user_id: twitterUserId }
      }
    }

    const githubMatches = url.match(githubRegex)

    if (githubMatches) {
      const githubUserId = githubMatches[1]

      if (githubUserId) {
        return { network: 'github', user_id: githubUserId }
      }
    }

    // URL doesn't match the expected patterns
    return {
      network: 'web',
      user_id: 'Link',
    }
  }
}
