import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {withTranslation} from "react-i18next";

const PsychoProfiles = ({t, data}) => {

  const { psychoProfile } = data;
  let psychoItems = [];
  let summaryItems = [];

  if (psychoProfile) {
    const { summary = {}, psycho = {} } = psychoProfile;
    summaryItems = summary?.result?.summaries || [];
    psychoItems = psycho?.result?.profiles || [];
  }


  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Psycho Profiles")}:</Text>

      <View>
        {
          psychoItems?.map(({personName, psychologicalPortrait, levelOfDanger, predictedCharacteristics}, idx) =>
            <View key={idx} style={{display: 'flex', flexFlow: 'column', gap: '10px'}}>
              <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{personName}</Text>
              <Text style={{fontSize: '12px', fontFamily: 'pdfFont', padding: '10px'}}>{psychologicalPortrait}</Text>
              <Text style={{fontSize: '12px', fontFamily: 'pdfFont',}}>{t("Level of Danger")}: {levelOfDanger}</Text>
              <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center', gap: '10px'}}>
                {
                  predictedCharacteristics?.map((pC, idx) => <View key={idx}>
                    <Text style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      marginRight: '10px',
                      fontFamily: 'pdfFont',
                      color: (() => {
                        switch (levelOfDanger) {
                          case 'High':
                            return 'red'
                          case 'Medium':
                            return '#cccc93';
                          default:
                            return '#2ecc71'
                        }
                      })(),
                    }}>{pC}</Text>
                  </View>)
                }

              </View>
            </View>
          )
        }
      </View>

      <Text style={{margin: '20px', fontFamily: 'pdfFont',}}>{t("Psycho Summary")}:</Text>

      <View>
        {
          summaryItems?.map(({summary, country, age, occupation, gender}, idx) =>
            <View key={idx} style={{display: 'flex', flexFlow: 'column', gap: '10px'}}>
              <Text style={{fontSize: '12px', fontFamily: 'pdfFont',padding: '10px'}}>{summary}</Text>
              <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', paddingBottom: '10px'}}>
                <Text style={{fontSize: '10px',fontWeight: 'bold',marginRight: '10px',fontFamily: 'pdfFont',color: '#99A3B9',}}>{t("Country")}</Text>
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{country}</Text>
              </View>
              <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', paddingBottom: '10px'}}>
                <Text style={{fontSize: '10px',fontWeight: 'bold',marginRight: '10px',fontFamily: 'pdfFont',color: '#99A3B9',}}>{t("Age")}</Text>
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{age}</Text>
              </View>
              <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', paddingBottom: '10px'}}>
                <Text style={{fontSize: '10px',fontWeight: 'bold',marginRight: '10px',fontFamily: 'pdfFont',color: '#99A3B9',}}>{t("Occupation")}</Text>
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{occupation}</Text>
              </View>
              <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', paddingBottom: '10px'}}>
                <Text style={{fontSize: '10px',fontWeight: 'bold',marginRight: '10px',fontFamily: 'pdfFont',color: '#99A3B9',}}>{t("Gender")}</Text>
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{gender}</Text>
              </View>
            </View>

          )
        }
      </View>

      <Text break />
    </View>
  );
};

export default withTranslation()(PsychoProfiles);