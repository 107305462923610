import BaseReducer from "../BaseReducer";
import AuthAction from "./authAction";
import jwt_decode from "jwt-decode";

export default class AuthReducer extends BaseReducer {
  getInitialState = () => {
    const defaultState = {
      isAuth: false,
      user: null
    }
    let accessToken;
    try {
      accessToken = window?.localStorage?.getItem("auth");
    } catch (e) {}

    if (accessToken) {
      try{

      } catch (e) {
        return defaultState;
      }
      const {sub, roles, topPostsIsActive = false} = jwt_decode(accessToken);
      return {
        isAuth: true,
        user: {username: sub, roles: roles.split(','), token: accessToken, topPostsIsActive}
      }
    }
    return defaultState;
  }
  initialState = this.getInitialState();

  [AuthAction.LOGIN_ERROR](state, action) {
    return {
      ...state,
      error: action.payload.error,
      errorMessage: action.payload.errorMessage,
    };
  }

  [AuthAction.LOGIN_SUCCESS](state, action) {
    const {username, roles = [], token, topPostsIsActive = false} = action.payload;
    return {
      ...state,
      isAuth: true,
      user: {
        username, roles, token, topPostsIsActive,
      },
    };
  }

  [AuthAction.LOGOUT](state, action) {
    return this.initialState;
  }
}
