import {Image, Text} from "@react-pdf/renderer";
import React from "react";

export const SocialIcon = ({network}) => {
  const images = require.context('../../../../assets/img/socials', true);
  try {
    let img = images('./' + network.toLowerCase() + '.png');
    return (
      <Image
        style={{width: 10, height: 10, verticalAlign: 'middle', 'object-fit': 'cover', marginRight: '5px'}}
        key={network + Math.random()}
        alt={network}
        title={network}
        src={img.default}
      />
    );
  } catch (e) {
    return <Text> </Text>;
  }
};

export const CountryIcon = ({country}) => {
  const images = require.context('../../../../assets/img/countries/png', true);
  try {
    let img = images('./' + country.toLowerCase() + '.png');
    console.log('img', img);
    return (
      <Image
        style={{width: 10, height: 10, verticalAlign: 'middle', 'object-fit': 'cover', marginRight: '5px'}}
        key={country + Math.random()}
        alt={country}
        title={country}
        src={img.default}
      />
    );
  } catch (e) {
    console.log('e', e);
    return <Text> </Text>;
  }
};

export const Icon = ({type}) => {
  const images = require.context('../../../../assets/img/icons', true);
  try {
    let img = images('./' + type.toLowerCase() + '.png');
    return (
      <Image
        style={{width: 10, height: 10, verticalAlign: 'middle', 'object-fit': 'cover', marginRight: '5px'}}
        key={type + Math.random()}
        alt={type}
        title={type}
        src={img.default}
      />
    );
  } catch (e) {
    return <Text> </Text>;
  }
};

export const BadgeIcon = (
  {
    type,
  }) => {
  const images = require.context('../../../../assets/img/badges', true);
  try{
    let img = images('./' + type.toLowerCase() + '.png');
    return <Image
      style={{width: 10, height: 10, verticalAlign: 'middle', 'object-fit': 'cover', marginRight: '5px'}}
      key={type + Math.random()}
      alt={type}
      title={type}
      src={img.default}
    />;
  } catch (e) {
    return <Text> </Text>;
  }
};
