const env = window?._env_ || process?.env;
const api_base_path = env.REACT_APP_API_BASE_PATH;
const api_mm_path = env.REACT_APP_API_MM_PATH;

//const api_base_path = 'https://osintcenter-stage.ddns.net';
//const api_base_path = 'https://upsilon3.ddns.net';
//const api_internal_path = process.env.REACT_APP_API_INTERNAL_PATH;
//const api_base_path = import.meta.env.VITE_API_BASE_PATH;
//console.log('import.meta', import.meta.env);

export const globals = {
  api_base_path,
  api_url: `${api_base_path}/api`,
  api_mm_url: `${api_mm_path}/api`,
  //api_internal_url: `${api_internal_path}/api`,
};