import _ from "lodash";
import {imageByIndex} from "../../helpers/imageByIndex";
import i18n from "i18next";

const getEmotion = function(emos) {
  let emoFactor = 0, emoRes = 'unknown';
  _.each(emos, (emo) => {
    if (emo.confidence > emoFactor) {
      emoFactor = emo.confidence;
      emoRes = emo.type;
    }
  })
  return emoRes;
};
const getPose = function(poses) {
  let poseFactor = 0, poseRes = 'unknown';
  console.log('poses', poses);
  _.each(poses, function (pose, key) {
    if (pose > poseFactor) {
      poseFactor = pose;
      poseRes = key;
    }
  });

  return poseRes;
};

export const transformFaceDetect = apiData => {
  const out = {};

  if (apiData?.length) {
    const [firstFace] = apiData;
    out.faceCoords = firstFace.boundingBox;
    out.faceParams = [];
    out.faceParams.push({title: i18n.t('Age'),value: `${firstFace.ageRange.low} - ${firstFace.ageRange.high}`,confidence: false,});
    out.faceParams.push({title: i18n.t('Beard'), value: firstFace.beard.value ? i18n.t('Yes') : i18n.t('No'), confidence: firstFace.beard.confidence, });
    out.faceParams.push({title: i18n.t('Emotions'), value: getEmotion(firstFace.emotions), confidence: false, });
    out.faceParams.push({title: i18n.t('Glasses'), value: firstFace.eyeglasses.value ? i18n.t('Yes') : i18n.t('No'), confidence: firstFace.eyeglasses.confidence, });
    out.faceParams.push({title: i18n.t('Eyes open'), value: firstFace.eyesOpen.value ? i18n.t('Yes') : i18n.t('No'), confidence: firstFace.eyesOpen.confidence, });
    out.faceParams.push({title: i18n.t('Gender'), value: firstFace.gender.value, confidence: firstFace.gender.confidence, });
    out.faceParams.push({title: i18n.t('Mouth open'), value: firstFace.mouthOpen.value ? i18n.t('Yes') : i18n.t('No'), confidence: firstFace.mouthOpen.confidence, });
    out.faceParams.push({title: i18n.t('Mustache'), value: firstFace.mustache.value ? i18n.t('Yes') : i18n.t('No'), confidence: firstFace.mustache.confidence, });
    out.faceParams.push({title: i18n.t('Pose'), value: getPose(firstFace.pose), confidence: false, });
    out.faceParams.push({title: i18n.t('Smile'), value: firstFace.smile.value ? i18n.t('Yes') : i18n.t('No'), confidence: firstFace.smile.confidence, });
    out.faceParams.push({title: i18n.t('Sunglasses'), value: firstFace.sunglasses.value ? i18n.t('Yes') : i18n.t('No'), confidence: firstFace.sunglasses.confidence, });
  }

  return out;
}

export const transformFaceSearch = apiData => {
  const out = {};

  const {faceMatches = [], searchedFaceBoundingBox} = apiData;

  out.faceCoords = searchedFaceBoundingBox;
  out.faceMatches = [];
  if (faceMatches) {
    faceMatches.forEach(({similarity, face}) => {
      out.faceMatches.push({
        confidence: similarity,
        image: imageByIndex(face.externalImageId),
        target: null,
        faceCoords: face.boundingBox,
        //ref: useRef(),
      });
    });
  }
  return out;
}

export const transformFaceSearchProfiles = apiData => {
  const out = {};

  out.faceCoords = null;
  out.faceMatches = [];
  if (apiData) {
    apiData.filter(img => img?.imageId).forEach(({confidence, imageId, targetId, boundingBox}) => {
      const [module, id] = targetId?.split('-');
      out.faceMatches.push({
        confidence,
        image: imageByIndex(imageId),
        targetId: id,
        module: module === 'profile' ? 'PROFILER' : module?.toUpperCase(),
        faceCoords: boundingBox,
        //ref: useRef(),
      });
    });
  }
  return out;
}

export const transformFaceEnroll = apiData => {
  const [firstFace] = apiData;
  const {face, faceDetail} = firstFace;

  //console.log('apiData', apiData);
  //console.log('firstFace', firstFace);

  const out = {};
  out.faceCoords = face.boundingBox;
  out.faceParams = [];
  out.faceParams.push({title: i18n.t('Age'),value: `${faceDetail.ageRange.low} - ${faceDetail.ageRange.high}`,confidence: false,});
  out.faceParams.push({title: i18n.t('Beard'), value: faceDetail.beard.value ? i18n.t('Yes') : i18n.t('No'), confidence: faceDetail.beard.confidence, });
  out.faceParams.push({title: i18n.t('Emotions'), value: getEmotion(faceDetail.emotions), confidence: false, });
  out.faceParams.push({title: i18n.t('Glasses'), value: faceDetail.eyeglasses.value ? i18n.t('Yes') : i18n.t('No'), confidence: faceDetail.eyeglasses.confidence, });
  out.faceParams.push({title: i18n.t('Eyes open'), value: faceDetail.eyesOpen.value ? i18n.t('Yes') : i18n.t('No'), confidence: faceDetail.eyesOpen.confidence, });
  out.faceParams.push({title: i18n.t('Gender'), value: faceDetail.gender.value, confidence: faceDetail.gender.confidence, });
  out.faceParams.push({title: i18n.t('Mouth open'), value: faceDetail.mouthOpen.value ? i18n.t('Yes') : i18n.t('No'), confidence: faceDetail.mouthOpen.confidence, });
  out.faceParams.push({title: i18n.t('Mustache'), value: faceDetail.mustache.value ? i18n.t('Yes') : i18n.t('No'), confidence: faceDetail.mustache.confidence, });
  out.faceParams.push({title: i18n.t('Pose'), value: getPose(faceDetail.pose), confidence: false, });
  out.faceParams.push({title: i18n.t('Smile'), value: faceDetail.smile.value ? i18n.t('Yes') : i18n.t('No'), confidence: faceDetail.smile.confidence, });
  out.faceParams.push({title: i18n.t('Sunglasses'), value: faceDetail.sunglasses.value ? i18n.t('Yes') : i18n.t('No'), confidence: faceDetail.sunglasses.confidence, });

  return out;
}