import BaseReducer from "../BaseReducer";
import InvestigationAction from "./investigationAction";

export default class InvestigationReducer extends BaseReducer {
  initialState = {
    hierarchy: [],
    chosenItem: null,
    chosenMode: null,
    chosenList: [],
    loading: false,
    targetsLoading: false,
    treeKey: null,
    filters: {},
  };

  /*[InvestigationAction.SET_INVESTIGATION_LIST](state, action) {
    return {
      ...state,
      investigations: action.payload,
      chosenItem: null,
      chosenMode: null,
    }
  }*/

  [InvestigationAction.SET_HIERARCHY](state, action) {
    return {
      ...state,
      hierarchy: [
        ...state.hierarchy,
        ...action.payload
      ],
    }
  }

  [InvestigationAction.CLEAR_HIERARCHY](state, action) {
    return {
      ...state,
      ...this.initialState,
      filters: state.filters,
    }
  }

  [InvestigationAction.SET_INVESTIGATION_LOADING](state, action) {
    return {
      ...state,
      loading: action.payload,
    };
  }

  [InvestigationAction.SET_CHOSEN_MODE](state, action) {
    return {
      ...state,
      chosenMode: action.payload,
    };
  }

  [InvestigationAction.SET_CHOSEN_ITEM](state, action) {
    return {
      ...state,
      chosenItem: action.payload,
    };
  }

  [InvestigationAction.SET_CHOSEN_LIST](state, action) {
    return {
      ...state,
      chosenList: action.payload,
    };
  }

  [InvestigationAction.SET_TREE_KEY](state, action) {
    return {
      ...state,
      treeKey: action.payload,
    };
  }

  [InvestigationAction.CREATE_INVESTIGATION_SUCCESS](state, action) {
    return {
      ...state,
      chosenList: [
        ...state.chosenList,
        action.payload,
      ],
      hierarchy: [
        ...state.hierarchy,
        {
          type: 'investigation',
          id: action.payload.invNumber,
          parent: action.payload.createdBy,
          node: action.payload
        }
      ],
      /*investigations: {
        ...state.investigations,
        [action.payload.createdBy]: {
          ...state.investigations[action.payload.createdBy],
          invList: [
            ...state.investigations[action.payload.createdBy].invList,
            action.payload
          ]
        }
      }*/
    };
  }

  [InvestigationAction.UPDATE_INVESTIGATION_SUCCESS](state, action) {
    return {
      ...state,
      chosenList: state.chosenList.map((inv) => {
        if (inv.invNumber === action.payload.invNumber) {
          return action.payload;
        }
        return inv;
      }),
    };
  }

  [InvestigationAction.DELETE_INVESTIGATION_SUCCESS](state, action) {
    return {
      ...state,
      chosenList: state.chosenList.filter(({ invNumber }) => invNumber !== action.payload),
      /*investigations: {
        ...state.investigations,
        [state.chosenItem.userName]: {
          ...state.investigations[state.chosenItem.userName],
          invList: state.investigations[state.chosenItem.userName].invList.filter(inv => inv.invNumber !== action.payload)
        }
      },*/
      hierarchy: state.hierarchy.filter(({id, type}) => !(type === 'investigation' && id === action.payload))
    };
  }

  [InvestigationAction.CREATE_CASE_SUCCESS](state, action) {
    return {
      ...state,
      chosenList: [
        ...state.chosenList,
        action.payload,
      ],
      hierarchy: [
        ...state.hierarchy,
        {
          type: 'case',
          id: action.payload.caseNumber,
          parent: action.payload.invNumber,
          node: action.payload
        }
      ],
    };
  }

  [InvestigationAction.UPDATE_CASE_SUCCESS](state, action) {
    return {
      ...state,
      chosenList: state.chosenList.map((cas) => {
        if (cas.caseNumber === action.payload.caseNumber) {
          return {
            ...cas,
            caseName: action.payload.caseName,
          };
        }
        return cas;
      }),
    };
  }

  [InvestigationAction.DELETE_CASE_SUCCESS](state, action) {
    return {
      ...state,
      chosenList: state.chosenList.filter(({ caseNumber }) => caseNumber !== action.payload),
      hierarchy: state.hierarchy.filter(({id, type}) => !(type === 'case' && id === action.payload))
    };
  }

  [InvestigationAction.SET_TARGETS_LOADING](state, action) {
    return {
      ...state,
      targetsLoading: action.payload,
    };
  }

  [InvestigationAction.CREATE_PROFILER_TARGET_SUCCESS](state, action) {
    return {
      ...state,
      chosenList: {
        ...state.chosenList,
        content: [
          ...action.payload,
          ...state.chosenList.content
        ]
      }
    };
  }

  [InvestigationAction.DELETE_TARGET_SUCCESS](state, action) {
    return {
      ...state,
      chosenList: {
        ...state.chosenList,
        content: state.chosenList.content.filter(({ id }) => id !== action.payload),
      }
    };
  }

  [InvestigationAction.SET_FILTERS](state, action) {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      }
    };
  }
}
