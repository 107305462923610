import BaseReducer from "../BaseReducer";
import WebintAction from "./webintAction";

export default class WebintReducer extends BaseReducer {
  initialState = {
    task: {},
    groups: [],
    targets: [],
    posts: [],
    topPosts: [],
    postStat: {},
    searchProgress: [],
    images: [],
    videos: [],
    exportLoading: false,
    posts100: [],
    isNotFound: false,
  };

  /* SET ANY PROPERTY TO Webint store*/
  [WebintAction.UPDATE_WEBINT_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    }
  }

}
