import moment from "moment";

export class ExportXlsAudit {
  static exportAudit = (audit = []) => {
    const data = [
      ['ID', 'Username', 'Date', 'Time', 'Type', 'Action', 'Args'],
    ];

    audit.forEach(({id, username, createdTimestamp, actionType, details, args}) => {
      data.push([
        id,
        username,
        moment(createdTimestamp, 'x').format("DD-MM-YYYY"),
        moment(createdTimestamp, 'x').format("HH:ss"),
        actionType,
        details,
        JSON.stringify(args)?.slice(0, 32767),
      ]);
    })

    return data;
  }

}