import BaseReducer from "../BaseReducer";
import AlertsAction from "./alertsAction";

export default class AlertsReducer extends BaseReducer {
  initialState = {
    alerts: {},
    tasks: [],
    criterias: [],
    alertsIsLoading: false,
    unreadAlerts: 0,
  };

  /* SET ANY PROPERTY TO Alerts store*/
  [AlertsAction.UPDATE_ALERTS_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }

  [AlertsAction.SET_LOADING](state, action) {
    return {
      ...state,
      alertsIsLoading: action.payload
    }
  }

  [AlertsAction.SET_CRITERIAS](state, action) {
    return {
      ...state,
      criterias: action.payload,
    };
  }

  [AlertsAction.INCREMENT_UNREAD_ALERTS](state, action) {
    return {
      ...state,
      unreadAlerts: state.unreadAlerts + 1,
    };
  }
}
