const getFromStorage = (storageKey, defaultValue = {}) => {
  let ls = defaultValue;
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(storageKey)) || defaultValue;
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls;
}

const saveToStorage = (storageKey, value) => {
  if (global.localStorage) {
    global.localStorage.setItem(
      storageKey,
      JSON.stringify(value)
    );
  }
}

export {getFromStorage, saveToStorage};