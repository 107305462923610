import BaseReducer from "../BaseReducer";
import AuditAction from "./auditAction";

export default class AuditReducer extends BaseReducer {
  initialState = {
    loading: false,
    audit: [],
    monthlyStat: [],
    pageNumber: 1,
    pageSize: 20,
  };

  /* SET ANY PROPERTY TO Audit store*/
  [AuditAction.UPDATE_AUDIT_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  }
}
