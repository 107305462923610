import React, { memo } from "react";
import { useSelector } from "react-redux";
import { getSettings } from "../../store/settings/settingsSelector";
import * as Styled from "./styled";

const EmptyLayout = memo((props) => {
  const { dataLoading } = useSelector((state) =>
    getSettings(state)
  );

  return (
    <>
      <Styled.Content>
        <div>
          <Styled.Container>{props.children}</Styled.Container>
        </div>
      </Styled.Content>
      {dataLoading && <>Loading ...</>}
    </>
  );
});

export default EmptyLayout;