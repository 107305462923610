import React from 'react';
import {Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {SupportService} from "../../../../support";
import {ImageRender} from "../../Images";
import {withTranslation} from "react-i18next";


const Friends = ({t, data}) => {
  const {friends = []} = data;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Friends")}</Text>


      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
        {
          friends.map((res, idx) => {

            return <View key={idx} style={{width: '150px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
              <View style={{width: '50px', height: '50px', marginRight: '10px'}}>
                {ImageRender(SupportService.getImageForRender(res.avatar, res.socialNetwork))}
                {/*{ res?.avatar?.trim() && <Image style={{width: '50px', height: '50px'}} src={res.avatar}/> }*/}
              </View>
              <View>
                <Text style={{fontSize:'10px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{res.name}</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <Link src={SupportService.getSocialProfileUrl(res.socialNetwork, res.friendProfileId)} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <SocialIcon network={res.socialNetwork} />
                    <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{res.friendProfileId}</Text>
                  </Link>
                </View>
              </View>
            </View>
          }

          )
        }
      </View>
    </View>
  );
};

export default withTranslation()(Friends);