import ActionUtility from "../ActionUtility";

export default class ProxyAction {
  static GET_ALL_PROXY_TEMPLATES = "ProxyAction.GET_ALL_PROXY_TEMPLATES";
  static SET_ALL_PROXY_TEMPLATES = "ProxyAction.SET_ALL_PROXY_TEMPLATES";
  static CREATE_PROXY_TEMPLATE = "ProxyAction.CREATE_PROXY_TEMPLATE";
  static UPDATE_PROXY_STATE = "ProxyAction.UPDATE_PROXY_STATE";
  static DELETE_PROXY = "ProxyAction.DELETE_PROXY";
  static UPDATE_PROXY = "ProxyAction.UPDATE_PROXY";

  static updateProxy(data, callback, id) {
    return ActionUtility.createAction(ProxyAction.UPDATE_PROXY, {
      data,
      callback,
      id
    });
  }

  static deleteProxy(data, callback) {
    return ActionUtility.createAction(ProxyAction.DELETE_PROXY, {
      data,
      callback,
    });
  }

  static updateProxyState(action) {
    return ActionUtility.createAction(ProxyAction.UPDATE_PROXY_STATE, action);
  }

  static setAllTemplates(data) {
    return ActionUtility.createAction(
      ProxyAction.SET_ALL_PROXY_TEMPLATES,
      data
    );
  }

  static getProxyTemplates(filter, callback) {
    return ActionUtility.createAction(ProxyAction.GET_ALL_PROXY_TEMPLATES, {
      filter,
      callback,
    });
  }

  static createProxyTemplate(data, callback) {
    return ActionUtility.createAction(ProxyAction.CREATE_PROXY_TEMPLATE, {
      data,
      callback,
    });
  }
}
