import React, {useCallback, useEffect, useState} from "react";
import * as Styled from "./styled";
import Icon from "../../../../components/UI/Icons/Icon";
import {useDispatch, useSelector} from "react-redux";
import UsersAction from "../../../../store/users/usersAction";
import {selectUsersQuotas} from "../../../../store/users/usersSelector";
import config from "../../../../config/config";
import {withTranslation} from "react-i18next";

const MainQuota = withTranslation()(({t}) => {
  const dispatch = useDispatch();

  //state
  const quotas = useSelector(selectUsersQuotas);

  //callbacks

  //effects

  useEffect(() => {
    //console.log('qq', quotas?.quotaProfiler)
  }, [quotas])

  //handlers



  return (
      <Styled.HeaderQuotaContainer to={{ pathname: config.irbisPackagesPage }} target={'_blank'}>
        {
          quotas?.quotaProfiler && <Styled.QuotaSection>
            <Icon
              type={'quota_cases'}
              text={
                (quotas?.quotaProfiler?.cases?.maxQuota > 0)
                  ? quotas?.quotaProfiler?.cases?.maxQuota * 1
                  : '0'
              }
              title={t('Number of cases')}
              styleText={{color: '#FFF', fontSize: '20px', fontWeight: 700}}
              style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}
            />
            <Icon
              type={'quota_lookups'}
              text={
                (quotas?.quotaProfiler?.lookup?.maxQuota > 0)
                  ? quotas?.quotaProfiler?.lookup?.maxQuota * 1
                  : '0'
              }
              title={t('Number of profiles by phone numbers')}
              styleText={{color: '#FFF', fontSize: '20px', fontWeight: 700}}
              style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}
            />
            <Icon
              type={'quota_sn'}
              text={
                (quotas?.quotaProfiler?.crawler?.maxQuota > 0)
                  ? quotas?.quotaProfiler?.crawler?.maxQuota * 1
                  : '0'
              }
              title={t('Number of profiles by social networks')}
              styleText={{color: '#FFF', fontSize: '20px', fontWeight: 700}}
              style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}
            />
          </Styled.QuotaSection>
        }

        {
          quotas?.quotaWebint && <Styled.QuotaSection>
            <Icon
              type={'quota_webint_cases'}
              text={
                (quotas?.quotaWebint?.cases?.maxQuota > 0)
                  ? quotas?.quotaWebint?.cases?.maxQuota * 1
                  : '0'
              }
              title={t('Number of cases')}
              styleText={{color: '#FFF', fontSize: '20px', fontWeight: 700}}
              style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}
            />
            <Icon
              type={'quota_webint'}
              text={
                (quotas?.quotaWebint?.crawler?.maxQuota > 0)
                  ? quotas?.quotaWebint?.crawler?.maxQuota * 1
                  : '0'
              }
              title={t('Number of tasks')}
              styleText={{color: '#FFF', fontSize: '20px', fontWeight: 700}}
              style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}
            />
            <Icon
              type={'quota_monitoring'}
              text={
                (quotas?.quotaWebint?.monitoring?.maxQuota > 0)
                  ? quotas?.quotaWebint?.monitoring?.maxQuota * 1
                  : '0'
              }
              title={t('Number of search requests')}
              styleText={{color: '#FFF', fontSize: '20px', fontWeight: 700}}
              style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}
            />
          </Styled.QuotaSection>
        }

      </Styled.HeaderQuotaContainer>
  )
})

export default MainQuota;
