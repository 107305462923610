import React from 'react';
import {Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {SupportService} from "../../../../support";
import {withTranslation} from "react-i18next";

const WebResults = ({t, data}) => {
  const {webResults = []} = data;

  return (
    <View>
      <Text style={{marginBottom: '20px'}}>{t("Web results")}</Text>


      {
        webResults.map((web, idx) =>
          <View key={idx} style={{paddingBottom: '20px'}}>
            <View>
              <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px'}}>
                <SocialIcon network={web.searchEngine} />
                <Text style={{fontSize: '12px', color: '#b1b1b1', fontFamily: 'pdfFont',}}><Link src={web.resultLink}>{web.resultTitle}</Link></Text>
              </View>
              <Text style={{fontSize: '12px', fontFamily: 'pdfFont',}}>{SupportService.stripHtmlTags(web.resultPreview)}</Text>
            </View>
          </View>
        )
      }
    </View>
  );
};

export default withTranslation()(WebResults);