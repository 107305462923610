import {
  Document,
  Paragraph,
  TextRun,
  ExternalHyperlink
} from "docx";
import i18n from "i18next";
import moment from "moment";
import {SupportService} from "../../../support";
import DocxService from "../DocxService";

class ExportDocxPosts100 {
  static dimensions = {};

  static renderTop10Posts = (posts = []) => {
    const postsContent = [];
    console.log('posts', posts);

    posts.forEach(post => {
      let postText = post.text;
      if (post.isSharedPost) {
        postText = `${i18n.t("Shared post")}: ${post.sharedText}`
      }
      if (post.originalPostUserImage) {
        postsContent.push(new Paragraph({
          children: [
            DocxService.createImage(SupportService.getImageForRender(post.originalPostUserImage, post?.socialNetwork), {width: 50, height: 50}, false)
          ]
        }));
      }

      postsContent.push(new Paragraph({
        text: moment(post.timeStamp).format('YYYY-MM-DD HH:mm:ss'),
        italics: true,
      }));
      postsContent.push(new Paragraph({
        text: `${post?.originalPostUserName || ' '} / ${post.socialNetwork}`,
      }));
      postsContent.push(new Paragraph({
        text: `${i18n.t("Resonance score")}: ${post.resonanceScore}`,
      }));
      postsContent.push(new Paragraph({
        text: SupportService.stripHtmlTags(postText)
      }));
      postsContent.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: post.postUrl,
                  style: "Hyperlink",
                }),
              ],
              link: post.postUrl,
            }),
          ]
        })
      );

      postsContent.push(DocxService.createEmptyRow());
      if (post?.comments) {
        const rows = [
          {header: i18n.t('Comments content'), value: i18n.t(`Resonance score`)},
          ...post?.comments?.map(({referenceContent, resonanceScore}) => {
            return {header: referenceContent, value: `${resonanceScore}`};
          })
        ];
        postsContent.push(DocxService.createTable(rows));
      }

      /*postsContent.push(
        new Paragraph({
          children: post?.comments?.map(({referenceContent, resonanceScore}) => {
            return DocxService.createColumns(
              [
                {text: referenceContent, bold: true},
                {text: `Resonance score: ${resonanceScore}`},
              ], 2000
            )
          })
        })
      );*/
      postsContent.push(DocxService.createEmptyRow());
      postsContent.push(DocxService.createEmptyRow());
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Top 100 posts')),
        ...postsContent,
      ],
    };
  };



  static renderDoc(data) {
    return new Document({
      sections: [
        this.renderTop10Posts(data),
      ],
    });
  }
}

export default ExportDocxPosts100;