import i18n from "i18next";
import moment from "moment";
import * as FileSaver from "file-saver"

class ExportCsv {
  constructor(data) {
    this.data = [];
    if (Array.isArray(data)) {
      this.rawData = [...data];
    } else {
      this.rawData = {...data};
    }
    this.fileName = "PROFILES_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareAvatarAudit() {
    this.data.push([
      i18n.t('Time'),
      i18n.t('Social Network'),
      i18n.t('Profile'),
      i18n.t('Username'),
      i18n.t('Status'),
    ])
    this.rawData.forEach(row => {
      this.data.push([
        moment(row.timestamp, 'x').format("DD-MM-YYYY"),
        row.domain,
        row.login,
        row.username,
        row.auditType
      ])
    })
    //console.log('this.data', this.data);
    this.fileName = "AVATAR_LOG_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  prepareAvatars() {
    this.rawData.forEach(row => {
      row?.avatars?.forEach(avatar => {
        this.data.push([
          avatar.username,
          avatar.password,
          avatar.domain,
        ])
      })
    })
    console.log('this.data', this.data);
    this.fileName = "AVATARS_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    return this;
  }
  export() {
    const universalBOM = "\uFEFF";

    const csvData = universalBOM + this.data.map(e => e.join(";")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, `${this.fileName}.csv`);

    /*let csvContent = "data:text/csv;charset=utf-8," + universalBOM + this.data.map(e => e.join(";")).join("\n");
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${this.fileName}.csv`);
    document.body.appendChild(link);
    link.click();*/

    return this;
  }
}

export default ExportCsv