import ActionUtility from "../ActionUtility";

export default class AmmAction {
  static UPDATE_AMM_STATE = "AmmAction.UPDATE_AMM_STATE";
  static UPDATE_AMM_PROFILES_STATE = "AmmAction.UPDATE_AMM_PROFILES_STATE";
  static TOGGLE_FILTERS = "AmmAction.SET_FILTERS";
  static UPDATE_MANAGEMENT_FILTERS = "AmmAction.UPDATE_MANAGEMENT_FILTERS";

  static GET_META_AVATARS = "AmmAction.GET_META_AVATARS";
  static SET_META_AVATARS = "AmmAction.SET_META_AVATARS";

  static CREATE_META_AVATAR = "AmmAction.CREATE_META_AVATAR";
  static UPDATE_META_AVATAR = "AmmAction.UPDATE_META_AVATAR";
  static DELETE_META_AVATAR = "AmmAction.DELETE_META_AVATAR";
  static DELETE_META_AVATAR_SUCCESS = "AmmAction.DELETE_META_AVATAR_SUCCESS";
  static GET_META_AVATAR = "AmmAction.GET_META_AVATAR";
  static GET_META_AVATAR_STAT = "AmmAction.GET_META_AVATAR_STAT"

  static CREATE_PROFILE = "AmmAction.CREATE_PROFILE";
  static DELETE_PROFILE = "AmmAction.DELETE_PROFILE";

  static SAVE_DEFAULT_PROXY = "AmmAction.SAVE_DEFAULT_PROXY";

  static UPDATE_META_AVATAR_INFO = "AmmAction.UPDATE_META_AVATAR_INFO";

  static UPDATE_AVATAR_STATUS = "AmmAction.UPDATE_AVATAR_STATUS";
  static UPDATE_AVATAR_QUARANTINE = "AmmAction.UPDATE_AVATAR_QUARANTINE";
  static UPDATE_AVATAR_LOCAL_STATUS = "AmmAction.UPDATE_AVATAR_LOCAL_STATUS";
  static UPDATE_AVATAR_LOCAL_QUARANTINE = "AmmAction.UPDATE_AVATAR_LOCAL_QUARANTINE";
  static GET_AUDIT_LOG = "AmmAction.GET_AUDIT_LOG";
  static EXPORT_AUDIT_LOG = "AmmAction.EXPORT_AUDIT_LOG";
  static GET_APA_LOGIN = "AmmAction.GET_APA_LOGIN";
  static GET_APA_POST = "AmmAction.GET_APA_POST";

  static EXPORT_ACTIVITIES = "AmmAction.EXPORT_ACTIVITIES";
  static EXPORT_BLOCKED = "AmmAction.EXPORT_BLOCKED";
  static EXPORT_AVATARS = "AmmAction.EXPORT_AVATARS";

  static RELEASE_AVATARS = "AmmAction.RELEASE_AVATARS";


static getMetaAvatarStat(id){
  return ActionUtility.createAction(AmmAction.GET_META_AVATAR_STAT, id)
}

  static updateAvatarQuarantine(data) {
    return ActionUtility.createAction(AmmAction.UPDATE_AVATAR_QUARANTINE, {
      data,
    });
  }

  static updateAvatarQuarantineLocal(data) {
    return ActionUtility.createAction(AmmAction.UPDATE_AVATAR_LOCAL_QUARANTINE, {
      data,
    });
  }

  static updateAvatarStatusLocal(data) {
    return ActionUtility.createAction(AmmAction.UPDATE_AVATAR_LOCAL_STATUS, {
      data,
    });
  }

  static updateAvatarStatus(data, callback) {
    return ActionUtility.createAction(AmmAction.UPDATE_AVATAR_STATUS, {
      data,
      callback,
    });
  }

  static updateProfileInfo(data, refreshState = false, callback) {
    return ActionUtility.createAction(AmmAction.UPDATE_META_AVATAR_INFO, {
      data,
      refreshState,
      callback,
    });
  }

  static updateAmmState(action) {
    return ActionUtility.createAction(AmmAction.UPDATE_AMM_STATE, action);
  }

  static updateAmmProfilesState(id, info) {
    return ActionUtility.createAction(AmmAction.UPDATE_AMM_PROFILES_STATE, {id, info});
  }

  static updateManagementFilters(action) {
    return ActionUtility.createAction(
      AmmAction.UPDATE_MANAGEMENT_FILTERS,
      action
    );
  }

  static toggleFilters() {
    return ActionUtility.createAction(AmmAction.TOGGLE_FILTERS);
  }

  /* List */
  static getMetaAvatarsList(filterData, callback) {
    return ActionUtility.createAction(AmmAction.GET_META_AVATARS, {
      filterData,
      callback,
    });
  }
  static setMetaAvatarsList(list) {
    return ActionUtility.createAction(AmmAction.SET_META_AVATARS, list);
  }

  // Create Meta Avatar
  static createMetaAvatar(data, callback) {
    return ActionUtility.createAction(AmmAction.CREATE_META_AVATAR, {
      data,
      callback,
    });
  }

  // Update Meta Avatar
  static updateMetaAvatar(id, data, callback) {
    return ActionUtility.createAction(AmmAction.UPDATE_META_AVATAR, {
      id,
      data,
      callback,
    });
  }

  // Delete Meta Avatar
  static deleteMetaAvatar(id, name, callback) {
    return ActionUtility.createAction(AmmAction.DELETE_META_AVATAR, {
      name,
      id,
      callback,
    });
  }
  static deleteMetaAvatarSuccess(id) {
    return ActionUtility.createAction(AmmAction.DELETE_META_AVATAR_SUCCESS, id);
  }

  // Get Meta Avatar
  static getMetaAvatar(id) {
    return ActionUtility.createAction(AmmAction.GET_META_AVATAR, id);
  }

  // Create Avatar profile
  static createProfile(id, postData, callback) {
    return ActionUtility.createAction(AmmAction.CREATE_PROFILE, {
      id,
      postData,
      callback,
    });
  }

  // Delete Avatar profile
  static deleteProfile(id, callback, messageCallback) {
    return ActionUtility.createAction(AmmAction.DELETE_PROFILE, {
      id,
      callback,
      messageCallback
    });
  }

  // Save default proxy
  static saveDefaultProxy(id, proxyData, callback) {
    return ActionUtility.createAction(AmmAction.SAVE_DEFAULT_PROXY, {
      id,
      proxyData,
      callback,
    });
  }

  // Get Avatars audit log
  static getAuditLog(from = null, to = null, callback) {
    return ActionUtility.createAction(AmmAction.GET_AUDIT_LOG, {from, to, callback});
  }

  //Export avatars audit log
  static exportAuditLog(type, callback) {
    return ActionUtility.createAction(AmmAction.EXPORT_AUDIT_LOG, {type, callback});
  }

  // Get Avatars audit log
  static getApaLogin(url, avatar, currentUser, id, callback) {
    return ActionUtility.createAction(AmmAction.GET_APA_LOGIN, {url, avatar, currentUser, id, callback});
  }

  // Go to post via APA
  static getApaPost(url, callback) {
    return ActionUtility.createAction(AmmAction.GET_APA_POST, {url, callback});
  }

  //Export avatars activities
  static exportActivities(type, avatarId, callback) {
    return ActionUtility.createAction(AmmAction.EXPORT_ACTIVITIES, {type, avatarId, callback});
  }

  //Export avatars blocks
  static exportBlocked(type, avatarId, callback) {
    return ActionUtility.createAction(AmmAction.EXPORT_BLOCKED, {type, avatarId, callback});
  }

  //Export avatars list
  static exportAvatars(type, callback) {
    return ActionUtility.createAction(AmmAction.EXPORT_AVATARS, {type, callback});
  }

  //Release all avatars
  static releaseAvatars(callback) {
    return ActionUtility.createAction(AmmAction.RELEASE_AVATARS, callback);
  }
}
