import {put, delay, takeEvery, select, call, all} from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import DotsAction from "../dots/dotsAction";
import {mergeNodes, transformNodesData, transformSourceInfo} from "./dotsTransformer";
import {apiCaseService, apiProfileService} from "../../config/api";
import {getFromStorage, saveToStorage} from "../../helpers/gridSaver";
import {selectDotsNodes} from "./dotsSelector";

function* getNodesSaga(action) {
  const caseId = action.payload;
  try {
    yield put(DotsAction.updateDotsState(({ nodesLoading: true})));
    const {data} = yield apiCaseService.getCaseProfiles(caseId, 0, 200);
    const {data: fullData} = yield apiCaseService.getFullProfiles(caseId, {page: 0, size: 200});

    //const targetList = data?.content;
    const targetList = fullData?.content?.map(({profileDTO}) => profileDTO);
    const targetLookups = fullData?.content;

    console.log('fullData', fullData);
    console.log('targetList', targetList);

    /*const callEffects = targetList.map(({id}) => {
      return call(function* () {
        const {data: lookups} = yield apiProfileService.getFullTreeProfile(id);
        return {...lookups};
      });
    })
    const targetLookups = yield all(callEffects);
    console.log('lookups', targetLookups);*/

    const callEffects = targetList.map(({id}) => {
      return call(function* () {
        const {data: friendsData} = yield apiProfileService.getAdditionalFriends(id);
        const {data: photosData} = yield yield apiProfileService.getPhotos(id);
        const sourceInfo = {targetId: id, friends: friendsData?.content, photos: photosData};
        return {...sourceInfo};
      });
    })
    const targetSources = yield all(callEffects);
    console.log('sources', targetSources);

    const searchItemsEffects = targetList.map(({id}) => {
      return call(function* () {
        const {data: searchItems} = yield apiProfileService.profileGetSearchProgress(id);
        searchItems.id = id;
        return {...searchItems};
      });
    })
    const targetSearchItems = yield all(searchItemsEffects);
    console.log('searchProgress', targetSearchItems);

    const preparedNodes = yield transformNodesData({targetList, targetLookups, targetSearchItems, targetSources});

    yield put(DotsAction.updateDotsState({nodes: preparedNodes}))
    yield put(DotsAction.updateDotsState(({ nodesLoading: false})));

  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}


function* getSourceInfoSaga(action) {
  const {targetId, lookupRequest, callback} = action.payload;
  try {
    const {data: friendsData} = yield apiProfileService.getAdditionalFriends(targetId);
    const {data: photosData} = yield yield apiProfileService.getPhotos(targetId);

    const transData = transformSourceInfo(lookupRequest, {
      friends: friendsData,
      photos: photosData,
    });
    callback(transData);

  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getTargetNodesSaga(action) {
  const target = action.payload;
  const existingNodes = yield select(selectDotsNodes);

  try {
    const {data: lookups} = yield apiProfileService.getFullTreeProfile(target.id);
    const {data: lookupsData} = yield apiCaseService.getFullProfiles(1, {page: 0, size: 1, profileIds: [target.id]});

    const {data: searchItems} = yield apiProfileService.profileGetSearchProgress(target.id);

    console.log('lookups SAGA', lookups);
    console.log('search items SAGA', searchItems);
    //const preparedNodes = yield transformNodesData([target], [lookups], [{...searchItems, id: target.id}]);

    const preparedNodes = yield transformNodesData([target], lookupsData?.content, [{...searchItems, id: target.id}]);
    console.log('prepared Nodes SAGA', existingNodes, preparedNodes);
    const mergedNodes = mergeNodes(existingNodes, preparedNodes);
    yield put(DotsAction.updateDotsState({nodes: mergedNodes}))

  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getBrowserModeSaga(action) {
  const defaultMode = 'regular'
  const storageItem = yield getFromStorage('browserMode', defaultMode);
  if (storageItem) {
    yield put(DotsAction.updateDotsState({browserMode: storageItem}));
  } else {
    yield put(DotsAction.updateDotsState({browserMode: defaultMode}));
    saveToStorage('browserMode', defaultMode);
  }
}

function* setBrowserModeSaga(action) {
  yield put(DotsAction.updateDotsState({browserMode: action.payload}));
  saveToStorage('browserMode', action.payload);
}


export function* watchDots() {
  yield takeEvery(DotsAction.GET_NODES, getNodesSaga);
  yield takeEvery(DotsAction.GET_SOURCE_INFO, getSourceInfoSaga);
  yield takeEvery(DotsAction.GET_TARGET_NODES, getTargetNodesSaga);
  yield takeEvery(DotsAction.GET_BROWSER_MODE, getBrowserModeSaga);
  yield takeEvery(DotsAction.SET_BROWSER_MODE, setBrowserModeSaga);
}
