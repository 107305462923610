import React from "react";
import TreeIcon from "../../components/UI/Icons/TreeIcon";

export function prepareUsersTree(userList = {}){
  const tree = [];

  Object.entries(userList).forEach(entry => {
    const [user, userTree] = entry;
    console.log('entry', user, userTree);
    const {invList = [], userInfo = {}} = userTree;

    const investigations = [];
    invList.forEach(inv => {
      investigations.push({
        title: inv.invName,
        key: `investigation-${inv.invNumber}`,
        type: 'investigation',
        obj: inv,
      })
    });

    const userObj = {
      title: user,
      key: `user-${user}`,
      type: 'user',
      icon: <></>,
      loaded: true,
      obj: userInfo
    };

    if (investigations.length) {
      userObj.children = investigations;
    }

    tree.push(userObj);
  })


  return tree;
}

export function prepareUsersHierarchy(userList = {}) {
  const hierarchy = [];

  Object.entries(userList).forEach(entry => {
    const [user, userTree] = entry;
    const {invList = [], userInfo = {}} = userTree;

    hierarchy.push({
      id: user,
      type: 'user',
      parent: null,
      node: {...userInfo, keysArray: [`user-${user}`]},
    })

    invList.forEach(inv => {
      hierarchy.push({
        id: inv.invNumber,
        type: 'investigation',
        parent: user,
        node: {...inv, keysArray: [`user-${user}`, `investigation-${inv.invNumber}`]}
      })
    });
  })

  return hierarchy;
}

export function prepareCasesHierarchy(invId, casesList = [], currentHierarchy = [], modules = []) {
  const hierarchy = [];
  const {node: {keysArray = []}} = currentHierarchy.find(hi => hi.id === invId && hi.type === 'investigation');


  casesList.forEach(entry => {
    entry.id = entry.caseNumber;
    entry.invId = invId;

    if (modules.includes(entry?.caseType)) {
      hierarchy.push({
        id: entry.caseNumber,
        type: 'case',
        parent: invId,
        node: { ...entry, keysArray: [...keysArray, `caseType-${invId}-${entry.caseType}`, `case-${entry.caseNumber}`]},
      });
    }
  })

  return hierarchy;
}

export function prepareTreeFromHierarchy(nodesList = [], modules = [], options = {}){
  const tree = [];
  const customName = options?.customName;
  const theme = options?.theme;

  nodesList.filter(level => level.type === 'user').forEach(user => {
    const userObj = {
      className: 'user-node',
      title: customName || user.id,
      key: `user-${user.id}`,
      type: 'user',
      icon: <></>,
      loaded: true,
      obj: { ...user.node, keysArray: [`user-${user.id}`]},
      children: []
    };
    nodesList.filter(level => level.type === 'investigation' && level.parent === user.id).forEach(inv => {
      const invObj = {
        title: inv.node.invName,
        key: `investigation-${inv.node.invNumber}`,
        type: 'investigation',
        //icon: ({ expanded }) => <TreeIcon type={'tree_investigation'} expanded={expanded} />,
        icon: (props) => <TreeIcon {...props} type={theme?.tree_icon_investigation || 'investigation'} />,
        obj: {...inv.node, keysArray: [...userObj.obj.keysArray, `investigation-${inv.node.invNumber}`],},
        loaded: false,
        children: [
          /*{
            title: 'PROFILER',
            key: `caseType-${inv.id}-PROFILER`,
            type: 'caseType',
            icon: (props) => <TreeIcon {...props} type={'profiler'} />,
            obj: {type: 'PROFILER', invId: inv.id, keysArray: [...userObj.obj.keysArray, `investigation-${inv.node.invNumber}`, `caseType-${inv.id}-PROFILER`],},
            loaded: true,
            isLeaf: true,
          },
          {
            title: 'WEBINT',
            key: `caseType-${inv.id}-WEBINT`,
            type: 'caseType',
            icon: (props) => <TreeIcon {...props} type={'webint'} />,
            obj: {type: 'WEBINT', invId: inv.id, keysArray: [...userObj.obj.keysArray, `investigation-${inv.node.invNumber}`, `caseType-${inv.id}-PROFILER`],},
            loaded: true,
            isLeaf: true
          },*/
        ]
      }
      if (modules.includes('PROFILER')) {
        invObj.children.push({
          title: 'PROFILER',
          key: `caseType-${inv.id}-PROFILER`,
          type: 'caseType',
          icon: (props) => <TreeIcon {...props} type={theme?.tree_icon_profiler || 'profiler'} />,
          obj: {type: 'PROFILER', invId: inv.id, keysArray: [...userObj.obj.keysArray, `investigation-${inv.node.invNumber}`, `caseType-${inv.id}-PROFILER`],},
          loaded: true,
          isLeaf: true,
        });
      }
      if (modules.includes('WEBINT')) {
        invObj.children.push({
          title: 'WEBINT',
          key: `caseType-${inv.id}-WEBINT`,
          type: 'caseType',
          icon: (props) => <TreeIcon {...props} type={theme?.tree_icon_webint || 'webint'} />,
          obj: {type: 'WEBINT', invId: inv.id, keysArray: [...userObj.obj.keysArray, `investigation-${inv.node.invNumber}`, `caseType-${inv.id}-PROFILER`],},
          loaded: true,
          isLeaf: true
        });
      }

      const caseItems = nodesList.filter(level => level.type === 'case' && level.parent === inv.id);
      if (caseItems.length) {
        caseItems.forEach(caseItem => {
          const caseType = invObj.children.find(({key}) => key === `caseType-${inv.id}-${caseItem.node.caseType}`);
          //console.log('caseType', caseType, invObj.children, `caseType-${inv.id}-${caseItem.node.caseType}`)
          if (caseType) {
            caseItem.invId = inv.id;
            const caseNode = {
              title: caseItem.node.caseName,
              key: `case-${caseItem.id}`,
              type: 'case',
              obj: {...caseItem, keysArray: [...caseType.obj.keysArray, `case-${caseItem.caseNumber}`],},
              icon: (props) => <TreeIcon {...props} type={theme?.tree_icon_case || 'case'} />,
              loaded: true,
              isLeaf: true,
            };
            caseType.isLeaf = false;
            if (caseType.children) {
              caseType.children.push(caseNode);
            } else {
              caseType.children = [caseNode];
            }
          }
        })
      }
      userObj.children.push(invObj)
    })
    tree.push(userObj);
  });

  return tree;
}

export function prepareCreateProfiler(formValues = {}){
  //REFERENCE JSON
  /*{
    items: [
      {
        "lookupPhoneNumber":"",
        "lookupEmails": ["email"],
        "lookupNames": ["lookupNames"],
        "socialProfiles": [
          {
            "profileId": "",
            "socialNetwork": "",
            "avatarId": ""
          }],
        "lookupFilters": [
          {
            "type":"",
            "socialNetwork":"",
            "value":""
          }],
        "lookupAvatars": [
          {
            "socialNetwork":"",
            "avatarId": ""
          }]
      }
    ]
  }*/

  const formData = new FormData();

  const items = [];
  let useAvatarsPool = true;


  if (formValues.profilerPhonesList) {

    // ----- multiple phones

    formValues.profilerPhonesList.split("\n").forEach(phone => {
      const item = {
        lookupAvatars: []
      };
      if (phone?.trim()) {
        console.log('PHONE', phone);
        item.lookupPhoneNumber = phone.trim();

        if (formValues.avatars?.length) {
          formValues.avatars.forEach(ava => {
            item.lookupAvatars.push({
              socialNetwork: ava.sn,
              avatarId: ava.id,
            })
          })
        }
        items.push(item);
      }
    })
  } else {

    // ----- not multiple

    const item = {
      lookupAvatars: []
    };

    //BY PHONE
    if (formValues.profilerPhone) {
      item.lookupPhoneNumber = formValues.profilerPhone;
    }

    //BY NAMES
    if (formValues.profilerName) {
      item.lookupNames = item?.lookupNames || [];
      item.lookupNames.push(formValues.profilerName);
    }

    //BY EMAILS
    if (formValues.profilerEmail) {
      item.lookupEmails = item?.lookupEmails || [];
      item.lookupEmails.push(formValues.profilerEmail);
    }


    //BY SOCIAL NETWORK
    if (formValues.socialNetworks?.length) {
      item.socialProfiles = item?.socialProfiles || [];
      formValues.socialNetworks.forEach(({name, sid, avatarId, prefix = null}) => {
        const profileId = sid === 'MY_MAIL_RU' ? (prefix + '' + name) : name;
        item.socialProfiles.push({
          profileId: profileId.trim(),
          socialNetwork: sid,
          avatarId
        })
      })
      useAvatarsPool = false;
    }
    if (formValues.socialNetwork?.name) {
      item.socialProfiles = item?.socialProfiles || [];
      item.socialProfiles.push({
        profileId: formValues.socialNetwork?.sid === 'MY_MAIL_RU'
          ? (formValues.socialNetwork?.prefix + '' + formValues.socialNetwork?.name.trim())
          : formValues.socialNetwork?.name.trim(),
        socialNetwork: formValues.socialNetwork?.sid,
        avatarId: formValues.socialNetwork?.avatarId
      })
      useAvatarsPool = false;
    }

    if (formValues.avatars?.length && useAvatarsPool) {
      formValues.avatars.forEach(ava => {
        item.lookupAvatars.push({
          socialNetwork: ava.sn,
          avatarId: ava.id,
        })
      })
    }
    items.push(item);
  }




  //BY IMAGE
  if (formValues.files?.length) {
    const [image] = formValues.files;
    if (image?.originFileObj) {
      formData.append('image', image.originFileObj);
    }
  }



  formData.append("data", JSON.stringify({items}));
  //console.log('formData.data', JSON.stringify({items}));

  //return {items};
  return formData;
}

export function prepareCreateWebint(formValues = {}){
  //REFERENCE JSON
  /*{
    "isMonitoring": true,
  "collectLikes": true,
  "collectComments": true,
  "imageId": "string",
  "name": "string",
  "avatars": [
    "string"
  ],
  "keywords": [
    {
      "name": "string",
      "keywordJoin": "OR"
    }
  ],
  "groups": [
    {
      "groupId": "string",
      "socialNetwork": "FB"
    }
  ],
  "profile": {
    "username": "string",
    "socialNetwork": "FB",
    "socialProfileId": "string",
    "imageId": "string"
  },
  "type": "GROUP_CHANNEL",
  "schedulerSetting": {
    "start": 0,
    "end": 0,
    "collectPeriod": "H1"
  }
  }*/

  //INPUT
  /*avatars: Array(1)
  0:
  id: "5f5494a7e4b0e8b223a53b70"
  name: "Ivan.1957.Kurilenko@gmail.com"
  sn: "FB"
    [[Prototype]]: Object
  length: 1
    [[Prototype]]: Array(0)
  collectLikes: true
  collectPeriod: "5H"
  groupName: "fff"
  groupSocialNetwork: "FB"
  groups: []
  images: [{…}]
  keyword: {name: '', flag: 'OR'}
  keywords: []
  monitoring: false
  monitoringEnd: "1644592108272"
  monitoringStart: "1643728108272"
  socialNetwork: {sid: 'facebook'}
  taskName: "qqqq"
  taskType: "groups"
  userImages: []
  userName: null
  userProfile: {name: '', sid: 'facebook'}*/

  const formData = new FormData();
  const postData = {
    isMonitoring: formValues.monitoring,
    collectLikes: formValues.collectLikes,
    collectComments: formValues.collectComments,
    name: formValues.taskName,
    avatars: formValues.avatars.map(({id}) => id),
    imageId: formValues.imageId,
  };

  //Group
  if (formValues.taskType === 'groups' || formValues.taskType === 'keywords_groups') {
    if (formValues.groupName && formValues.groupSocialNetwork) {
      postData.groups = [{
        groupId: formValues.groupName,
        socialNetwork: formValues.groupSocialNetwork,
      }]
    } else if (formValues.groups?.length) {
      postData.groups = [];
      formValues.groups.map(({sid, name}) => [
        postData.groups.push({
          groupId: name,
          socialNetwork: sid,
        })
      ])
    }
  }

  //keywords
  if (formValues.taskType === 'keywords' || formValues.taskType === 'keywords_groups') {
    postData.keywords = formValues.keywords.map(kw => {
      return {
        name: kw.name,
        keywordJoin: kw.flag,
      }
    })
  }

  //posts
  if (formValues.taskType === 'posts') {
    postData.profile = {
      username: formValues.userName,
      socialNetwork: formValues?.userProfile?.sid,
      //socialProfileId: formValues.userProfile.name,
      imageId: formValues.userProfile?.imageId,
    };
    if (formValues.userProfile?.name) {
      postData.profile.socialNetwork = formValues.userProfile?.sid;
      postData.profile.socialProfileId = formValues.userProfile?.name;
      if (formValues.userProfile?.sid === 'MY_MAIL_RU' && formValues.userProfile?.prefix) {
        postData.profile.socialProfileId = formValues.userProfile?.prefix + postData.profile.socialProfileId;
      }
    }
  }

  //scheduling
  if (formValues.monitoring) {
    postData.schedulerSetting = {
      start: formValues.monitoringStart,
      end: formValues.monitoringEnd,
      //collectPeriod: formValues.collectPeriod,
      collectPeriodInHour: formValues.collectPeriod,
    }
  }


  //ADD IMAGE and userImage
  if (formValues.images?.length) {
    const [image] = formValues.images;
    if (image?.originFileObj) {
      formData.append('image', image.originFileObj);
    }
  }
  if (formValues.userImages?.length) {
    const [image] = formValues.userImages;
    if (image?.originFileObj) {
      formData.append('userImage', image.originFileObj);
    }
  }

  console.log('oistData', postData);

  formData.append("data", JSON.stringify({...postData}));

  return formData;
}

export const prepareWebintTargets = ({content: targets, ...rest}) => {
  console.log('targets', targets);
  targets.forEach(target => {
    target.photo = target.imageId;
  })
  return {content: targets, ...rest};
}

export const prepareInputLinkAnalysis = (item, mode) => {
  const out = {};

  //@TODO: remove this when core is fixed
  /*return {
    "items": "targets",
    "itemsIds": [
      "69","71"
    ]
  }*/

  // eslint-disable-next-line
  switch (mode) {
    case 'investigation':
      return {
        items: 'investigations',
        itemsIds: [item.invNumber]
      }
    case 'case':
      return {
        items: 'cases',
        itemsIds: [item.id]
      }
  }
  return out;
}