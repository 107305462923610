import styled from "styled-components";
import { Avatar } from "antd";

export const HeaderAvatar = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: flex-end;
`;
export const HeaderUserName = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.theme.header_user};
  margin: 0 16px;
`;
export const UserAvatar = styled(Avatar)`
  width: 36px;
  height: 36px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: ${props => props.theme.header_user_avatar};
  background: ${props => props.theme.header_user_avatar_bg};
`;
export const UserMenuTitle = styled.div`
  margin: 10px;
`;
export const SwitchBlock = styled.div`
  margin: 5px 0 15px 23px;
  display: flex;
  width: 85%;
  align-items: center;
`;

export const SwitchName = styled.span`
  font-family: "Roboto", sans-serif;
  margin-left: 5px;
  font-size: 12px;
  white-space: nowrap;
`;

export const UserMenuContainer = styled.div`
  .ant-menu-light .ant-menu-item:hover {
    background: #d6dce4;
  }
`;
