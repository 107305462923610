import React from 'react';
import {Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {SupportService} from "../../../../support";
import {withTranslation} from "react-i18next";

const Profiles = ({t, data}) => {

  const { searchProgress } = data;
  const { socialCrawlers } = searchProgress;

  return (
    <View>
      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}}>
        <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{t("Profiles")}:</Text>
        </View>

        <View>
          {
            socialCrawlers?.map(({socialNetwork, socialId}, idx) =>
              <View key={idx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px'}}>
                <SocialIcon network={socialNetwork} />
                <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}><Link src={SupportService.getSocialProfileUrl(socialNetwork, socialId) || ''}>{socialId}</Link></Text>
              </View>
            )
          }
        </View>
      </View>
      <Text break />
    </View>
  );
};

export default withTranslation()(Profiles);