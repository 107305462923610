import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import LanguageWrapper from "./LanguageWrapper";

import { GlobalStyle } from "./styled";
import PopupContainer from "../components/PopUp/PopUpContainer";
import PermissionProvider from "../components/Permissions/PermissionProvider";


const App = () => (
  <BrowserRouter>
    <LanguageWrapper>
      <GlobalStyle />
      <PermissionProvider>
        <Routing />
        <PopupContainer />
      </PermissionProvider>
    </LanguageWrapper>
  </BrowserRouter>
);

export default App;
