import styled from "styled-components";
import {themes} from "../../config/themes";

export const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 2000; 
  flex-grow: 1;
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  background: #F8F9FA;  
`;

export const Wrapper = styled.div`

`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 95% !important;
  width: 100%;

  @media(min-width: 1600px) {
      max-width: 80% !important;
  }

  @media(max-width: ${themes.regular.wide_screen}) {
      margin: 0 !important;
      max-width: 100% !important;
      padding: 0 !important;
  }
`;